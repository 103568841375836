import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { environment } from 'src/environments/environment';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import * as codes from '../../../assets/Json/collegeList.json';
import { EventServicesService } from 'src/app/services/event-services.service';

enum CandidateStatus {
  applied = 'Applied',
  pending = 'Pending',
  selected = 'Selected',
  rejected = 'Rejected',
  archived = 'Archived',
  shortlisted = 'Shortlisted',
  addedToVettedPool = 'Added To Vetted Pool',
  submitted = 'Submitted',
  scheduled = 'Scheduled',
  allClear = 'All Clear',
  eligible = 'Eligible',
  attended = 'Attended',
  noteligible = 'Not Eligible',
  waitlisted = 'Waitlisted',
  noShow = 'No Show',
  slotSend = 'Slot Sent',
  submittedPartially = 'Submitted Partially',
  submissionPending = 'Submission Pending',
  partiallyApproved = 'Partially Approved',
  joiningConfirmed = 'Joining Confirmed',
  joined = 'Joined',
  notJoining = 'Not Joining',
  notJoined = 'Not Joined',
  yetToJoin = 'Yet To Join',
  offered = 'Offered',
  mailPending = 'Mail Pending',
  offerPending = 'Offer Pending',
  offerRevision = 'Offer Revision',
  offerAccepted = 'Offer Accepted',
  offerDeclined = 'Offer Declined',
  onHold = 'On Hold',
  futureCandidate = 'Future Candidate',
}

export interface FormModel {
  fieldId?: string,
  fieldOrder: number,
  fieldName: string,
  fieldDescription: string,
  isMandatory: boolean,
  formName: string,
  displaySection: string,
  placeHolder: string,
  fieldType: string,
  answer: Array<any>,
  isValid?: boolean,
  schoolId?: string,
  dependant?: Dependancy,
  fieldDefaultValue?: Array<any>,
  fieldOptions?: Array<FieldOptions>,
  fileProperities?: FieldProperties,
  visible?: boolean
}

export interface Dependancy {
  dependantOn: string,
  dependantValues: Array<string>
}

export interface FieldOptions {
  label: string,
  value: any
}

export interface FieldProperties {
  fileFormat: Array<string>,
  fileSize: any
}

@Component({
  selector: 'app-candidate-info',
  templateUrl: './candidate-info.component.html',
  styleUrls: ['./candidate-info.component.css']
})
export class CandidateInfoComponent implements OnInit, OnChanges {

  userInfo: Array<any> = [];
  personalInfo: Array<any> = [];
  educationQualification: Array<any> = [];
  experience: Array<any> = [];
  displayInfo: boolean = true;
  displayResume: boolean = false;
  displayFeedback: boolean = false;
  displayActivity: boolean = false;
  userDetails: any;

  jobId: string = window.localStorage.getItem('selectedJobId') || '';
  eventId: string = window.localStorage.getItem('selectedEventId') || '';
  isInEvent: boolean = this.jobId === this.eventId;
  signedProfileUrl: string = '';

  candidateStatus: any = {}
  statusColor: Array<any> = [];

  stages: Array<any> = [];
  allUsers: Array<any> = [];
  pdfSrc: string = '';
  feedbackDetails: any = [];
  stageNameIdmapping: any = [];
  showDialog: boolean = false;
  paramItems: any;
  isLoadingFeedback: boolean = true;
  isLoading: boolean = false;
  allFormDetails: any = [];
  isLoadingDetails: boolean = false
  showAllDetailDialog: boolean = false;
  editAllDetailDialog: boolean = false;
  allFormTemplate: any = [];
  ENV = environment.HOST.NODE_ENV;
  bucketName: string = '';
  userRole: string = '';
  formLists: any = [];
  formDetails: any = [];
  eventCategory: string = '';
  candidateActivity: any = [];
  timelyActivity: any = [];

  jobName: string = window.localStorage.getItem('selectedJobName') || '';
  @Input() selectedUser: any = {};
  @Input() numberOfStages: number = 0;
  @Input() disableFeedback: any;
  @Input() disableActivity: any;
  @Input() disableStage: any;
  @Input() statusSettings: any;
  @Input() stage: any;

  templateModels: Array<FormModel> = [];
  templateCollections: Array<Array<FormModel>> = [];
  invalidField: any;
  ansChange: boolean = false;
  inEditMode: boolean = false;
  clicked: boolean = false;
  isFormValid: boolean = false;
  submitBtnLoading: boolean = false;
  selectedForm: string = '';
  belowAge: boolean = false;
  collegeList: any = (codes as any).default;
  dateFormat: any;
  timeFormat: any;
  combainFormat: any;

  constructor(
      private fileUploadService: FileUploadService, 
      private formBuilderService: FormBuilderService, 
      private jobService: JobServicesService, 
      private globalService: GlobalServicesService,
      private eventServices: EventServicesService
    ) { }

  ngOnInit() {
    this.dateFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    // this.globalService.dateFormat.subscribe((res: any) => {
    //   this.dateFormat = res;
    // })
    this.timeFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].timeFormat : '';
    // this.globalService.timeFormat.subscribe((res:any) => {
    //   this.timeFormat = res;
    // })
    this.combainFormat = this.timeFormat == 'hh:mm' ? this.dateFormat  + '  '+ this.timeFormat + ' a' : this.dateFormat  + '  '+ this.timeFormat;
    this.candidateStatus = CandidateStatus;

    this.statusColor = [
      { status: "applied", color: 'var(--clr-violet-bg-light)' },
      { status: "scheduled", color: 'var(--clr-violet-bg-light)' },
      { status: "addedToVettedPool", color: 'var(--clr-violet-bg-light)' },
      { status: "slotSend", color: 'var(--clr-violet-bg-light)' },
      { status: "submitted", color: 'var(--clr-violet-bg-light)' },
      { status: "joiningConfirmed", color: 'var(--clr-violet-bg-light)' },
      { status: "offered", color: 'var(--clr-violet-bg-light)' },

      { status: 'offerAccepted', color: 'var(--clr-green-bg-light)' },
      { status: 'offerRevision', color: 'var(--clr-blue-bg-light)' },
      { status: 'offerDeclined', color: 'var(--clr-red-bg-light)' },

      { status: "selected", color: 'var(--clr-green-bg-light)' },
      { status: "shortlisted", color: 'var(--clr-green-bg-light)' },
      { status: "allClear", color: 'var(--clr-green-bg-light)' },
      { status: "eligible", color: 'var(--clr-green-bg-light)' },
      { status: "joined", color: 'var(--clr-green-bg-light)' },

      { status: "waitlisted", color: '#FFE0B4' },
      { status: "noShow", color: '#FFE0B4' },
      { status: "submittedPartially", color: '#FFE0B4' },
      { status: "notJoining", color: '#FFE0B4' },

      { status: "rejected", color: 'var(--clr-red-bg-light)' },
      { status: "noteligible", color: 'var(--clr-red-bg-light)' },
      { status: "notJoined", color: 'var(--clr-red-bg-light)' },

      { status: "pending", color: 'var(--clr-blue-bg-light)' },
      { status: "submissionPending", color: 'var(--clr-blue-bg-light)' },
      { status: 'offerPending', color: 'var(--clr-violet-bg-light)' },
      { status: 'yetToJoin', color: '#E1E6CC' },
      { status: 'mailPending', color: '--clr-blue-bg-light' },

      { status: "attended", color: '#E1E6CC' },
      { status: "partiallyApproved", color: '#E1E6CC' },
      { status: "futureCandidate", color: '#E1E6CC' },

      { status: "archived", color: 'var(--clr-grey)' },
      { status: 'onHold', color: '#7a859275' },

    ]

    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
    this.eventCategory = localStorage.getItem('eventCategory') || 'technical';
    this.selectedForm = this.eventCategory == 'nonTechnical' ? 'Registration Form Non-Technical' : 'Registration Form';

    
    if (this.ENV === 'dev') {
      this.bucketName = 'neo-hire';
    } else if (this.ENV === 'acc') {
      this.bucketName = 'neo-hire';
    } else if ( this.ENV === 'prod' ){
      this.bucketName = 'neo-hire';
    }

    this.userRole = this.globalService.privilegeRoles;
  }
  
  ngOnChanges(): void {
    this.personalInfo = [
      { field: "Gender", value: this.selectedUser.gender ?? '-' },
      { field: "Date of Birth", value: this.selectedUser.dob ?? '-' },
      { field: "Address", value: this.selectedUser.currentAddress ?? '-' },
    ]
    this.educationQualification = [
      { field: "Degree", value: (this.selectedUser?.degreeDetails && this.selectedUser?.degreeDetails.length && this.selectedUser?.degreeDetails[0]?.degree) ? this.selectedUser.degreeDetails[0].degree : '-'},
      { field: "Department", value: (this.selectedUser?.degreeDetails && this.selectedUser?.degreeDetails.length && this.selectedUser?.degreeDetails[0]?.specialization) ? this.selectedUser.degreeDetails[0].specialization : '-'},
      { field: "Batch", value: this.selectedUser.graduationYear },
    ]
    this.experience = [
      { field: "Work Experience", value: this.selectedUser.experience ?? '-' },
      { field: "Current Designation ", value: this.selectedUser.designation ?? '-' },
      { field: "Current Job Location", value: this.selectedUser.location ?? '-' },
    ]
    this.signedProfileUrl = this.selectedUser?.profilePic

    
    this.getStageDetails();
    // console.log("this.selectedUser", this.selectedUser)
    // console.log("status", this.stage)
  } 

  showInfo = () => {
    this.displayInfo = true;
    this.displayResume = false;
    this.displayFeedback = false;
    this.displayActivity = false;
  }
  
  showResume = () => {
    this.pdfSrc = '';
    this.displayResume = true;
    this.displayInfo = false;
    this.displayFeedback = false;
    this.displayActivity = false;
    this.pdfSrc = this.selectedUser?.resume;
  }
  
  showActivity = () => {
    this.displayActivity = true;
    this.displayInfo = false;
    this.displayResume = false;
    this.displayFeedback = false;
  }
  
  showFeedback = async() => {
    this.isLoading = true;
    this.displayFeedback = true;
    this.displayActivity = false;
    this.displayInfo = false;
    this.displayResume = false;
    this.feedbackDetails = [];
    this.formBuilderService.getAllFeedbackform(this.eventId, this.selectedUser.id).subscribe(async (res) => {
      this.feedbackDetails = res.data;
      this.feedbackDetails.sort((a: any, b: any) => +new Date(b.updatedAt) - +new Date(a.updatedAt));
      this.feedbackDetails.forEach((each: any) => {
        each.stageName = this.stageNameIdmapping[each.stageId];
      });
      let stageDurationMapping: any = {};
      let interviewDetails = await this.getInterviewDuration();
      interviewDetails.forEach((each: any)=>{
        stageDurationMapping[each.stageId] = each.duration;
      })
      this.feedbackDetails.forEach((each: any)=>{
        if(stageDurationMapping[each.stageId])
        {
          each.duration = stageDurationMapping[each.stageId];
        }
        if(each.duration){
         //Start Date
          let start = each.duration.startDT.split(' ');
          let startSeperate = start[0].split('-')
          let newStart = (startSeperate.reverse()).join('-');
          let finalStart = new Date( newStart + ' ' +start[1]);
          each.duration.startDT = finalStart;
          
          //End Date
          let end = each.duration.endDT.split(' ');
          let endSeperate = end[0].split('-')
          let newEnd = (endSeperate.reverse()).join('-');
          let finalEnd = new Date(newEnd + ' ' + end[1]);
          each.duration.endDT = finalEnd;
        }

        // let endSeperate = date[1].split(':')
        // console.log(dateSeperate,timeSeperate)
        // let newDate = new Date(Date.UTC(dateSeperate[3], dateSeperate[2], dateSeperate[1], timeSeperate[1], timeSeperate[2], timeSeperate[3]))

      })
      console.log(this.feedbackDetails)
      this.isLoading = false;
    })
  }

  getCandidateActvity = async () => {
    this.candidateActivity = [];
    this.timelyActivity = [];

    let stageActivity = () => {
      return new Promise((resolve, rejects) => {
        this.eventServices.getCandiateStageActivity(this.eventId, this.selectedUser.id).subscribe((res) => {
          if(res.data[0]?.stageMovement){
            let stageMovement = res.data[0]?.stageMovement;
            stageMovement = stageMovement.map((each:any) =>({...each, action: 'Move To'}))
            return resolve(stageMovement);
          } else return resolve(null);
        })
      })
    }

    let statusActivity = () => {
      return new Promise((resolve, rejects) => {
        this.eventServices.getCandiateStatusActivity(this.eventId, this.selectedUser.id).subscribe((res) => {
          if(res.data[0]?.status_tracker){
            let statusTracker = res.data[0]?.status_tracker;
            statusTracker = statusTracker.map((each:any) =>({...each, action: 'Status Updated', movedOn: each.changedOn}))
            return resolve(statusTracker);
          } else return resolve(null);
        })
      })
    }
    let activity: any = await Promise.all([stageActivity(), statusActivity()]);
    activity = activity.filter((each:any) => each != null);

    this.candidateActivity = activity?.flat(1);

    this.candidateActivity.forEach((each:any) =>{
      if(this.getStageName(each.next_stageId,true) == 'Offer'){
        each.userCohortData = this.selectedUser.userCohortData;
      }
    })
    console.log("this.candidateActivity", this.candidateActivity)

    this.candidateActivity.push({ movedOn: this.selectedUser.createdAt, movement: "registration" })
    if (this.selectedUser?.mappingCreatedAt) {
      this.candidateActivity.push({ movedOn: this.selectedUser.mappingCreatedAt, movement: "movedToEvent" })
    }

    if(activity) this.manageCandidateActivity();

    let allDates: any = [];
    let movedDate = '';

    this.candidateActivity.forEach((activity: any) => {
      if (movedDate !== activity.movedDate && !allDates.includes(activity.movedDate)) {
        movedDate = activity.movedDate;
        allDates.push(movedDate);
      }
    })

    allDates.forEach((eachDate: string) => {
      this.timelyActivity.push(this.candidateActivity.filter((activity: any) => activity.movedDate == eachDate));
    })

    this.timelyActivity.sort((a: any, b: any) => +new Date(b[0].movedOn) - +new Date(a[0].movedOn))
    this.timelyActivity.forEach((array: any) => {
      array.sort((a: any, b: any) => +new Date(b.movedOn) - +new Date(a.movedOn));
    });

    this.isLoading = false
  }

  manageCandidateActivity = () => {
    this.candidateActivity.map((activity: any) => {
      if (activity?.action == 'Status Updated') {
        activity.from = this.candidateStatus[activity.current_status];
        activity.to = this.candidateStatus[activity.next_status];
      }
      else {
        if (activity.movement != "registration" && activity.movement != "movedToEvent" && activity?.action == 'Move To') {
          activity.from = this.getStageName(activity.current_stageId);
          activity.to = this.getStageName(activity.next_stageId);
        }
      }
      
      activity.movedDate = activity.movedOn?.split('T')[0];
      if (activity.movement == "manual") {
        activity.userData = this.getUserData(activity.user);
      }
      return activity;
    })
  }

  getUserData = (id: string) => {
    let userData: any = this.allUsers.filter((each: any) => each.email == id)[0];
        userData.badge = userData.name.split(" ").map((n: string, index: number) => { if (index == 0 || index == userData.name.split(" ").length - 1) return n[0]; else return '' }).join("");
        return userData;
  }

  getInterviewDuration(): Promise<any>
  {
    return new Promise((resolve, reject)=>{
      this.jobService.getAllInterviewDuration(this.selectedUser.id, this.eventId).subscribe((response: any)=>{
        resolve(response);
      })
    })
  }

  getStageDetails() {
      this.jobService.getAllStages(this.eventId).subscribe(res => {
        this.stages = res.data;
        this.stages.forEach((each: any) => {
          this.stageNameIdmapping[each.stageId] = each.stageName;
        })
        this.getAllUsers();
    })
  }

  getAllUsers() {
      this.eventServices.getAllUser().subscribe(res => {
        this.allUsers = res?.data ? res.data.items : {};
        if(this.selectedUser?.userCohortData?.cohort_spoc && !this.selectedUser?.userCohortData?.cohort_spoc?.id){
          this.selectedUser.userCohortData.cohort_spoc = this.allUsers.filter((user: any) => user.id == this.selectedUser?.userCohortData.cohort_spoc)[0];
        }
        this.getCandidateActvity();
      })
  }

  getStageName(id:string, getType?: boolean){
    let stageName = '-';
    let stageType = '-';
    this.stages.forEach((stage:any) => {
      if(id?.length == 10){
        if(stage.id == id) {
          stageName = stage.stageName; 
          stageType = stage.stageType;
        }
      } else{
        if(stage.stageId == id) {
          stageName = stage.stageName; 
          stageType = stage.stageType;
        }
      }
    });
    if(getType) {
      return stageType;
    }
    else return stageName;
  }

  fetchFeedback(detail: any) {
    this.isLoadingFeedback = true;
    this.paramItems = {
      eventId: this.eventId,
      stageId: detail.stageId,
      userID: this.selectedUser.id,
      candidateEmail: this.selectedUser.email
    }
    this.showDialog = true;
  }

  loadedFeedback(event: any) {
    this.isLoadingFeedback = event
  }

  // getsignedURL = (url: string, type?: string) => {
  //   this.isLoading = true;
  //   let bucketName = url?.split('/')[2].split('.')[0];
  //   let fileName = url?.split('/').slice(3, 7).join('/').split('?')[0].replace(/\s/g, "");
  //   const payload = {
  //     Bucket_name: bucketName,
  //     file_name: fileName,
  //   }

  //   this.fileUploadService.getSignedDownloadUrl(payload).subscribe(
  //     (signedURL: any) => {
  //       this.isLoading = false;
  //       if (type === 'resume') {
  //         this.pdfSrc = signedURL.data;
  //       }
  //       else {
  //         this.signedProfileUrl = signedURL.data;
  //       }
  //     }
  //   )
  // }

  downloadResume()
  {
    window.open(this.pdfSrc, '_blank')
  }

  fetchAllDetails()
  {
    this.formLists = [];
    this.allFormDetails = [];
    this.showAllDetailDialog = true;
    this.isLoadingDetails = true;
    this.formBuilderService.getCandidateFormDetails(this.selectedUser.id).subscribe((res: any)=>{
      this.isLoadingDetails = false;
      this.allFormDetails = res?.data[0]?.formDetails;
      if(this.allFormDetails[0].formName == 'Registration Form Non-Technical') this.allFormDetails.shift();
      let mobileField: any = ["Primary Mobile Number", "Alternate Mobile Number", "Parent's Mobile Number"]
      this.formLists = [];
      this.allFormDetails.forEach((each: any)=>{
        each.answer = each.answer?.length ? each.answer : ['-'];
        let answer = each.answer;
        if(mobileField.includes(each.fieldName) && answer.length === 2)
        {
          each.answer = [`+${answer[0]+ answer[1]}`];
        }  
        // if(typeof answer[0] === 'string' && (answer[0].includes("https://") && answer[0].includes("s3.amazonaws.com") && answer[0].includes("AWSAccessKeyId")))
        // {
        //   let bucketName = answer[0].split('/')[2].split('.')[0];
        //   let fileName = answer[0].split('/').slice(3, 7).join('/').split('?')[0].replace(/\s/g, "");
        //   each.answer = [`https://s3.amazonaws.com/${bucketName}/${fileName}`];
        // }
      })
      if(this.formLists.length === 0)
      {
        this.allFormDetails.forEach((each: any)=>{
          if(this.formLists.indexOf(each.formName) === -1)
          {
            this.formLists.push(each.formName);
          }
          this.formLists.sort();
          this.formLists.reverse();
          this.selectedForm = this.formLists[0];
        })
      }
      console.log("--- allFormDetails", this.allFormDetails)
    })
  }
  editAllDetails()
  {
    this.formLists = [];
    this.editAllDetailDialog = true;
    this.isLoadingDetails = true;
    this.inEditMode = true;
    this.getTemplates();
  }
  getTemplates = () => {
    this.isLoading = true;
    this.isLoadingDetails = true;
    this.allFormDetails = [];
    this.formDetails = [];
    let _templateModels: Array<any> = [];
    this.templateModels = [];
    this.templateCollections = [];
    this.formBuilderService.getTemplate().subscribe(res => {
      res.data.formDetails.forEach((data: any) => {
        if (data.formDetails[0].formName == this.selectedForm) {
          _templateModels = data.formDetails;
        }
      });

      _templateModels.forEach((eachField: any)=>{
        if(eachField.fieldName === 'Highest Degree - College Name')
        {
          eachField.fieldOptions = this.collegeList;
        }
      })

      this.templateModels = _templateModels.map((model: FormModel) => ({ ...model, answer: [], isValid: !model.isMandatory, visible: model.dependant ? false : true }));
      this.getCandidateForm();

      let sections: Array<string> = [];
      let _displaySection = '';

      _templateModels.forEach((model: FormModel) => {
        if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
          _displaySection = model.displaySection;
          sections.push(_displaySection);
        }
      })
    this.templateCollections = [];
      sections.forEach((eachSection: string) => {
        this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
      })
      setTimeout(() => {
        this.isLoading = false;
      }, 3000) 
    })
  }

selectForm(formName: string)
{
  this.selectedForm = formName;
}

  getCandidateForm = () => {
    this.formBuilderService.getCandidateFormDetails(this.selectedUser.id).subscribe((res: any)=>{
      this.isLoading = false;
      this.formDetails = res?.data[0];
      this.allFormDetails = res?.data[0]?.formDetails;
      if(this.formLists.length === 0)
      {
        this.allFormDetails.forEach((each: any)=>{
          if(this.formLists.indexOf(each.formName) === -1)
          {
            this.formLists.push(each.formName);
          }
          this.formLists.sort();
          this.formLists.reverse();
          this.selectedForm = this.formLists[0];
        })
      }
      this.isLoadingDetails = false;
      this.populateAnswers();
    })
  }

  populateAnswers = () => {
    this.templateModels.forEach((eachModel: FormModel) => {
      eachModel.isValid = true;
      this.allFormDetails.forEach((eachData: FormModel) => {
        if (eachModel.fieldName === eachData.fieldName) {
          eachModel.answer = eachData.answer;
        }
      })
    })
    this.ansChange = !this.ansChange;
    this.isLoading = false;
  }

  checkForDependancy = (model: FormModel): boolean => {
    if (model.dependant) {
      let templateModels = this.templateCollections.flat(1);
      let dependantField: any;

      templateModels.forEach((eachModel: FormModel) => {
        if (eachModel.fieldName === model.dependant?.dependantOn) dependantField = eachModel;
      })
      if (model.dependant.dependantValues.includes(dependantField.answer[0])) {
        model.visible = true;
        return true;
      }
      else {
        model.visible = false;
        return false;
      }
    } else return true;
  }
  checkValid(event: any, model: FormModel) {
    model.isValid = event;
    console.log("--- model.fieldName", model.fieldName)
    if (model.fieldName == "Date Of Birth") {
      let year = new Date().getFullYear();
      let dif = year - Number(model.answer[0].split('-')[2]);
      if (dif < 18) {
        model.isValid = false;
        this.belowAge = true;
      } else {
        model.isValid = true;
        this.belowAge = false;
      }
    }
  }
  bindData(event: any, model: FormModel) {
    model.answer = [];
    switch (model.fieldType) {
      case 'checkbox': model.answer = event; break;
      case 'multiinput': {
        model.answer.push(model.fieldOptions?.map((option: FieldOptions) => option.value).join(' '));
      } break;
      case 'dropdown': {
        if (model.fieldName == 'Highest Degree - Year of Passing') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'input': {
        if (model.fieldName == 'Aadhar Card Number:') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'mobile': model.answer = event; break;
      default: {
        model.answer.push(event)
      }
    }
  }

  submit() {
    this.clicked = true;
    let templateModels = this.templateCollections.flat(1).filter((eachModel: FormModel) => eachModel.visible);
    this.isFormValid = !templateModels.map((model: FormModel) => model.isValid).includes(false);

    if (this.isFormValid) {
      this.submitBtnLoading = true;
      this.clicked = true;
      let payload = templateModels;
      let filterRemainingForm: any = [];
      this.formDetails?.formDetails.forEach((each: any, index:number)=>{
        if(each.formName !== this.selectedForm)
        {
          filterRemainingForm.push(each);
        }
      })
      payload = [...payload, ...filterRemainingForm]
      this.formBuilderService.editFormDetails(payload, this.formDetails.id).subscribe((res: any)=>{
        this.submitBtnLoading = false;
        this.getTemplates();
      })
    } else {
      let invalidFields = templateModels.filter((model: FormModel) => !model.isValid);
      this.invalidField = invalidFields[0];
    }
  }

  downloadFeedback(stageId: number){
    this.isLoading = true;
    this.eventServices.getFeedbackDownloadLink(this.eventId, this.selectedUser.id, stageId).subscribe((res:any) => {
      this.isLoading = false;
      let downloadLink = res?.link;
      window.open(downloadLink);
    })
  }

}
