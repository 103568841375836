import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { EventServicesService } from 'src/app/services/event-services.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import * as codes from '../../../assets/Json/collegeList.json';


enum EventTypes {
  InCampus = 'In-Campus',
  offCampus = 'Off-Campus',
  walkIn = 'Walk-In',
  poolCampus = 'Pool Campus'
}

@Component({
  selector: 'app-events-add',
  templateUrl: './events-add.component.html',
  styleUrls: ['./events-add.component.css'],
  providers: [MessageService, DatePipe]
})
export class EventsAddComponent implements OnInit, OnChanges {

  @Input() eventData: any;
  @Input() eventJobData: any;

  inEditMode: boolean = false;
  clicked: boolean = false;
  saveBtnLoading: boolean = false;
  publishBtnLoading: boolean = false;
  // expInvalid: boolean = false;

  eventTypes: Array<any> = [];
  eventCategories: Array<any> = [];
  jobRoles: Array<any> = [];
  selectedJobRoles: any[] = [];
  selectedInstitutions: any[] = [];
  owners: Array<any> = [];
  institutions: Array<any> = [];

  // minExp!: number;
  // maxExp!: number;

  minSalary!: number;
  maxSalary!: number;

  //Dates
  eventStartDate: any;
  eventEndDate: any
  registrationStartDate: any;
  registrationEndDate: any;

  currentDate = new Date();
  renderForm = false;

  addEventForm: FormGroup = new FormGroup({
    eventTitle: new FormControl('', Validators.required),
    eventStartDate: new FormControl('', Validators.required),
    eventEndDate: new FormControl('', Validators.required),
    jobRole: new FormControl('', Validators.required),
    //Might Be Needed for Future Ref
    // minWorkExperience: new FormControl('', [Validators.required, Validators.min(0), Validators.max(30)]),
    // maxWorkExperience: new FormControl('', [Validators.required, Validators.min(0), Validators.max(30)]),
    workExperience: new FormControl(null, [ Validators.min(0), Validators.max(50)]),
    minSalary: new FormControl(null, [ Validators.min(0)]),
    maxSalary: new FormControl(null, [ Validators.min(0), (control: AbstractControl) => Validators.min(this.minSalary)(control)]),
    eventType: new FormControl(null, Validators.required),
    eventCategory: new FormControl(null, Validators.required),
    institutionName: new FormControl(null, Validators.required),
    institutionAddress: new FormControl('', Validators.required),
    registrationStartDate: new FormControl('', Validators.required),
    registrationEndDate: new FormControl('', Validators.required),
    createdBy: new FormControl(null, Validators.required),
    status: new FormControl(''),
  })

  constructor(
    private eventServices: EventServicesService,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) {
    this.eventTypes = Object.keys(EventTypes).map((key: string) => ({ label: EventTypes[key as keyof typeof EventTypes], value: key }));
    this.eventCategories = [
      { label: 'Technical', value: 'technical' },
      { label: 'Non-Technical', value: 'nonTechnical' }
    ]
  }

  ngOnInit(): void {
    this.getAllRoles();
    this.getOwners();
    this.getInstitutions();
  }


  ngOnChanges(): void {
    this.getInstitutions();
    this.inEditMode = Object.keys(this.eventData).length ? true : false;
    if (this.inEditMode) this.setValues();
  }

  setValues = () => {
    this.inEditMode = Object.keys(this.eventData?.eventTitle).length ? true : false;
    if (this.inEditMode) {
      this.renderForm = true;
      this.addEventForm.patchValue(this.eventData)
      this.selectedJobRoles = [];
      this.getAllRoles('renderChip');
      this.selectedInstitutions = this.eventData && this.eventData.institutionName;
      this.addEventForm.controls['eventStartDate'].setValue(new Date(this.eventData.eventStartDate));
      this.addEventForm.controls['eventEndDate'].setValue(new Date(this.eventData.eventEndDate));
      this.addEventForm.controls['registrationStartDate'].setValue(new Date(this.eventData.registrationStartDate));
      this.addEventForm.controls['registrationEndDate'].setValue(new Date(this.eventData.registrationEndDate));
      this.addEventForm.controls['workExperience'].setValue(this.eventJobData.workExperience);
      this.addEventForm.controls['minSalary'].setValue(this.eventJobData.minSalary);
      this.addEventForm.controls['maxSalary'].setValue(this.eventJobData.maxSalary);
      this.minSalary = this.eventJobData.minSalary;
      this.maxSalary = this.eventJobData.maxSalary;

      this.eventStartDate = new Date(this.eventData.eventStartDate);
      this.eventEndDate = new Date(this.eventData.eventEndDate);
      this.registrationStartDate = new Date(this.eventData.registrationStartDate)
      this.registrationEndDate = new Date(this.eventData.registrationEndDate);
    }
  }

  getAllRoles = (action?: string) => {
    this.eventServices.getAllRoles().subscribe(res => {
      let _jobRoles = res.data ? res.data : [];
      this.jobRoles = [];
      _jobRoles.forEach((role: any) => { this.jobRoles.push({ roleId: role.id, roleName: role.roleName }) });
      if(action === 'renderChip')
      {
        this.selectedJobRoles = (this.eventData && this.eventData.jobRole) ? this.eventData.jobRole : [];
      }
    })
  }

  getInstitutions = () => {
    this.institutions = [];
    (codes as any).default.forEach((each: any)=>{
      this.institutions.push(
        {
          name : each.label
        }
      )
    })
  }

  getOwners = () => {
    this.eventServices.getAllUser().subscribe(res => {
      this.owners = res.data.items ? res.data.items : [];
    })
  }

  updateEvent = (payload: FormGroup) => {
    this.clicked = true;
    if(!this.eventData?.eventCategory) this.addEventForm.removeControl('eventCategory');
    if(this.addEventForm.valid)
    {
      this.publishBtnLoading = true;
      let updatePayload = payload.value;
  
      this.eventServices.updateEvent(updatePayload, this.eventData.id).subscribe(response => {
        if (Object.keys(response.data).length !== 0) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Event Updated Successfully' });
          this.clicked = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to Update the Event' });
        }
        this.publishBtnLoading = false;
      })
      this.clicked = false;
    }
  }

  publishEvent = (payload: any) => {
    this.clicked = true;
    if (this.addEventForm.valid) {
      this.publishBtnLoading = true;
      let publishPayload = payload.value;
      publishPayload.status = 'open';
      this.eventServices.createEvent(publishPayload).subscribe(response => {
        if (response.data) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Event Saved Successfully' });
          this.addEventForm.reset();
          this.clicked = false;
          this.publishBtnLoading = false;
        }
        else if(response.status === 500){
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: response.error });
          this.publishBtnLoading = false;
        }
        else{
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to Save the Event' });
          this.publishBtnLoading = false;
        }
      })
      this.clicked = false;
    }
  }

  onPublish() {
  }

  handleDate() {
  }

  resetRegistrationDates()
  {
    if(!this.renderForm)
    {

      this.addEventForm.controls['registrationStartDate'].setValue(null);
      this.addEventForm.controls['registrationEndDate'].setValue(null);
    }
    else 
    {
      this.renderForm = false;
    }
  }

}
function control(control: any): import("@angular/forms").ValidatorFn {
  throw new Error('Function not implemented.');
}

