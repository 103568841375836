<div class="workflow-container">
    <div *ngIf="isLoading" class="{{isLoading ? 'loadingOverlay' : ''}}">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
        </p-progressSpinner>
    </div>
    <div class="workflow-header">
        <div>Stages</div>
        <button pButton pRipple type="button" label="Add Stage" class="p-button-raised p-button-text"
            (click)="displayAddStage = true; selectedStage.isActive = false"></button>
    </div>
    <div class="workflow-content">
        <div class="stages-list">
            <div class="stage {{ stage.isActive ? 'active' : '' }}" *ngFor="let stage of stages"
                (click)="openStage(stage.id)">

                <p *ngIf="!stage.isRenameStage">{{ stage.name }} <span>{{ stage.type ? "(" + stage.type + ")" :
                        ""}}</span></p>
                <p *ngIf="stage.isRenameStage">
                    <span class="p-inputgroup renameStage">
                        <input type="text" pInputText placeholder="Rename" [(ngModel)]="stageRename"
                            (keyup.enter)="renameStage(stage)">
                        <em class="pi pi-check rename-action_icon" (click)="renameStage(stage)"></em>
                        <em class="pi pi-times rename-action_icon" (click)="stage.isRenameStage = false;"></em>
                    </span>
                </p>

                <p-overlayPanel #options_op>
                    <ng-template pTemplate>
                        <p class="stageOptions-overlay" [ngClass]="{'disabled': !stage.deletable}" (click)="deleteStage(stage.id)" *ngIf="stage.stageId !== 1">
                            Delete</p>
                        <p class="stageOptions-overlay" (click)="duplicateStage(stage, options_op)"
                            *ngIf="stage.stageId !== 1">Duplicate</p>
                        <p class="stageOptions-overlay "
                            (click)="stage.isRenameStage = true; stageRename = stage.name; options_op.hide()">
                            Rename</p>
                    </ng-template>
                </p-overlayPanel>
                <em class="pi pi-ellipsis-v stageOptions" [style]="{display: stage.isActive ? 'block' : ''}"
                    (click)="options_op.toggle($event)"></em>
            </div>
        </div>
        <div class="stages-container">
            <div class="addStage" *ngIf="displayAddStage">
                <p class="header">Add Stage</p>
                <div class="addStage-content stage-content">
                    <div class="radioButtons">
                        <div *ngFor="let category of stageTypes" class="field-checkbox">
                            <p-radioButton [inputId]="category" name="category" [value]="category"
                                [(ngModel)]="selectedType"></p-radioButton>
                            <label
                                [style]="{color: selectedType === category ? 'var(--fc-dark)' : 'var(--fc-light-dark)', fontWeight: selectedType === category ? 'var(--fw-semi-bold)' : 'var(--fw-regular)' }"
                                [for]="category">{{ category }}</label>
                        </div>
                        <!-- <span class="p-float-label">
                            <p-dropdown inputId="dropdown" [autoDisplayFirst]="false" [options]="stageTypes" [(ngModel)]="selectedType" [showClear]="true">
                            </p-dropdown>
                            <label for="dropdown">Stage Type</label>
                        </span> -->
                    </div>
                    <span class="p-float-label">
                        <input type="text" id="stageName" pInputText [(ngModel)]="stageName"
                            [ngClass]="{'p-filled': stageName}" (keyup.enter)="createStage()">
                        <label for="stageName">Stage name</label>
                    </span>
                    <div class="addStage-buttons clearAndSave-buttons">
                        <button pButton pRipple type="button" label="Cancel" (focus)="true"
                            class="clear p-button-outlined p-button-secondary"
                            (click)=" closeAddStage(); openCurrentStage();"></button>
                        <button pButton pRipple type="button" label="Save" class="save p-button-outlined"
                            (click)="createStage()"></button>
                    </div>
                </div>
            </div>

            <div class="stageDetails" *ngFor="let stage of stages">
                <div *ngIf="stage.isActive">
                    <div class="header-tab_search">
                        <div class="header-tabs">
                            <button class="tabs" [ngClass]="{'selected': displayShortlisted}"
                                (click)=" displayShortlisted = true; displayRuleEngine = false">Shortlisted</button>
                            <button class="tabs" [ngClass]="{'selected': displayRuleEngine}"
                                (click)=" displayShortlisted = false; displayRuleEngine = true">Rules</button>
                        </div>
                        <div class="search-container">
                            <div *ngIf="displayShortlisted" class="stage-search">
                                <span class="p-input-icon-left">
                                    <em class="pi pi-search"></em>
                                    <input class="searchbar" (keyup.enter)="searchEmailCandidate($event)" type="text"
                                        pInputText placeholder="Search Email" />
                                </span>
                            </div>
                            <div *ngIf="stage.type === 'Offer' && showBulkCohort" class="cohort_container" [style]="{paddingLeft: '20px'}">
                                <div class="cohort">
                                    <button pButton pRipple type="button" label="Bulk Upload Cohort" class="p-button-raised p-button-text stage-bulk"
                                    [style]="{height: '2.5rem'}" (click)="bulkCohort()"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="stage.type == 'Test' || stage.type == 'Interview'"
                        [ngClass]="{'hidden': !displayRuleEngine}">
                        <app-workflow-manage-schedule [stage]="stage" (isTestSelected)="checkTestSelected($event)"
                            (isFeedbackSelected)="checkFeedbackSelected($event)" (emitContestId)="contestId = $event"
                            (emitTestId)="testId = $event" (isTemplateSelected)="checkTemplateSelected($event)">
                        </app-workflow-manage-schedule>
                    </div>
                    <div class="stage-ruleSet" [ngClass]="{'hidden': !displayRuleEngine}">
                        <app-workflow-ruleset [stage]="stage" [index]="i" [workflowId]="rule"
                            (ruleToRemove)="removeRule($event, stage)" (ruleToUpdate)="updateRule($event, stage)"
                            *ngFor="let rule of stage.workflowIds; index as i" [isTestSelected]="isTestSelected">
                        </app-workflow-ruleset>
                        <div [style]="{display: 'inline-flex', alignItems: 'center', width: '100%'}">
                            <button pButton pRipple type="button" label="Add Rule" (click)="addRule(stage)"
                                class="p-button-raised p-button-text addRule-button"></button>
                        </div>
                    </div>
                    <div [ngClass]="{'hidden': !displayShortlisted}">
                        <app-stage-candidates [emailsCleared]="emailsCleared" [stage]="stage" [stages]="stages" [contestId]="contestId" (emptyStage)="stage.deletable = $event"
                            [search]="searchEmail" [testId]="testId" [isFeedbackSelected]="isFeedbackSelected" [isTestSelected]="isTestSelected" [isTemplateSelected]="isTemplateSelected"
                            (emailList)="emailList($event)" (candidateCount)="candidateCount($event)" [statusSettings]="statusSettings">
                        </app-stage-candidates>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-toast></p-toast>
</div>

<p-sidebar class="sendLink-slider" [(visible)]="displayCohort" styleClass="p-sidebar-lg" [showCloseIcon]="true"
    position="right" [style]="{width: '50rem'}" (onHide)="cohortClosed()">
    <div class="cohort-slider-header">
        <div class="cohort-slider-heading">Upload Cohort ( <span style="color: #326BEE;">{{!finalStage ? '1' : '2'}}</span>  OF 2 )</div>
        <div class="header-btns">
            <button *ngIf="finalStage" pButton pRipple type="submit" label="Back" class="p-button-outlined button-align back-btn"
            (click)="back()"></button>
            <button pButton pRipple type="submit"
            (click)="!finalStage ? stageOne() : stageFinal()"
            [label]="!finalStage ? 'Next' : 'Confirm'" class="p-button-raised p-button-text button-align"
            [loading]="confirmBtnLoading"></button>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>

    <div class="cohort-slider-body" *ngIf="!finalStage" [style]="{fontSize: '15px'}">
        <div class="radio-group align-group">
            <div class="radio-heading">Download</div>
            <div class="radio-body">
                <div class="radioButton-container">
                    <div>
                        <p-radioButton [(ngModel)]="cohortDownloadRadio" value="generic-template" name="generic-template" inputId="generic-template">
                        </p-radioButton>
                        <label>Download generic template</label>
                    </div>
                </div>
                <div class="radioButton-container">
                    <div>
                        <p-radioButton [(ngModel)]="cohortDownloadRadio" value="selected-candidates" name="selected" inputId="selected">
                        </p-radioButton>
                        <label>Download selected candidates</label>
                    </div>
                </div>
                <div class="radioButton-container">
                    <div>
                        <p-radioButton [(ngModel)]="cohortDownloadRadio" value="downloadAll" name="all" inputId="all">
                        </p-radioButton>
                        <label>Download all candidates</label>
                    </div>
                </div>
            </div>
            <div>
                <div class="cohort-download" (click)="cohortDownload()">
                    <div class="file-container" [style]="{display : 'flex'}">
                        <div class="cohort-file_info">
                            <p class="file-name">Cohort_Template.xlsx</p>
                        </div>
                    </div>
                    <p class="template-download-icon">
                    <em class="pi pi-download" [style]="{fontSize: '18px', color: '#326BEE', fontWeight: 'bold',paddingLeft: '10px'}"></em></p>
                </div>     
            </div> 
            <form [formGroup]="uploadCohort">
                <div class="scheduleCandidate-container">
                    <div class="upload-template">
                        <div class="upload-heading">Upload Template</div>
                        <div *ngIf="!cohortUploaded">
                            <div class="bulkUpload-dropzone" ngx-dropzone
                            [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                            [multiple]="false" (change)="cohortUpload($event)">
                                <ngx-dropzone-label *ngIf="!uploadingFile">
                                    <div class="dropzone-label">
                                        <em class="pi pi-cloud-upload"></em>
                                        <p class="upload-text">Click to <span [style]="{color: 'var(--fc-primary)'}">upload</span>
                                            or
                                            Drag & Drop</p>
                                        <p class="format-text">.XLS, .XLSX Formate only</p>
                                    </div>
                                </ngx-dropzone-label>
                                <div *ngIf="uploadingFile">
                                    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
                                    </p-progressSpinner>
                                </div>
                            </div>  
                        </div>
                        <div *ngIf="cohortUploaded">
                            <div class="flex-dev uploaded-container" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">
                                <div class="main" [style]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
                                    <div class="file-icon" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center',
                                        background: 'rgba(52, 86, 255, 0.03)',borderRadius: '4px', width: '40px', height: '40px',marginRight: '15px'}">
                                            <p class="pi pi-file template-file-icon" [style]="{fontSize : '24px',display: 'flex',alignItems: 'center', color: 'blue'}"></p>
                                    </div>
                                    <div class="cohort-file_info" [style]="{marginRight: '15px'}">
                                        <p class="file-name">{{cohortFile[0].file[0].name}}</p>
                                        <p class="file-name" [style]="{color: '#AAAAAA'}">{{cohortFileSize}}</p>
                                    </div>
                                    <div class="cancel" (click)="onRemoveExcel()">
                                        <p class="cohort-cancel-icon">
                                        <em class=" report-download pi pi-times" [style]="{fontSize: '14px', fontWeight: 'bold', color: '#d5d5d5', 
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'}"></em></p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="countDiv" class="records-container" [style]="{background: 'rgba(52, 86, 255, 0.03)',borderRadius: '4px', 
                                width: '303px', height: '40px', marginTop : '25px', marginBottom : '25px', display: 'flex', alignItems: 'center'}">
                                <div class="records" [style]="{width: '100%', display: 'flex',justifyContent: 'space-evenly', alignItems : 'center'}">
                                    <div class="total" [style]="{color: '#000000', fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                                        <label class="file-name">Total records :<span class="total-mark">  {{counts.total}}</span></label>
                                    </div>
                                    <div class="correct" [style]="{fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                                        <label class="file-name" [style]="{color: '#008000'}">Correct :<span class="total-mark" [style]="{color: '#000000'}">  {{counts.validated}}</span></label>
                                    </div>
                                    <div class="totalErrors" [style]="{fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                                        <label class="file-name" [style]="{color: '#FF5E5B'}">Error :<span class="total-mark" [style]="{color: '#000000'}">  {{counts.errored}}</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="error-report" *ngIf="cohortError">
                <div class="error-header" [style]="{width: '100%',display: 'flex', justifyContent: 'space-between',
                alignItems: 'center', paddingRight: '40px'}">
                    <div class="header-left">
                        <div class="upload-heading">Error</div>     
                    </div>
                    <div class="header-right">
                        <p [style]="{color: '#3456FF', cursor: 'pointer'}" (click)="errorReportDownload()">Download Error Report </p>
                    </div>
                </div>
                <div class="error-body">
                    <p-table [columns]="requiredColumns" [value]="errorFileData" [tableStyle]="{ 'min-width': '44rem', 'margin-top': '20px', 'margin-bottom' : '30px'}">
                        <ng-template pTemplate="header" let-columns>
                            <tr [style]="{width: '5vw'}" scope="col" pResizableColumn
                            pFrozenColumn>
                                <th *ngFor="let col of columns" style="background: #D2DFFF !important;">
                                    {{ col.header }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [style]="{width: '5vw'}" pFrozenColumn>
                                <td *ngFor="let col of columns">
                                    <div class="errored_label">
                                        <div class="data" *ngIf="col.field == 'jobLocation'">
                                            <div class="emptyLocation" *ngIf="rowData['jobLocation'].length < 1">
                                                <div class="errored">
                                                    {{ rowData['jobLocation'].length < 1  ? '-' : null}}
                                                </div>
                                            </div>
                                            <div class="multiple-location" *ngFor="let data of rowData[col.field]">
                                                <p [ngStyle]="{'color' : locationCheck(data) ? 'black' : 'red'}">
                                                    {{ rowData[col.field].length > 0 && data }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="data" *ngIf="col.field != 'jobLocation'">
                                            <div [ngClass]="{'errored': (rowData['remarks'].includes(col.field) || rowData['remarks'].includes('Candidate is not in the stage'))}">
                                                {{  rowData[col.field] ? rowData[col.field] : '-' }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="cohort-slider-body" *ngIf="finalStage" [style]="{fontSize: '15px'}">
        <div class="dropdown-group align-group">
            <span class="p-float-label eventForm-elements">
                <p-dropdown class="dropdown cohortDropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label"
                    (onChange)="templateChange($event)" [filter]="true"
                    [options]="templateOptions" [styleClass]=" clicked && !templateValue.length ? 'invalid' : ''">
                </p-dropdown>
                <label for="dropdown">Select Template<span class="imp">*</span></label>
            </span>
            <span class="final-stageBox" *ngIf="templateValue.length">
                    <div class="stageBox">
                        <span [style]="{color: '#000000', paddingRight: '10px', fontWeight: '500', fontSize: '14px'}">Subject Line </span>
                        <span class="template-content">{{templateLabel}}</span>
                        <p-divider></p-divider>
                        <span [innerHTML]="templateValue" class="template-content"></span>
                    </div>
            </span>
        </div>
        
    </div>

</p-sidebar>