import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OtpService } from 'src/app/services/otp.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css']
})
export class OtpVarificationComponent implements OnInit, OnChanges {

  @ViewChild('otp_value') otp_value: any;

  @Input() candidateId: string = '';
  @Input() eventId: string = '';
  @Output() otpVerified = new EventEmitter<boolean>();

  emailID: string = '';
  otpValue: any;
  userName: string = '';
  infoMessage: any = {};
  
  btnLoading: boolean = false;
  pageLoading: boolean = false;
  sendOtpState: boolean = true;
  verifyOtpState: boolean = false;
  waitToResend: boolean = false;
  loadResend: boolean = false;
  clicked: boolean = false;
  invalidOTP: boolean = false;
  expiredOTP: boolean = false;
  displayInfoMessage: boolean = false;

  otpTimer: number = 0;
  countdown: string = '';
  redirectUrl: string = '';
  
  constructor( private otpService: OtpService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if(window.location.pathname === '/verification'){
      this.activatedRoute.queryParamMap.subscribe((param) => {
        this.candidateId = param.get('candidateId') || '';
        if(this.eventId === ''){
          this.eventId = param.get('eventId') || '';
        }
      })
      console.log(this.eventId)
      this.redirectUrl = window.location.href.split('&redirect=')[1];
      this.getUserData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['candidateId']){
      this.getUserData();
    }
  }  

  getUserData = () => {
    this.pageLoading = true;
    this.otpService.getUserById(this.candidateId).subscribe((res: any) => {
      this.pageLoading = false;
      if(res.data){
        this.emailID = res.data.email;
        this.userName = res.data.name; 
      }
    })
  }

  sendOtp = () => {
    this.btnLoading = true;
    console.log(this.candidateId, this.eventId)
    this.otpService.sendOtp(this.candidateId, this.eventId).subscribe(res => {
      if(res.data.otpValue){
        this.infoMessage = {icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailID, bgColor: '#55BA45'}
        this.showInfoMessage();
        this.btnLoading = false;
        this.sendOtpState = false;
        this.verifyOtpState = true;
        this.waitToResend = true;
        this.startOtpTimer();
      } else {
        this.infoMessage = {icon: 'pi pi-times', message: 'Something went wrong!', bgColor: '#E24A47'}
        this.showInfoMessage();
      }
    })
  }

  verifyOtp = () => {
    this.clicked = true;
    if (this.otpValue.toString().length == 6) {
      this.btnLoading = true;
      this.invalidOTP = false;
      this.expiredOTP = false;
      let payload = { otp: Number(this.otpValue) };
      this.otpService.verifyOtp(this.emailID, payload).subscribe(res => {

        switch (res.data) {
          case 'success': {
            if(window.location.pathname === '/verification'){
              this.invalidOTP = false;
              window.open(decodeURIComponent(this.redirectUrl), '_self');
            } else {
              this.invalidOTP = false;
              this.otpVerified.emit(true);
              this.clicked = false;
            }
          } break;
          case 'failed': {
            this.invalidOTP = true;
          } break;
          case 'expired': {
            this.expiredOTP = true;
          }
        }
        this.btnLoading = false;
      })

    } else {
      this.invalidOTP = true;
    }
  }

  resendOtp = () => {
    this.loadResend = true; 
    this.otpService.sendOtp(this.candidateId, this.eventId).subscribe(res => {
      this.infoMessage = {icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailID, bgColor: '#55BA45'}
      this.showInfoMessage();
      this.loadResend = false; 
      this.waitToResend = true;
      this.startOtpTimer();
    })
  }

  startOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;

    const intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    return `${seconds.toString().padStart(2, '0')}`;
  }

  restrictNumber(event: any) {
    let e: any = event || window.event; 
    let key = e.keyCode || e.which;

    if(key == 13) {
      this.verifyOtp();
    }
    if (key < 48 || key > 57 || event.target.value.toString().length >= 6) {
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
    }
  }

  showInfoMessage = () =>{
    this.displayInfoMessage = true;
    setInterval(() => {
      this.displayInfoMessage = false;
      this.infoMessage = {};
    }, 10000);
  }

  otpValueChange(event: any){
    if(event.length == 0 || event.length == 6){
      this.invalidOTP = false;
      this.expiredOTP = false;
    }
  }
}
