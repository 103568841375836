import { Component, Input, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { JobServicesService } from '../../services/job-services.service';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EventServicesService } from 'src/app/services/event-services.service';
import { TalentPoolService } from 'src/app/services/talent-pool.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { SettingsService } from 'src/app/services/settings.service';


@Component({
  selector: 'app-talentpool-management',
  templateUrl: './talentpool-management.component.html',
  styleUrls: ['./talentpool-management.component.css'],
  providers: [MessageService]
})
export class TalentpoolManagementComponent implements OnInit {

  // Array Var
  talentList: Array<any> = [];
  pageLimits: Array<any> = [];
  status: MenuItem[] = [];
  selectedTalent: Array<any> = [];
  talentpoolCol: Array<any> = [];
  _talentpoolCol: Array<any> = [];
  _selectedColumns: any[] = [];
  colsToDownload: Array<any> = [];
  talentOptions: Array<any> = [];
  eventTalent: object = {};
  filteredTalentList: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  sourceData: Array<any> = [];
  eventList: Array<any> = [];
  eventDetails: Array<any> = [];
  _eventDetails: Array<any> = [];
  listAllCandidates: Array<any> = [];

  //Filtering Array
  checkedColumns: Array<any> = [];
  selectedStatusElements: Array<any> = [];
  selectedJobTypeElements: Array<any> = [];
  selectedDepartmentElements: Array<any> = [];
  selectedUsersList: any = [];
  dates: any = [];
  moveToEvent: any;
  selectedTalentpoolTypeElements: Array<any> = [];
  rangeDates: Array<any> = [];
  dateFormat: any;

  // Boolean Var
  addTalentpoolSlider: boolean = false;
  sendEmailSlider: boolean = false;
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;
  moveToSelected: boolean = false;
  isLoading: boolean = false;
  allCandidateSelected: boolean = false;
  // selectAll: boolean = false;
  skipLimit: boolean = true;

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;

  // String var
  statusSelected: string = 'All';
  eventSearchTerm: string = '';
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  columnFieldId: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'candidateList';
  candidateSearch: string = '';
  privilege: any = {};
  selectedUser: any;
  displayCandidateDetails: boolean = false;
  statusSettings: any

  constructor(
    private jobService: JobServicesService,
    private messageService: MessageService,
    private _router: Router,
    private eventService: EventServicesService,
    private talentPoolService: TalentPoolService,
    private globalService: GlobalServicesService,

    private breadcrumbService: NgDynamicBreadcrumbService,
    private settingsService: SettingsService
    // public shortList: Ng2SearchPipeModule,
  ) {
    this.pageLimits = [10, 25, 50, 100];

    this.talentpoolCol = [
      { index: 1, field: 'id', header: 'Candidate ID' },
      { index: 2, field: 'name', header: 'Name' },
      { index: 3, field: 'email', header: 'Email' },
      { index: 4, field: 'mobile_number', header: 'Contact Number' },
      { index: 5, field: 'eventDetails', header: 'Event Name' },
      { index: 6, field: 'sourceTag', header: 'Source Tag' },
      { index: 7, field: 'status', header: 'Account Status' },
      { index: 8, field: 'createdAt', header: 'Joined Date' },
      { index: 9, field: 'degree', header: 'Degree' },
      { index: 10, field: 'specialization', header: 'Specialization' },
      { index: 11, field: 'graduationYear', header: 'Batch' },
    ]

    this._selectedColumns = [
      { index: 1, field: 'id', header: 'Candidate ID' },
      { index: 2, field: 'name', header: 'Name' },
      { index: 3, field: 'email', header: 'Email' },
      { index: 4, field: 'mobile_number', header: 'Contact Number' },
      { index: 5, field: 'eventDetails', header: 'Event Name' },
      { index: 6, field: 'sourceTag', header: 'Source Tag' },
      { index: 7, field: 'status', header: 'Account Status' },
      { index: 8, field: 'createdAt', header: 'Joined Date' }
    ]

    this.talentOptions = [
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: () => {
          this.deleteTalent(this.eventTalent)
        }
      }
    ]
  }

  searchForm = [
    { name: 'Name', value: '' },
    { name: 'Email', value: '' },
    { name: 'Contact Number', value: '' },
    { name: 'Event Name', value: '' },
    { name: 'Source', value: '' },
    { name: 'Account Status', value: 'All' },
    { name: 'Joined Date', value: '' },
    { name: 'Candidate ID', value: '' }
  ]


  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave)
  })

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    // this.globalService.dateFormat.subscribe((res: any) => {
    //   this.dateFormat = res;
    // })
    this.getAllCandidates();
    this.getColumnId();
    this.getSavedColumns();
    this.getCandidateCount();
    this._talentpoolCol = this.talentpoolCol;
    this.colsToDownload = this.talentpoolCol;
    this.checkedColumns = this._selectedColumns;
    this.getEvent();
    this.allCandidateSelected = false;
    this.privilege = this.globalService.privilegeDetails;
    this.updateBreadcrumb();
    this.globalService.searchCandidateList.subscribe((response) => {
      this.talentList = [];
      this.talentList = response.data ? response.data.items : '';
      this.totalLength = response.data.totalItems;
      this.loadingSpinner = false;
    })
    this.getStatusSettings();
  }


  updateBreadcrumb(): void {
    const breadcrumbs = [
      {
        label: 'Manage Candidate',
        url: ''
      }
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  allSelected() {
    this.allCandidateSelected = true;
  }


  // API functions
  // get all the jobs
  getAllCandidates = () => {
    this.loadingSpinner = true;
    this.globalService.pageNumber = this.pageNumber;
    this.globalService.pageSize = this.pageSize;
    let searchTerm = this.globalService.searchTerm;
    let payload: any = {};
    let searchedEmail = searchTerm.trim().split(/\s+/);
    searchTerm = '';
    if (searchedEmail && searchedEmail.length === 1) {
      searchTerm = searchedEmail[0];
    }
    else {
      payload.search = searchedEmail;
    }
    if (searchTerm.length) {
      this.globalService.getSearchCandidates(searchTerm, payload).subscribe(res => {
        this.isLoading = false;
        this.globalService.searchCandidateList.next(res);
      });
    }
    else {
      this.talentPoolService
        .getAllCandidates(this.pageNumber, this.pageSize)
        .subscribe((response: any) => {
          this.talentList = response.data ? response.data.items : [];
          this.loadingSpinner = false;
        })
    }
  }

  getFilterTalent = (status?: string) => {
    this.loadingSpinner = true;
    this.talentList = [];
    this.jobService
      .getFilterJobs(this.pageNumber, this.pageSize, status)
      .subscribe((response: any) => {
        // this.talentList = response.data ? response.data.items : [];
        // this.totalLength = response.data ? response.data.totalItems : 0;
        // this.loadingSpinner = false;

        // this.totalLength = this.talentList.length;
        this.loadingSpinner = false;
      });
  };

  deleteTalent = (talent: any) => {
    this.loadingSpinner = true;
    this.jobService.deleteJob(talent.id).subscribe(res => {
      if (res) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Candidate deleted...' });
        this.getAllCandidates();
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to delete the Candidate...' });
      }
    })
    this.loadingSpinner = false;
  }

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get("columnName")?.value,
      columnValue: this.columnsToSave
    });

    this.jobService.saveColumns(this.columnFieldId, [this.columnSettings.value]).subscribe((result: any) => {
      this.savedColumnSettings = result.data.jobSelectedColumns;
      this.loadingSpinner = false;
    });
    this.displaySaveColumnSettings = false;
  }

  getColumnId = () => {
    this.jobService.getSchool().subscribe(res => {
      this.columnFieldId = res.data[0].id;
    })
  }

  getEvent = () => {
    this.moveToSelected = true;
    this.eventService.getEventDetails().subscribe(res => {
      res.data.forEach((element: any) => {
        if (element.id && element.eventTitle) {
          this.eventDetails.push({
            id: element.id,
            name: element.eventTitle,
          });
        }
      });
      this._eventDetails = this.eventDetails;
    })
  }

  moveEvent(event: any, stagesOP: any) {
    this.isLoading = true;
    window.localStorage.setItem('eventId', event.id);
    let payload = this.selectedUsersList;
    if (this.allCandidateSelected) {
      this.skipLimit = true;
      this.talentPoolService.moveEvent(payload, event.name, this.skipLimit).subscribe(res => {
        if (res.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Candidate${this.selectedUsersList.length > 1 ? 's' : ''} Moved to ${event.name}.` });
          this.selectedUsersList = [];
          this.eventSearchTerm = '';
          this._eventDetails = this.eventDetails;
          this.isLoading = false;
          this.getAllCandidates();
        }
      });
    } else {
      this.skipLimit = false;
      this.talentPoolService.moveEvent(payload, event.name, this.skipLimit).subscribe(res => {
        if (res.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Candidate${this.selectedUsersList.length > 1 ? 's' : ''} Moved to ${event.name}.` });
          this.selectedUsersList = [];
          this.eventSearchTerm = '';
          this._eventDetails = this.eventDetails;
          this.isLoading = false;
          this.getAllCandidates();
        }
      })
    }
    stagesOP.hide(event);
  }

  getCandidateCount = () => {
    this.talentPoolService.getTalentPoolCount().subscribe(res => {
      this.totalLength = res.data;
    })
  }

  // searchData(fieldName: any, i: number) {
  //   let search: string;
  //   if (this.searchForm[i].name == 'Account Status') {
  //     search = this.searchForm[i].value.toLowerCase();
  //   } else if (this.searchForm[i].name == 'Joined Date') {
  //     search = this.dates[i];
  //   } else {
  //     search = this.searchForm[i].value;
  //   }
  //   this.pageSize = 100;
  //   this.talentList = []
  //   this.globalService.getSearchCandidates(this.pageNumber, this.pageSize, search, fieldName).subscribe(res => {
  //     this.talentList = res.data.items
  //     console.log(search, fieldName, i)
  //     console.log(res)
  //     console.log(this.dates,'list')
  //   });

  // }

  event(i: number) {
    this.searchForm.forEach((item, idx) => {
      if (idx != i && item.name != 'Account Status') {
        item.value = ''
      }
    })
  }

  getSavedColumns = () => {
    this.jobService.getSchool().subscribe(res => {
      this.savedColumnSettings = res.data[0].jobSelectedColumns ? res.data[0].jobSelectedColumns : []
    });
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.jobService.deleteColumns(this.columnFieldId, columnId).subscribe(res => {
      this.savedColumnSettings = res.data.jobSelectedColumns;
      this.loadingSpinner = false;
    })
  }

  // Normal Functions
  setStatus = (status: string) => {
    this.statusSelected = status;
  }

  getEventVal = (event: Event) => {
    return (event.target as HTMLInputElement).value;
  }

  onSelectedTalentChange = (value: []) => {
    this.selectedTalent = value;

    if (this.selectedUsersList.length == this.pageSize) {
      this.allCandidateSelected = true;
    } else {
      this.allCandidateSelected = false;
    }
    // console.log(this.selectedUsersList);

  }

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [
      { index: 1, field: 'id', header: 'Candidate ID' },
      { index: 2, field: 'name', header: 'Name' },
      { index: 3, field: 'email', header: 'Email' }
    ];
  }

  applySelectedFilter = () => {
    // let date = this.rangeDates;
    let startDate = new Date(this.rangeDates[0]).toISOString();
    let endDate = new Date(this.rangeDates[1]).toISOString();

    this.talentPoolService.getFilter(this.pageNumber, this.pageSize, startDate, endDate).subscribe(res => {
      this.talentList = res.data ? res.data.items : [];
      this.loadingSpinner = false;
      // this.showFilterSidebar = false;
    })
    if (this.selectedStatusElements.length || this.selectedTalentpoolTypeElements.length || this.selectedDepartmentElements.length) {
      this.filteredTalentList = [];

      let statusFilter = this.talentList.filter(element => this.selectedStatusElements.includes(element.status));
      let talentTypeFilter = this.talentList.filter(element => this.selectedTalentpoolTypeElements.includes(element.jobType));
      let departmentFilter = this.talentList.filter(element => this.selectedDepartmentElements.includes(element.department));

      this.filteredTalentList = [...new Set([...statusFilter, ...talentTypeFilter, ...departmentFilter])]
      this.showFilterSidebar = false;

    } else {
      this.filteredTalentList = [];
    }
  };

  clearSelectedFilters = () => {
    this.selectedStatusElements = [];
    this.selectedTalentpoolTypeElements = [];
    this.selectedDepartmentElements = [];
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.talentpoolCol.filter(e => savedColumnValue.includes(e.field));
  }

  openCandidates = (talent: any) => {
    this.selectedUser = talent;
    this.displayCandidateDetails = true;
    console.log(talent)
  }
  // get jobs by limit
  getTalentByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getAllCandidates();
  };

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._talentpoolCol = this.talentpoolCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.talentpoolCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  eventSearch(event: any) {
    if(event){
      this._eventDetails = this.eventDetails.filter(val => val.name.toLowerCase().includes(event.toLowerCase()));
    } else {
      this._eventDetails = this.eventDetails;
    }
      
  }

  // get jobs by filter
  getTalentByFilter = (status: string) => {
    this.statusSelected = status;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getAllCandidates();
  };

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  // For custom pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getAllCandidates();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? ( this.last == this.totalLength ? this.totalLength - this.talentList.length : this.last - this.pageSize ) : this.pageSize;
    this.pageNumber -= 1;
    this.getAllCandidates();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true; 
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.talentpoolCol.filter(col => val.includes(col));
  }

  getHeaderVal(col: string) {
    let result = col.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  setEventTalentId(talent: any) {
    this.eventTalent = talent;
  }

  exportRole = (type: string) => {
    let checkedColumnFields: any = ['candidateId'];
    console.log(this.checkedColumns)
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.talentList.forEach((eachData) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
        switch(item)
        {
          case "eventDetails":
            let eventName = this.fetchEventDetails(eachData["eventDetails"]);
            filterData[item] = eventName.join(',');
            break;
          case "specialization":
            filterData[item] = eachData["degreeDetails"][0]["specialization"];
            break;
        }
      });
      afterFilter.push(filterData);
    });    
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: type === 'Excel' ? 'xlsx' : 'csv',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  };

  fetchEventDetails = (eventDets : any) =>{
    let eventNameArray : any= [];
    eventDets.forEach((item : any) => {
      eventNameArray.push(item.eventTitle)
    });
    return eventNameArray; 
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE = type === 'Excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'text/csv';
    let FILE_EXTENSION = type === 'Excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + FILE_EXTENSION
    );
  }

  sendEmail() {
    this.sendEmailSlider = true;
  }
  
  mailSent(event: boolean) {
    if(event){
      console.log(event)
      this.sendEmailSlider = false;
      this.selectedUsersList = [];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Mail Sent!' });
    }
  }

  getStatusSettings = () => {
    this.loadingSpinner = true;
    this.settingsService.getStageStatusData().subscribe(res => {
      if(res.status == 200){
        this.statusSettings = res?.data ? res.data.stageStatus : {};
        this.loadingSpinner = false;
      }
    })
  }
}
