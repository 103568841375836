<div (window:resize)="onResize()" [ngStyle]="{'height':height}" class="login">
    <div class="login-container">
        <div class="login-container_divisions">
            <div class="login-container_image">
                <img class="cover-image" style="border-radius: 25px 0px 0px 25px;" [src]="coverImage" alt="" srcset="">
            </div>
            <div class="login-container_main">
                <div class="login-container_input">
                    <div class="logo-container">
                        <img class="logo" [src]="sourceImg" alt="" srcset="">
                    </div>
                    <p class="signin-label">Sign in</p>
                    <p class="moto">The key to happiness is to login.</p>
                    <div *ngIf="invalidCred" class="invalid-cred-container invalid-cred">
                        <span [ngClass]="[invalidCred ? 'visible-element' : 'hidden-element' ]">
                            Invalid email address or password
                        </span>
                    </div>
                    <form [formGroup]="loginSection">
                        <span class="p-float-label email-field">
                            <input formControlName="email" [required]="true" class="inputEmail"
                                [ngClass]="{ 'p-filled': loginSection.value.email, 'dirty-input' : ((!loginSection.get('email')?.valid && loginSection.get('email')?.touched) || invalidCred)}"
                                style="min-width: 100% !important;" type="email" id="inputtext" pInputText
                                (click)="invalidCred = false" />
                            <label for="inputtext"
                                [ngClass]="[(!loginSection.get('email')?.valid && loginSection.get('email')?.touched) ? 'touched-email' : (invalidCred ? 'invalid-credentials' : '')]">Email
                                Address</label>
                        </span>
                        <div *ngIf="(loginSection.get('email')?.hasError('pattern'))"
                            class="error-msg">
                            <span
                                [ngClass]="[(!loginSection.get('email')?.valid && loginSection.get('email')?.touched && loginSection.get('email')?.dirty) ? 'visible-element' : 'hidden-element' ]">
                                Enter a valid Email ID </span>
                        </div>
                        <app-handle-error [clicked]="clicked" [control]="loginSection.get('email')" name="Email ID">
                        </app-handle-error>
                        <span class="p-float-label password-field">
                            <input formControlName="password" [required]="true" class="inputPassword"
                                [ngClass]="{ 'p-filled': loginSection.value.password, 'dirty-input' : (!loginSection.get('password')?.valid && loginSection.get('password')?.touched) || invalidCred}"
                                style="min-width: 100% !important;" type="password" id="inputtext" class="passwordInput"
                                pInputText (keyup.enter)="signIn()" (click)="invalidCred = false" />
                            <label for="inputtext"
                                [ngClass]="[((!loginSection.get('password')?.valid && loginSection.get('password')?.touched)) ? 'touched-password' : (invalidCred ? 'invalid-credentials' : '')]">Password</label>
                        </span>
                        <div *ngIf="((!loginSection.get('password')?.valid && loginSection.get('password')?.touched))"
                            class="error-msg">
                            <span
                                [ngClass]="[((!loginSection.get('password')?.valid && loginSection.get('password')?.touched)) ? 'visible-element' : 'hidden-element' ]">Password
                                is required</span>
                        </div>
                        <div class="signin-addon">
                            <div class="signin-show-pwd">
                                <span class="show-pwd">
                                    <p-checkbox (onChange)="toggleShowHidePwd()" [binary]="true"></p-checkbox>
                                    <label class="show-label_spacing">Show Password</label>
                                </span>
                            </div>
                        </div>
                        <!-- loginSection.valid -->
                        <span class="sigin-btn" [ngClass]="[loginSection.valid ? 'enable-signin' : 'disable-signin']"
                            (click)="signIn()" type="submit">
                            <span *ngIf="!isLoading">Sign in</span>
                            <span class="sign-btn-load" *ngIf="isLoading">
                                <p-progressSpinner class="signin-spinner" [style]="{height: '20px', width: '20px'}"
                                    strokeWidth="7" animationDuration="0.5s">
                                </p-progressSpinner>
                            </span>
                        </span>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>