<div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div class="pageInfo">
    <div class="settings-header" style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
        <div style="display: flex;justify-content:space-between; align-items: center;">
            <em class="pi pi-user"></em>
            <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Primary Account</p>
        </div>
        <em class="pi pi-pencil" (click)="openAddTemplate('Edit Primary Account', 'pi-user')"></em>
    </div>
</div>
<div class="info-card">
    <div [style]="{display: 'block', flexWrap: 'wrap'}">
        <div *ngFor="let info of userInfo; let i = index">
            <div class="info-element">
                <span class="info-line"></span>
                <div [style]="{display: 'flex', flexDirection: 'column'} ">
                    <p [style]="{color: 'var(--fc-light-dark)'}">{{info.field}}</p>
                    <p [style]="{color: 'var(--fc-dark)', width: 'fit-content'}">{{info.value}}</p>
                </div>
            </div>
            <div style="margin-left: 16px;" *ngIf="info.field === 'Email/Username' || info.field === 'Password'">
                <div (click)="openAddTemplate((info.field === 'Email/Username' ? 'Change Email' : 'Change Password'), (info.field === 'Email/Username' ? 'pi-at' : 'pi-key'))"
                    class="change-button">
                    <div>
                        <em class="pi pi-pencil"></em>
                        <p>Change</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-sidebar *ngIf="addTemplateSidebar" class="addTemplate-slider" [(visible)]="addTemplateSidebar"
    styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
    (onHide)="emailForm.reset(); showPlaceholder = false;">
    <div class="addTemplate-header">
        <div class="addTemplate-heading"><em style="margin-right: 12.5px;" ngClass="{{sideActionIcon}}"
                class="pi"></em>{{sidebarAction}}</div>
        <div class="header-btn">
            <button *ngIf="sidebarAction === 'Edit Primary Account'" pButton pRipple type="submit" label="Save"
                (click)="saveTemplate()" class="p-button-raised p-button-text button-align"
                [loading]="saveBtnLoading"></button>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>
    <div *ngIf="sidebarAction === 'Change Email'" class="templateForm" [formGroup]="emailForm">
        <div style="display: flex; align-items: center;">
            <span class="p-float-label templateForm-elements">
                <input type="text" id="template-title" pInputText formControlName="oldEmail"
                    [disabled]="verifiedCurrentEmail" [ngClass]="{'p-filled': true}">
                <label for="template-title">Current Email <span [style]="{color: '#E24A47'}">*</span></label>
            </span>
            <div
                style="background: #326BEE33; color:  var(--clr-primary); height: 2.5rem; margin-left: 27px; border-radius: 4px; display: flex; justify-content: center; align-items: center;cursor: pointer;">
                <p style="padding:8px 18px; font-size:var(--fs-medium);" *ngIf="!verifiedCurrentEmail"
                    (click)="verifyEmail()">Verify Email</p>
            </div>
        </div>
        <div style="display: flex; align-items: center;" *ngIf="verifiedCurrentEmail">
            <span class="p-float-label templateForm-elements">
                <input type="text" id="template-title" pInputText formControlName="newEmail"
                    [ngClass]="{'p-filled': emailForm.value.newEmail}">
                <label for="template-title">New Email <span [style]="{color: '#E24A47'}">*</span></label>
            </span>
            <div
                style="background: #326BEE33; color: var(--clr-primary); height: 2.5rem; margin-left: 27px; border-radius: 4px; display: flex; justify-content: center; align-items: center;cursor: pointer;">
                <p style="padding:8px 18px; font-size:var(--fs-medium);" (click)="verifyEmail()">Verify Email</p>
            </div>
        </div>
        <div *ngIf="verifiedMail">
            <p style="font-size:var(--fs-large); margin: 50px 0px 10px 0px;">Almost there!</p>
            <div style="font-size:var(--fs-medium);">
                <p>An email with a verification code has been sent to</p>
                <p>admin@wiley.com</p>
            </div>
            <div style="margin-top: 20px;">
                <p>Didn't recieve a code? <span style="color: var(--clr-primary); cursor: pointer;"
                        (click)="verifyEmail()">Request again</span></p>
            </div>
        </div>
        <div style="margin-top: 20px;">
            <ng-otp-input class="{{(otp.length) ? 'otp-type' : ''}}" *ngIf="showOtpField"
                (onInputChange)="onOtpChange($event)" [config]="{length:4,allowNumbersOnly:true}"></ng-otp-input>
        </div>
        <span style="margin-top: 30px; width:25vw !important" class="p-float-label templateForm-elements">
            <div style="cursor: pointer;" *ngIf="!verifiedCurrentEmail && showOtpField">
                <span
                    style="padding:10px 25px; background-color:var(--clr-primary); color: var(--clr-white);border-radius: 4px;"
                    (click)="verifyOtp()">Confirm OTP</span>
            </div>
            <div style="cursor: pointer;" *ngIf="verifiedCurrentEmail && showOtpField">
                <span
                    style="padding:10px 25px; background-color:var(--clr-primary); color: var(--clr-white);border-radius: 4px;"
                    (click)="verifyOtp()">Confirm Email Changes</span>
            </div>

        </span>
    </div>
    <p-toast></p-toast>

    <div *ngIf="sidebarAction === 'Change Password'" class="templateForm" [formGroup]="passwordForm">
        <span class="p-float-label templateForm-elements">
            <input formControlName="currentPassword" class="inputEmail curInput inputtext"
                [ngClass]="{ 'p-filled':passwordForm.value.currentPassword }" type="password" id="template-title"
                pInputText />
            <label for="inputtext">Current Password <span [style]="{color: '#E24A47'}"> *</span></label>
            <span class="inputEmailIcon">
                <em *ngIf="this.currentPassShow"
                    (click)="this.currentPassShow = !this.currentPassShow; showPassword('curInput')"
                    class="pi pi-eye"></em>
                <em *ngIf="!this.currentPassShow"
                    (click)="this.currentPassShow = !this.currentPassShow; showPassword('curInput')"
                    class="pi pi-eye-slash"></em>
            </span>
        </span>
        <span class="p-float-label templateForm-elements">
            <input formControlName="newPassword" class="inputEmail newInput inputtext"
                [ngClass]="{ 'p-filled':passwordForm.value.newPassword }" type="password" id="template-title"
                pInputText />
            <label for="inputtext">New Password <span [style]="{color: '#E24A47'}"> *</span></label>
            <span class="inputEmailIcon">
                <em *ngIf="this.newPassShow" (click)="this.newPassShow = !this.newPassShow; showPassword('newInput')"
                    class="pi pi-eye"></em>
                <em *ngIf="!this.newPassShow" (click)="this.newPassShow = !this.newPassShow; showPassword('newInput')"
                    class="pi pi-eye-slash"></em>
            </span>
        </span>

        <span class="p-float-label templateForm-elements">
            <div style="margin: 1vh 0; width:25vw !important">
                <p>Minimum of 6 characters, with upper and lowercase and a number, of symbol</p>
            </div>
        </span>
        <span class="p-float-label templateForm-elements">
            <input formControlName="confirmPassword" class="inputEmail confirmInput inputtext"
                [ngClass]="{ 'p-filled':passwordForm.value.confirmPassword }" type="password" id="template-title"
                pInputText />
            <label for="inputtext">Confirm Password <span [style]="{color: '#E24A47'}"> *</span></label>
            <span class="inputEmailIcon">
                <em *ngIf="this.confirmPassShow"
                    (click)="this.confirmPassShow = !this.confirmPassShow; showPassword('confirmInput')"
                    class="pi pi-eye"></em>
                <em *ngIf="!this.confirmPassShow"
                    (click)="this.confirmPassShow = !this.confirmPassShow; showPassword('confirmInput')"
                    class="pi pi-eye-slash"></em>
            </span>
        </span>
        <span class="p-float-label templateForm-elements">
            <div style="margin: 1vh 0; width:25vw !important">
                <p>Make sure to change your application password.</p>
            </div>
        </span>
        <span style="margin-top: 30px; width:25vw !important" class="p-float-label templateForm-elements">
            <div style="cursor: pointer;">
                <span
                    style="padding:10px 25px; background-color:var(--clr-primary); color: var(--clr-white);border-radius: 4px;">Change
                    My Password</span>
            </div>
        </span>
    </div>

    <div *ngIf="sidebarAction === 'Edit Primary Account'" class="templateForm" [formGroup]="accountDetails">
        <div style="display: flex; align-items: center;">
            <span class="p-float-label templateForm-elements">
                <input class="inputtext" type="email" id="template-title" pInputText formControlName="email"
                    [ngClass]="{'p-filled': accountDetails.value.email}" [(ngModel)]="emailEdit"
                    style="max-width: 32vw !important;">
                <label for="template-title">Email/Username</label>
            </span>
            <div (click)="openAddTemplate('Change Email', 'pi-at')" class="change-button" style="margin: 0 0 0 20px;">
                <div>
                    <em class="pi pi-pencil"></em>
                    <p>Change</p>
                </div>
            </div>
        </div>

        <div style="display: flex; align-items: center;">
            <span class="p-float-label templateForm-elements">
                <input class="inputtext" type="text" id="template-title" pInputText formControlName="password"
                    [ngClass]="{'p-filled': accountDetails.value.password}" [(ngModel)]="passwordEdit"
                    style="max-width: 32vw !important;">
                <label for="template-title">Password</label>
            </span>
            <div (click)="openAddTemplate('Change Password', 'pi-key')" class="change-button"
                style="margin: 0 0 0 20px;">
                <div>
                    <em class="pi pi-pencil"></em>
                    <p>Change</p>
                </div>
            </div>
        </div>
        <span class="p-float-label">
            <p-inputNumber class="acc-phNo" id="inputtext" [useGrouping]="false" formControlName="mobile"
                 [style]="{maxWidth: '32vw !important'}" [maxlength]="10">
            </p-inputNumber>
            <label for="inputtext">Mobile Number</label>
        </span>
    </div>



</p-sidebar>