<div class="otp-info-message" [style]="{backgroundColor: infoMessage.bgColor}" *ngIf="displayInfoMessage">
    <div class="info-icon"><i [class]="infoMessage.icon" ></i></div>
    <div class="info-content">{{ infoMessage.message }}</div>
</div>
<div class="opt-verification">
    <div class="opt-popup">
        <div class="otp-container">
            <p class="otp-header mt-45" *ngIf="!pageLoading">Welcome, <span style="word-break: break-word;">{{userName}}</span></p>

            <span class="p-float-label email-field mt-45" style="width: 100%;" *ngIf="!pageLoading">
                <input [(ngModel)]="emailID" class="inputEmail" type="email" id="inputtext" pInputText [disabled]="true"/>
                <label for="inputtext">Email ID <span class="imp">*</span></label>
            </span>

            <span class="p-float-label otp-field" style="width: 100%;" *ngIf="!pageLoading && verifyOtpState">
                <input [(ngModel)]="otpValue" (keypress)="restrictNumber($event)" #otp_value="ngModel" class="inputOtp" 
                    onwheel="return false;" (ngModelChange)="otpValueChange($event)" style="max-width: 100% !important; width: 100%;" id="inputtext" pInputText/>
                <label for="inputtext">Enter OTP</label>
                <span *ngIf="clicked && invalidOTP" class="otpError" >Please enter the valid OTP sent to your email ID</span>
                <span *ngIf="clicked && expiredOTP" class="otpError" >OTP Expired</span>
            </span>
            <div class="resend-container" *ngIf="verifyOtpState">
                <div class="otp-info">Please enter 6 digit OTP</div>
                <div class="resend-info">
                    <span *ngIf="waitToResend" class="resent-timer">Resend in <strong>{{countdown}}s</strong></span>
                    <span *ngIf="!waitToResend" (click)="resendOtp()" class="resend-btn">
                        <span *ngIf="!loadResend">Resend</span>
                        <p-progressSpinner *ngIf="loadResend" [style]="{height: '20px', width: '20px', marginRight: '30px'}" strokeWidth="7"
                            animationDuration="0.5s">
                        </p-progressSpinner>
                    </span>
                </div>
            </div>
            

            <div *ngIf="sendOtpState && !pageLoading" class="card flex justify-content-center submit-btn mt-45" style="width: 100%;">
                <p-button label="Send OTP" [loading]="btnLoading" (click)="sendOtp()"></p-button>
            </div>

            <div *ngIf="verifyOtpState && !pageLoading" class="card flex justify-content-center submit-btn mt-45" style="width: 100%;">
                <p-button label="Verify" [loading]="btnLoading" (click)="verifyOtp()" [ngClass]="{'disabled': otpValue?.length != 6}" ></p-button>
            </div>

            <p-progressSpinner *ngIf="pageLoading" [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                animationDuration="0.5s">
            </p-progressSpinner>
        </div>
    </div>
</div>