<div class="userInfo-container">
    <div class="profileCard-container">
        <div class="profileImg">
            <img src="{{ selectedUser?.profilePic ? signedProfileUrl : 'https://ediindia.ac.in/alumni/web/profile.jpg'}}"
                alt="profile">
        </div>
        <div class="userDetails">
            <p class="userName">{{selectedUser.name}}</p>
            <div class="userInfo">
                <p class="userInfo-text"><em class="pi pi-id-card"></em> {{selectedUser?.id}}</p>
                <span class="divider"></span>
                <p class="userInfo-text"><em class="pi pi-at"></em> {{selectedUser?.email}}</p>
                <span class="divider"></span>
                <p class="userInfo-text" *ngIf="selectedUser?.mobile_number"><em class="pi pi-phone"></em> +91
                    {{selectedUser?.mobile_number}}</p>
            </div>
        </div>
        <div *ngIf="!disableStage" class="userStatus">
            <div [ngSwitch]="selectedUser.status">
                <span *ngFor="let status of statusColor">
                    <span *ngSwitchCase="status.status">
                        <round-progress class="round-progress" [radius]="32" [stroke]="6" [background]="'#DFE3E7'"
                            [current]="selectedUser.stageId" [max]="numberOfStages" [rounded]="true" [semicircle]="true"
                            [color]="status.color">
                        </round-progress>
                    </span>
                </span>
            </div>
            <div [style]="{position: 'relative', top: '-15px', color: 'black'}">
                {{selectedUser.stageId}}/{{numberOfStages}}
            </div>
            <div class="status-tag {{selectedUser.status}}-tag">
                <div *ngIf="stage && (selectedUser.stageId == 1 || stage.type); else noStageType">
                    {{ statusSettings[ selectedUser?.stageId == 1 ? 'Eligibility' : stage.type][selectedUser['status']] ?? '-' }}
                </div>
                <ng-template #noStageType>
                    <div *ngIf="selectedUser.stageId == 1 || selectedUser.stageType">
                        {{ statusSettings[selectedUser.stageId == 1 ? 'Eligibility' : selectedUser.stageType][selectedUser['status']] ?? '-' }}
                    </div>
                    <div *ngIf="!selectedUser.stageType">
                        {{selectedUser.status}}
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="tab-menu">
        <span class="tabs {{displayInfo ? 'selected' : ''}}" (click)="showInfo()">Info</span>
        <span class="tabs {{displayResume ? 'selected' : ''}}" (click)="showResume()">Resume</span>
        <span *ngIf="!disableFeedback" class="tabs {{displayFeedback ? 'selected' : ''}}"
            (click)="showFeedback()">Feedback</span>
        <span *ngIf="!disableActivity" class="tabs {{displayActivity ? 'selected' : ''}}" 
            (click)="showActivity()">Activity</span>
    </div>

    <div>
        <div style="overflow-y: scroll; height: 70vh;" class="{{displayInfo ? 'userInfo-content' : 'hidden'}} section">
            <div class="form-access">
                <div class="form-flex">
                    <div>
                        <p class="view-all_label" (click)="fetchAllDetails()">View All Details</p>
                    </div>
                    <div>
                        <p *ngIf="userRole === 'admin'" class="view-all_label" (click)="editAllDetails()">Edit All Details</p>
                    </div>
                </div>
            </div>

            <div *ngIf="editAllDetailDialog">
                <p-dialog  header="Candidate Details" [modal]="true" [(visible)]="editAllDetailDialog"
                    [style]="{width: '80vw', height: '100vh'}" >
                    <div class="form-names" >
                        <div *ngFor="let form of formLists">
                            <div [ngClass]="{'selected': selectedForm === form}" class="form-name" (click)="selectForm(form); getTemplates()">{{form}}</div>
                        </div>
                    </div>
                    <div *ngIf="isLoadingDetails" class="loading-feedback">
                        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                            animationDuration="0.5s">
                        </p-progressSpinner>
                    </div>
                    <div *ngIf="!isLoading" class="dialog-form">
                        <div *ngFor="let section of templateCollections; index as i">
                            <div class="personal-info">
                                <div *ngIf="i != 0">
                                    <h3 class="personal-details_label">{{section[0].displaySection}}</h3>
                                    <hr class="title-line">
                                </div>
                                <div *ngFor="let model of section">
                                    <app-fields [model]="model" [clicked]="clicked" (checkValidator)="checkValid($event, model)"
                                        (fieldDataEmitter)="bindData($event, model)" [invalidField]="invalidField" [belowAge]="belowAge"
                                        [inEditMode]="inEditMode" *ngIf="checkForDependancy(model)" [ansChange]="ansChange">
                                    </app-fields>
                                </div>
                            </div>
                        </div>
                        <button *ngIf="!isLoadingDetails" pButton pRipple type="button" label="Submit" (click)="submit()" class="form-btn"
                    [loading]="submitBtnLoading"></button>
                    </div>
                    
                </p-dialog>
            </div>
            <div class="info-card">
                <p class="heading">Personal Info</p>
                <div [style]="{display: 'flex', flexWrap: 'wrap'}">
                    <div class="info-element" *ngFor="let info of personalInfo">
                        <span class="info-line"></span>
                        <div [style]="{display: 'flex', flexDirection: 'column'} ">
                            <p [style]="{color: 'var(--fc-light-dark)'}">{{info.field}}</p>
                            <p [style]="{color: 'var(--fc-dark)', width: 'fit-content', fontWeight: '500'}">
                                {{info.field == 'Date of Birth' ? (info.value | date : dateFormat) : info.value}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-card">
                <p class="heading">Educational Qualifications</p>
                <div [style]="{display: 'flex', flexWrap: 'wrap'}">
                    <div class="info-element" *ngFor="let info of educationQualification">
                        <span class="info-line"></span>
                        <div [style]="{display: 'flex', flexDirection: 'column'} ">
                            <p [style]="{color: 'var(--fc-light-dark)'}">{{info.field}}</p>
                            <p [style]="{color: 'var(--fc-dark)', width: 'fit-content', fontWeight: '500'}">
                                {{info.value}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isLoading" class="container-loader">
            <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
            </p-progressSpinner>
        </div>

        <div class="{{displayResume ? 'userResume-content' : 'hidden'}}">
            <div *ngIf="selectedUser?.resume && pdfSrc.length" class="resume-download">
                <div (click)="downloadResume()" class="resume-download_label">
                    <span class="pi pi-download resume-download_icon"> </span>Download Resume
                </div>
            </div>
            <pdf-viewer *ngIf="selectedUser?.resume && pdfSrc.length" class="pdf-viewer" [src]="pdfSrc"
                [render-text]="true" [fit-to-page]="true" [autoresize]="true"
                style="width: 900px; height: 68vh"></pdf-viewer>
            <span *ngIf="!(selectedUser?.resume)">No Resume Uploaded</span>
        </div>

        <div style="overflow-y: scroll; height: 70vh;" class="{{displayFeedback ? 'userFeedback-content' : 'hidden'}} section">
            <div *ngIf="showDialog">
                <p-dialog header="Feedback Details" [modal]="true" [(visible)]="showDialog"
                    [style]="{width: '75vw', height: '80vh'}">
                    <div *ngIf="isLoadingFeedback" class="loading-feedback">
                        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                            animationDuration="0.5s">
                        </p-progressSpinner>
                    </div>
                    <app-feedback-form (isLoadingFeedback)="loadedFeedback($event)" [headerData]="paramItems"
                        [onlyFeedbackView]="true" [inViewMode]="true"></app-feedback-form>
                </p-dialog>
            </div>
            <div *ngIf="!isLoading" class="feedback-details">
                <div class="feedback-detail" *ngFor="let detail of feedbackDetails">
                    <p class="feedback-date">{{detail.updatedAt | date: 'fullDate' || '-'}}</p>
                    <div class="interview-details">
                        <div class="interview-duration">
                            <div>
                                <p class="feedback-date_startTime">{{detail?.duration ? (detail?.duration?.startDT | date: combainFormat || '-') : '-'}}</p>
                                <p class="feedback-date_endTime"> to</p>
                                <p class="feedback-date_endTime">{{detail?.duration ? (detail?.duration?.endDT | date: combainFormat || '-') : '-'}}</p>
                            </div>
                        </div>
                        <div class="split">
                            <div class="split-bar"></div>
                        </div>
                        <div class="feedback-user_detail">
                            <div class="interrogator-feedback">
                                <div class="interrogator-details">
                                    <div>
                                        <p-avatar class="avatar-align"
                                            image="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"></p-avatar>
                                    </div>
                                    <div>
                                        <p class="interrogator-name">{{detail.interrogatorName}}</p>
                                        <p>{{detail.interrogatorEmail}}</p>
                                        <p class="feedback-submit">{{detail.updatedAt | date: dateFormat || '-'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="view-feedback_container">
                                <div>
                                    <p class="stage-name">{{detail.stageName}}</p>
                                </div>
                                <div class="feedback-options">
                                    <p (click)="downloadFeedback(detail.stageId)"><i class="pi pi-download"></i> Download Feedback</p>
                                    <div (click)="fetchFeedback(detail)" class="view-feedback"><em class="pi pi-file"></em> View Feedback</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-break_section">
                        <hr class="line-break">
                    </div>
                </div>
                <div *ngIf="feedbackDetails.length === 0 && !isLoading" class="no-feedback">
                    <p>Feedback not submitted yet!</p>
                </div>
            </div>
        </div>

        <div class="{{displayActivity && !isLoading ? 'userActivity-content' : 'hidden'}}">
            <div class="candidateActivity">
                <div class="activities" *ngFor="let date of timelyActivity">
                    <p-divider align="left">
                        <div class="activityDate">{{date[0].movedDate | date: dateFormat}}</div>
                    </p-divider>
                    <div *ngFor="let activity of date">

                        <!-- <div class="flex" *ngIf="activity.movement == 'manual' && !activity?.userData">
                            <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                            <div style="flex: 1">
                                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                                <p-skeleton width="75%"></p-skeleton>
                            </div>
                        </div> -->

                        <!-- <div class="activity-item" *ngIf="activity.movement != 'manual' || activity.userData"> -->
                        <div class="activity-item">
                            <div class="user-profile">
                                <img src="../../../assets/icons-svg/command.svg"
                                    *ngIf="activity.movement != 'manual'; else badge" alt="">
                                <ng-template #badge>{{activity?.userData?.badge}}</ng-template>
                            </div>
                            <div class="activity-content" >
                                <h3 class="user-name">
                                    {{activity.movement == 'manual' ? activity?.userData?.name : 'Hire Automation' }} 
                                    <span class="time">{{activity.movedOn | date: combainFormat}}</span> 
                                </h3>
                                <div class="activity-details">
                                    <div class="action-details" [ngSwitch]="activity.movement">
                                        <span *ngSwitchCase="'registration'">
                                            <div class="action"><span class="label">Action </span>Registration</div>
                                            <button pButton pRipple class="view-form-btn" label="View Application Form" (click)="fetchAllDetails()"></button>
                                        </span>
                                        <span *ngSwitchCase="'movedToEvent'">
                                            <div class="action"><span class="label">Action </span>Moved to event</div>
                                        </span>
                                        <span *ngSwitchDefault>
                                            <div class="action"><span class="label">Action </span>{{activity.action}}</div>
                                            <div class="action-from-to">
                                                <span class="fromStage">{{activity?.from}}</span>
                                                <img src="../../../assets/icons-svg/arrow-right-alt.svg" alt="Arrow-right">
                                                <span class="toStage">{{activity?.to}}</span>
                                            </div>
                                            
                                        </span>
                                    </div>
                                    <div class="action-remarks">
                                        <span class="label">Remarks</span>
                                        <p  [ngSwitch]="activity.movement">
                                            <span *ngSwitchCase="'automatic'">Passed Stage's criteria</span>
                                            <span *ngSwitchCase="'registration'">Successfully Registered</span>
                                            <span *ngSwitchCase="'movedToEvent'">Successfully Moved To Event.</span>
                                            <span *ngSwitchDefault>{{activity.comments ? activity.comments : '-'}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="cohort-table-container" *ngIf="activity.userCohortData?.cohort_name">
                                    <p class="cohort-table-header">Updated Fields</p>
                                    <div class="cohort-table">
                                        <span class="table-data">
                                            <label class="table-item header">Job Role</label>
                                            <p class="table-item">{{ activity.userCohortData.cohortJobRole }}</p>
                                        </span>
                                        <span class="table-data">
                                            <label class="table-item header">Job Location</label>
                                            <p class="table-item">{{ activity.userCohortData.cohortLocation }}</p>
                                        </span>
                                        <span class="table-data">
                                            <label class="table-item header">Cohort ID</label>
                                            <p class="table-item">{{ activity.userCohortData.cohort_name }}</p>
                                        </span>
                                        <span class="table-data">
                                            <label class="table-item header">Cohort Date</label>
                                            <p class="table-item">{{ activity.userCohortData.date | date: dateFormat }}</p>
                                        </span>
                                        <span class="table-data">
                                            <label class="table-item header">Cohort SPOC</label>
                                            <p class="table-item">{{ activity.userCohortData.cohort_spoc?.name }}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showAllDetailDialog">
        <p-dialog header="Candidate Details" [modal]="true" [(visible)]="showAllDetailDialog"
            [style]="{width: '75vw', height: '80vh'}" >
            <div *ngIf="isLoadingDetails" class="loading-feedback">
                <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                    animationDuration="0.5s">
                </p-progressSpinner>
            </div>
            <div class="display-details_section">
                <div *ngFor="let eachForm of formLists; let formIndex=index">
                    <p class="view-form-name">{{eachForm}}</p>
                    <div class="fetch-details" *ngFor="let eachData of allFormDetails">
                        <div *ngIf="eachData.formName === eachForm" class="each-detail">
                            <div class="field-name">{{eachData.fieldName}}</div>
                            <div class="seperator">:</div>
                            <div *ngIf="eachData.fieldType !== 'file'" class="field-answer">
                            <div *ngFor="let answer of eachData.answer; let i=index">
                                <p class="answer-bold">{{eachData.fieldName == 'Date Of Birth' ? (answer | date : dateFormat) : answer}}
                                    <span *ngIf="eachData.answer[i+1]">, </span></p>
                            </div>
                            </div>
                            <div *ngIf="eachData.fieldType === 'file'" class="field-answer">
                            <div *ngFor="let answer of eachData.answer; let i=index">
                                <a class="file_link" target="_blank" href="{{answer}}">View</a>
                            </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="formIndex<formLists.length-1">
                </div>
            </div>
        </p-dialog>
    </div>

</div>