import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { JobServicesService } from 'src/app/services/job-services.service';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  providers: [MessageService]
})
export class UserListComponent implements OnInit {

  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  addUserSlider: boolean = false;
  adminAccess: boolean = true;

  usersList: any = [];
  usersCol: Array<any> = [];
  _usersCol: Array<any> = [];
  colsToDownload: Array<any> = [];
  pageLimits: Array<any> = [];
  checkedColumns: Array<any> = [];
  selectedUsers: Array<any> = [];
  userTypes: any = {};
  userData: any = null;

  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;

  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'userList';
  privilege: any = {};

  constructor(private jobServices: JobServicesService, private messageService: MessageService, private globalService: GlobalServicesService) {
    this.pageLimits = [10, 25, 50, 100];

    this.usersCol = [
      { index: 1, field: 'userType', header: 'Associated Role' },
      { index: 2, field: 'email', header: 'Official Email ID' },
      { index: 3, field: 'mobile_number', header: 'Mobile Number' },
    ]

    this.userTypes = {
      admin: 'Admin',
      user: 'User',
      recruiter: 'Recruiter',
      hiringManager: 'Hiring Manager',
      limitedRecruiter: 'Limited Recruiter',
      interviewer: 'Interviewer',
    }
  }

  ngOnInit(): void {
    this._usersCol = this.usersCol
    this.colsToDownload = this.usersCol;
    this.checkedColumns = this.usersCol;
    this.getAllUsers();
    // this.privilege  = JSON.parse(this.globalService.decryptPrivileges());
    this.privilege = this.globalService.privilegeDetails;
  }


  getAllUsers = () => {
    this.loadingSpinner = true;
    this.usersList = [];
    this.jobServices.getAllUser(this.pageNumber, this.pageSize).subscribe(response => {
      this.usersList = Object.keys(response.data).length !== 0 ? response.data.items : [];
      this.totalLength = response.data ? response.data.totalItems : 0;
      this.usersList.forEach((user: any) => {
        if (user.userType === 'admin') this.adminAccess = false;
      });
      this.loadingSpinner = false;
    })
  }

  getUsersByLimit = () => {
    this.showFilterSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getAllUsers();
  };

  openUser(user: any) {
    this.userData = user;
    this.addUserSlider = true;
  }

  downloadHide(){
    this.downloadSearchTerm = '';
    this.colsToDownload = this.usersCol;
  }

  searchColumns = (searchTerm: string) => {
    if (this.showDownloadSidebar) {
      this.colsToDownload = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  exportUser = (type: string) => {
    let checkedColumnFields: any = ['name'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.usersList.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: type === 'excel' ? 'xlsx' : 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  next(): void {
    this.showFilterSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getAllUsers();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? ( this.last == this.totalLength ? this.totalLength - this.usersList.length : this.last - this.pageSize ) : this.pageSize;
    this.pageNumber -= 1;
    this.getAllUsers();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true; 
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }
}
