import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TalentPoolService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  bulk_invite = environment.HOST.BULK_INVITE

  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = localStorage.getItem('eventId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      schoolid: schoolId,
      jobid: jobId
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  // AddUser  
  addUser(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/', payload, this.options());
  }

  // BulkInvite  
  bulkInvite(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite + '/cloudRun/', payload, this.options());
  }

  singleInvite(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/talentPool/', payload, this.options());
  }

  getAllCandidates(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get(this.api_url + '/talentPool/allCandidates?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  moveEvent(payload: any, eventTitle: string, skipLimit: any): Observable<any> {
    return this.http.post(this.api_url + '/talentPool/moveEvent?eventTitle=' + eventTitle, payload, this.options());
  }

  getTalentPoolCount(): Observable<any> {
    return this.http.get(this.api_url + '/talentPool/candidates/getCount', this.options())
  }

  //Filter API
  getFilter(pageNumber: number, pageSize: number, startDate: any, endDate: any): Observable<any> {
    return this.http.get(this.api_url + '/talentpool/filter/createdDate?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&startDate=' + startDate + '&endDate=' + endDate, this.options());
  }
}
