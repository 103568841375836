import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
} from '@angular/core';
import { EventServicesService } from 'src/app/services/event-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { nanoid } from 'nanoid';

enum CandidateStatus {
  applied = 'Applied',
  pending = 'Pending',
  selected = 'Selected',
  rejected = 'Rejected',
  archived = 'Archived',
  shortlisted = 'Shortlisted',
  addedToVettedPool = 'Added To Vetted Pool',
  submitted = 'Submitted',
  scheduled = 'Scheduled',
  allClear = 'All Clear',
  eligible = 'Eligible',
  attended = 'Attended',
  noteligible = 'Not Eligible',
  waitlisted = 'Waitlisted',
  noShow = 'No Show',
  slotSend = 'Slot Sent',
  submittedPartially = 'Submitted Partially',
  submissionPending = 'Submission Pending',
  partiallyApproved = 'Approved Partially',
  joiningConfirmed = 'Joining Confirmed',
  mailPending = 'Mail Pending',
  joined = 'Joined',
  notJoining = 'Not Joining',
  notJoined = 'Not Joined',
  yetToJoin = 'Yet To Join',
  offered = 'Offered',
  offerPending = 'Offer Pending',
  offerRevision = 'Offer Revision',
  offerAccepted = 'Offer Accepted',
  offerDeclined = 'Offer Declined',
  onHold = 'On Hold',
  futureCandidate = 'Future Candidate',
}

interface FilterCriteria{
  filterField : string,
  filterValue : string
}

@Component({
  selector: 'app-stage-candidates',
  templateUrl: './stage-candidates.component.html',
  styleUrls: ['./stage-candidates.component.css'],
  providers: [MessageService],
})
export class StageCandidatesComponent implements OnInit, OnChanges {
  @ViewChild ('cohortOP') cohortOP: any;
  @ViewChild ('cohortDropdown') cohortDropdown: any;
  @ViewChild ('errorReport') errorReport: any;
  
  @HostListener('window:scroll', ['$event'])
  onScrollEvent(event:any) {
    if(this.cohortDetails) {
      this.cohortOP.hide();
      this.cohortDropdown.nativeElement.blur();
      this.cohortDropdown.hide();
    }
  }

  @Input() stage: any;
  @Input() stages: any;
  @Input() contestId: string = '';
  @Input() testId: string = '';
  @Input() search: any;
  @Input() isFeedbackSelected: any;
  @Input() isTestSelected: any;
  @Input() isTemplateSelected: any;
  @Input() emailsCleared: any;
  @Input() statusSettings: any;

  @Output() emptyStage = new EventEmitter<boolean>();
  @Output() emailList = new EventEmitter<any>();
  @Output() candidateCount = new EventEmitter<any>();

  pageLimits: Array<number> = [];
  candidatesCol: Array<any> = [];
  _selectedColumns: Array<any> = [];
  candidateList: any = [];
  selectedUsersList: any = [];
  checkedColumns: Array<any> = [];
  columnsToSave: Array<any> = [];
  _candidatesCol: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  colsToDownload: Array<any> = [];
  stageList: Array<any> = [];
  sourceData: Array<any> = [];
  emails: Array<string> = [];
  allCandidateDetails: any = [];
  excel: File[] = [];
  cohortExcel: File[] = [];
  template: File[] = [];
  cohortFile: File[] = [];
  erroredFile: Array<any> = [];
  cohortErroredFile: Array<any> = [];
  bucketData: any = {};
  statusOptions: Array<any> = [];
  timeSlots: Array<any> = [];
  copyTimeSlots: Array<any> = [];
  candidateStatus: any = {};
  statusColor: Array<any> = [];
  loggedUserDetails: any = {};
  stageToMove: any = 0;
  activitySliderOptions: any = {};
  cohortStatuses: Array<any> = [];
  _cohortStatuses: Array<any> = [];
  selectedCohortStatus: any = {};
  newCohortStatus: any = {};
  eventUsers: any = [];
  selectedEventDetails: any;
  assignedCohortData: any = [];

  exportName: string = 'candidateList';
  cohortName: string = 'cohort_template';
  jobId: string = '';
  eventId: string = '';
  eventName: string = '';
  columnSearchTerm: string = '';
  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  scheduleRadio: string = 'all';
  schoolId: string = '';
  fileBase64: string = '';
  templateFile: string = '';
  currentStatus: string = '';
  activityRemarks: string = '';
  interview_buckName: string = '';
  stageType: string = '';
  cohortNameTerm: string = '';
  delayedStyle: string = '';
  sendMailHeader: string = '';

  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displayUserDetails: boolean = false;
  displaySaveColumnSettings: boolean = false;
  showUploadSlider: boolean = false;
  showScheduleSlider: boolean = false;
  uploadingFile: boolean = false;
  cohortUploadingFile: boolean = false;
  loadingDownload: boolean = false;
  uploadBtnLoading: boolean = false;
  scheduleBtnLoading: boolean = false;
  cohortBtnLoading: boolean = false;
  inEditMode: boolean = false;
  sendLinkSlider: boolean = false;
  clicked: boolean = false;
  templateUploaded: boolean = false;
  cohortLoader: boolean = false;
  templateLoader: boolean = false;
  excelValidation: boolean = false;
  stageActivitySlider: boolean = false;
  locationJobRoleDetails: boolean = false;
  cohortDetails: boolean = false;
  createCohortTable: boolean = false;
  selectCohortTable: boolean = false;
  loadingCohort: boolean = false;
  errorMessage = {isError: false, message: 'Kindly fill all the fields!'};
  clickedCohort: boolean = false;
  show: boolean = false;
  cohortUploadSuccess: boolean = false;
  cohortUploadFailed: boolean = false;
  sendDocumentation: boolean = false;

  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  timeDuration: number = 0;

  selectedUser: any;
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  startTime: any;
  endTime: any;
  templateSize: any;
  fileCopy: any;
  cohortUploadCopy: any;
  dateFormat: any;

  currentDate = new Date();

  ENV = environment.HOST.NODE_ENV;

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave),
  });

  sendLinkForm: FormGroup = new FormGroup({});
  eventCategory: string = '';
  sendEmailSlider: boolean = false;
  sendOfferMail: boolean = false;

  schlDetails: any = localStorage.getItem('schoolDetails');
  fromAddress = JSON.parse(this.schlDetails)[0].senderEmailId;
  filterCriteria: Array<FilterCriteria> = [];

  constructor(
    private jobService: JobServicesService,
    private eventServices: EventServicesService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private globalService: GlobalServicesService
  ) {
    this.jobId = localStorage.getItem('selectedJobId') || '';
    this.eventId = window.localStorage.getItem('selectedEventId') || '';
    this.eventName = window.localStorage.getItem('selectedEventName') || '';

    this.schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';

    this.pageLimits = [10, 25, 50, 100];

    this.candidatesCol = [
      { index: 1, field: 'email', header: 'E-mail' },
      { index: 2, field: 'status', header: 'Status', options: [{ label: 'All', value: '' }], selectedFilterElement: { label: 'All', value: '' } },
      { index: 3, field: 'stageId', header: 'Stage' },
      { index: 4, field: 'degree', header: 'Education' },
      { index: 6, field: 'cohortJobRole', header: 'Cohort Job Role'},
      { index: 7, field: 'cohortLocation', header: 'Cohort Location'},
      { index: 9, field: 'mobile_number', header: 'Phone' },
      { index: 10, field: 'gender', header: 'Gender' },
      { index: 11, field: 'dob', header: 'Date Of Birth' }
    ]

    this._selectedColumns = [
      { index: 1, field: 'email', header: 'E-mail' },
      { index: 2, field: 'status', header: 'Status', options: [{ label: 'All', value: '' }], selectedFilterElement: { label: 'All', value: '' } },
      { index: 3, field: 'stageId', header: 'Stage' },
      { index: 4, field: 'degree', header: 'Education' },
      { index: 9, field: 'mobile_number', header: 'Phone' },
      { index: 10, field: 'gender', header: 'Gender' },
    ]

    this.timeSlots = this.generateTimeSlots();

    this.sendLinkForm = new FormGroup({
      data: new FormControl([]),
      interviewDate: new FormControl(null, [Validators.required]),
      startTime: new FormControl(null, [Validators.required]),
      endTime: new FormControl(null, [Validators.required]),
      stageId: new FormControl(),
    });

    this.candidateStatus = {
      pending: 'Pending',
      shortlisted: 'ShortList',
      rejected: 'Rejected',
      waitlisted: 'Waitlisted',
    };
  }

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    // this.globalService.dateFormat.subscribe((res: any) => {
    //   this.dateFormat = res;
    // })

    this.addColumns();
    this.sourceData = [
      {
        fieldName: 'stageId',
        value: this.stage.stageId,
        fieldType: 'number',
      },
      {
        fieldName: 'jobStatus',
        value: 'pending',
        fieldType: 'string',
      },
    ];

    this.statusOptions = this.chooseStatusOptions(this.stage);
    this.candidatesCol.find((col: any) => col.field == 'status').options.push(...this.statusOptions);
    this._selectedColumns.find((col: any) => col.field == 'status').options.push(...this.statusOptions);

    if (this.stage.type === 'Offer') {
      let jobStatusFilter = [
        { label: 'All', value: '' },
        { label: 'Software', value: 'Software' },
        { label: 'Production', value: 'Production' },
        { label: 'Unassigned', value: 'Nil' }
      ]

      this.candidatesCol.forEach((each: any) => {
        if (each.field === 'cohortJobRole') {
          each.options = jobStatusFilter
        }
      })

      this._selectedColumns.forEach((each: any) => {
        if (each.field === 'cohortJobRole') {
          each.options = jobStatusFilter
        }
      })
    }
    this.loggedUserDetails = JSON.parse(
      localStorage.getItem('userDetails') || ''
    );

    this.getCandidates();
    this.getAllUsers();
    this._candidatesCol = this.candidatesCol;
    this.colsToDownload = this.candidatesCol;
    this.checkedColumns = this._selectedColumns;

    if (this.stages) {
      this.stages.forEach((stage: any) => {
        this.stage != stage &&
        this.stageList.push({ name: stage.name, stageId: stage.stageId, type: stage.type });
        // if(this.stage.type === 'Offer')
        // {
        //   this.stage != stage && 
        //   stage.stageId > this.stage.stageId && 
        //   this.stageList.push({ name: stage.name, stageId: stage.stageId, type: stage.type });
        // }
        // else
        // {
        //   this.stage != stage &&
        //   this.stageList.push({ name: stage.name, stageId: stage.stageId, type: stage.type });
        // }
      });
    }
    this.copyTimeSlots = this.timeSlots;

    this.statusColor = [
      { status: 'applied', color: 'var(--clr-violet-bg-light)' },
      { status: 'scheduled', color: 'var(--clr-violet-bg-light)' },
      { status: 'addedToVettedPool', color: 'var(--clr-violet-bg-light)' },
      { status: 'slotSend', color: 'var(--clr-violet-bg-light)' },
      { status: 'submitted', color: 'var(--clr-violet-bg-light)' },
      { status: 'joiningConfirmed', color: 'var(--clr-violet-bg-light)' },
      { status: 'offered', color: 'var(--clr-violet-bg-light)' },

      { status: 'offerAccepted', color: 'var(--clr-green-bg-light)' },
      { status: 'offerRevision', color: 'var(--clr-blue-bg-light)' },
      { status: 'offerDeclined', color: 'var(--clr-red-bg-light)' },

      { status: 'selected', color: 'var(--clr-green-bg-light)' },
      { status: 'shortlisted', color: 'var(--clr-green-bg-light)' },
      { status: 'allClear', color: 'var(--clr-green-bg-light)' },
      { status: 'eligible', color: 'var(--clr-green-bg-light)' },
      { status: 'joined', color: 'var(--clr-green-bg-light)' },

      { status: 'waitlisted', color: '#FFE0B4' },
      { status: 'noShow', color: '#FFE0B4' },
      { status: 'submittedPartially', color: '#FFE0B4' },
      { status: 'notJoining', color: '#FFE0B4' },

      { status: 'rejected', color: 'var(--clr-red-bg-light)' },
      { status: 'noteligible', color: 'var(--clr-red-bg-light)' },
      { status: 'notJoined', color: 'var(--clr-red-bg-light)' },

      { status: "pending", color: 'var(--clr-blue-bg-light)' },
      { status: "submissionPending", color: 'var(--clr-blue-bg-light)' },
      { status: 'offerPending', color: '--clr-blue-bg-light' },
      { status: 'mailPending', color: '--clr-blue-bg-light' },
      
      { status: "attended", color: '#E1E6CC' },
      { status: "partiallyApproved", color: '#E1E6CC' },
      { status: "futureCandidate", color: '#E1E6CC' },
      { status: 'yetToJoin', color: '#E1E6CC' },

      { status: 'archived', color: 'var(--clr-grey)' },
      { status: 'onHold', color: '#7a859275' },
    ];
    this.eventCategory =
      window.localStorage.getItem('eventCategory') || 'technical';
    this.getCohortStatuses();
    this.getEventDetails();
  }

  chooseStatusOptions = (stage: any): any => {
    switch (true) {
      case stage.stageId == 1: {
        let statusSettings = this.statusSettings['Eligibility'];
        this.candidateStatus = statusSettings;
        return [
          { label: statusSettings['pending'], value: 'pending', actionId: 'pending', disable: true, }, 
          { label: statusSettings['applied'], value: 'applied', actionId: 'applied', disable: true, },
          { label: statusSettings['eligible'], value: 'eligible', actionId: 'eligibleCandidate', },
          { label: statusSettings['waitlisted'], value: 'waitlisted', actionId: 'waitlistCandidate', disable: true, },
          { label: statusSettings['noteligible'], value: 'noteligible', actionId: 'noteligibleCandidate', },
        ];
      }
      case stage.type == 'Test': {
        let statusSettings = this.statusSettings[stage.type];
        this.candidateStatus = statusSettings;
        return [
          { label: statusSettings['pending'], value: 'pending', actionId: 'pending', disable: true, },
          { label: statusSettings['shortlisted'], value: 'shortlisted', actionId: 'shortlistCandidate', },
          { label: statusSettings['rejected'], value: 'rejected', actionId: 'rejectCandidate' },
          { label: statusSettings['waitlisted'], value: 'waitlisted', actionId: 'waitlistCandidate', },
          { label: statusSettings['scheduled'], value: 'scheduled', actionId: '', disable: true, },
          { label: statusSettings['attended'], value: 'attended', actionId: '', disable: true },
          { label: statusSettings['noShow'], value: 'noShow', actionId: 'noShow' },
        ];
      }
      case stage.type == 'Screening': {
        let statusSettings = this.statusSettings[stage.type];
        this.candidateStatus = statusSettings;
        return [
          { label: statusSettings['mailPending'], value: 'mailPending', actionId: 'pending', disable: true, },
          { label: statusSettings['submitted'], value: 'submitted', actionId: 'submitted' },
          { label: statusSettings['submittedPartially'], value: 'submittedPartially', actionId: 'submittedPartially' },
          { label: statusSettings['partiallyApproved'], value: 'partiallyApproved', actionId: 'partiallyApproved' },
          { label: statusSettings['submissionPending'], value: 'submissionPending', actionId: '', disable: true },
          { label: statusSettings['allClear'], value: 'allClear', actionId: 'allClear' },
          { label: statusSettings['shortlisted'], value: 'shortlisted', actionId: 'shortlistCandidate', },
          { label: statusSettings['waitlisted'], value: 'waitlisted', actionId: 'waitlistCandidate', },
          { label: statusSettings['rejected'], value: 'rejected', actionId: 'rejectCandidate' },
        ];
      }
      case stage.type == 'Interview': {
        let statusSettings = this.statusSettings[stage.type];
        this.candidateStatus = statusSettings;
        return [
          { label: statusSettings['pending'], value: 'pending', actionId: 'pending', disable: true, },
          { label: statusSettings['shortlisted'], value: 'shortlisted', actionId: 'shortlistCandidate', },
          { label: statusSettings['rejected'], value: 'rejected', actionId: 'rejectCandidate' },
          { label: statusSettings['waitlisted'], value: 'waitlisted', actionId: 'waitlistCandidate', },
          { label: statusSettings['slotSend'], value: 'slotSend', actionId: 'slotSend' },
          { label: statusSettings['noShow'], value: 'noShow', actionId: 'noShow' },
        ];
      }
      case stage.type == 'OnBoarding': {
        let statusSettings = this.statusSettings[stage.type];
        this.candidateStatus = statusSettings;
        return [
          { label: statusSettings['pending'], value: 'pending', actionId: 'pending', disable: true, },
          { label: statusSettings['joined'], value: 'joined', actionId: 'joined' },
          { label: statusSettings['notJoined'], value: 'notJoined', actionId: 'notJoined' },
          { label: statusSettings['joiningConfirmed'], value: 'joiningConfirmed', actionId: 'joiningConfirmed', },
          { label: statusSettings['notJoining'], value: 'notJoining', actionId: 'notJoining' },
          { label: statusSettings['yetToJoin'], value: 'yetToJoin', actionId: 'yetToJoin' },
        ];
      }
      case stage.stageType == 'Others':
      case stage.name == 'Vetted Pool': {
        let statusSettings = this.statusSettings[stage.type];
        this.candidateStatus = statusSettings;
        return [
          { label: statusSettings['pending'], value: 'pending', actionId: 'pending', disable: true, },
          { label: statusSettings['addedToVettedPool'], value: 'addedToVettedPool', actionId: 'addedToVettedPool', },
          { label: statusSettings['shortlisted'], value: 'shortlisted', actionId: 'shortlistCandidate', },
          { label: statusSettings['waitlisted'], value: 'waitlisted', actionId: 'waitlistCandidate', },
          { label: statusSettings['rejected'], value: 'rejected', actionId: 'rejectCandidate' },
        ];
      }
      case stage.type == 'Offer': {
        let statusSettings = this.statusSettings[stage.type];
        this.candidateStatus = statusSettings;
        return [
          // { label: 'Pending', value: 'pending', actionId: 'pending', disable: true},
          { label: statusSettings['onHold'], value: 'onHold', actionId: 'onHold' },
          { label: statusSettings['futureCandidate'], value: 'futureCandidate', actionId: 'futureCandidate'},
          { label: statusSettings['offered'], value: 'offered', actionId: 'offered' },
          { label: statusSettings['offerPending'], value: 'offerPending', actionId: 'offerPending'},
          { label: statusSettings['offerRevision'], value: 'offerRevision', actionId: 'offerRevision' },
          { label: statusSettings['offerAccepted'], value: 'offerAccepted', actionId: 'offerAccepted' },
          { label: statusSettings['offerDeclined'], value: 'offerDeclined', actionId: 'offerDeclined' },
          { label: statusSettings['waitlisted'], value: 'waitlisted', actionId: 'waitlistCandidate' },
        ];
      }
      default: {
        return [
          { label: 'Pending', value: 'pending', actionId: 'pending', disable: true, },
          { label: 'ShortList', value: 'shortlisted', actionId: 'shortlistCandidate', },
          { label: 'Rejected', value: 'rejected', actionId: 'rejectCandidate' },
          { label: 'Waitlisted', value: 'waitlisted', actionId: 'waitlistCandidate', },
        ];
      }
    }
  };

  getAllUsers(){
    this.eventServices.getAllUser().subscribe(res => {
      this.eventUsers = res?.data?.items;
    })
  }

  ngOnChanges(): void {
    if (this.search?.length) this.getCandidates();
    if(this.emailsCleared){
      this.selectedUsersList = [];
    }
  }

  filterUpdate(filterValue: any, filterField: string, col: any) {
    col.selectedFilterElement = filterValue;
    // this.candidatesCol.find((each:any) => each.fiels == col.field).selectedFilterElement = filterValue;
    if (filterField == 'status') filterField = 'jobStatus';

    const i = this.filterCriteria.findIndex(e => e.filterField === filterField) ?? null;

    if (this.filterCriteria.length && (i >= 0)) {
      this.filterCriteria[i] = { filterField: filterField, filterValue: filterValue.value };
    } else {
      this.filterCriteria.push({ filterField: filterField, filterValue: filterValue.value });
    }
    this.filterCriteria = this.filterCriteria.filter((field: FilterCriteria) => field.filterValue != '');

    this.getCandidates();
  }

  handleSelection = (event: any) => {
    this.emails = event.map((user: any) => user.email);
    if(this.stage.type == 'Offer'){
      let cohortEmails = event.map((user: any) => user.email);
      this.emailList.emit(cohortEmails);
    }
  };

  addColumns = () => {
    let testCompletedStage: Array<any> = [];

    testCompletedStage = this.stages.filter((stage: any) => stage.stageId <= this.stage.stageId && stage.type == 'Test');
    testCompletedStage.forEach(stage => {
        this.candidatesCol.unshift({ index: 1, field: 'testScore', stageId: stage.id, header: stage.name + ' Test Score', testSchedule: stage.scheduleTest });
        this._selectedColumns.unshift({ index: 1, field: 'testScore', stageId: stage.id, header: stage.name + ' Test Score', testSchedule: stage.scheduleTest });
    });

    if (this.stage.type == 'Screening') {
      this.candidatesCol.push({
        index: 1,
        field: 'isDocumentsSubmitted',
        header: 'Document',
      });
      this._selectedColumns.push({
        index: 1,
        field: 'isDocumentsSubmitted',
        header: 'Document',
      });
    }

    if (this.stage.type == 'Offer') {
      this.candidatesCol.push({
        index: 1,
        field: 'offerResponse',
        header: 'Offer Response',
      });
      this._selectedColumns.push({
        index: 1,
        field: 'offerResponse',
        header: 'Offer Response',
      });
    }

    if(this.stage.type == 'Interview'){
      this.candidatesCol.splice(2, 0, ({ index: 3, field: 'feedbackStatus', header: 'Feedback Status'}));
      this._selectedColumns.splice(2, 0, ({ index: 3, field: 'feedbackStatus', header: 'Feedback Status'}));
      this.stageType = this.stage.type;
    }

    if(this.stage.type == 'Offer'){
      this.candidatesCol.splice(4, 0, 
        ({ index: 5, field: 'cohort_name', header: 'Cohort Name'}),
        ({ index: 6, field: 'cohort_date', header: 'Cohort Date'}),
        ({ index: 7, field: 'cohort_spoc', header: 'Cohort SPOC'})
      );
      this._selectedColumns.splice(4, 0, 
        ({ index: 5, field: 'cohort_name', header: 'Cohort Name'}),
        ({ index: 6, field: 'cohort_date', header: 'Cohort Date'}),
        ({ index: 7, field: 'cohort_spoc', header: 'Cohort SPOC'}),
        ({ index: 6, field: 'cohortJobRole', header: 'Cohort Job Role'}),
        ({ index: 7, field: 'cohortLocation', header: 'Cohort Location'}),
      );      
    }
    if(this.stage.type == 'Others'){
      this._selectedColumns.splice(4, 0, ({ index: 6, field: 'cohortJobRole', header: 'Cohort Job Role'}),
      ({ index: 7 , field: 'cohortLocation', header: 'Cohort Location'}));
      this.stageType = this.stage.type;
    }
  };

  templateDownload(){
    let type = this.scheduleRadio;
    this.eventServices.getScheduleDownloadTemplate(this.eventId, this.stage.stageId,type).subscribe((response: any) => {
      this.interview_buckName = 'dev-neo-hire-asset';
      window.open(response.data,'_blank');
    })
  }

  getEventDetails(){
    this.eventServices.getEventById(this.eventId).subscribe(response => {
      this.selectedEventDetails = response.data ? response.data : {};
    });
  }

  handleUpload(event: any) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file);
    };
  }

  fileUpload(file: any) {
    this.uploadingFile = true;
    let fileName = file.name.split('.');
    let fileExtension = fileName.pop();
    fileName = `${fileName
      .join()
      .replace(/\s/g, '')
      .replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    this.bucketData = {
      Bucket_name: this.bucketName,
      file_name: `manage-events/bulk-movestage/${this.schoolId}/${
        this.eventId
      }/${nanoid(10)}/${fileName}`,
      type: file.type,
    };

    const blobData: any = file;

    this.fileUploadService
      .getSignedUrl(this.bucketData)
      .subscribe((url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService
            .uploadUsingSignedUrl(json.data, blobData)
            .subscribe((r: any) => {
              this.fileBase64 = `${this.s3Domain}/${this.bucketName}/${this.bucketData.file_name}`;
              this.uploadingFile = false;
            });
        }
      });
  }

  onSelectExcel(event: any) {
    this.excel = event.addedFiles;
    this.handleUpload(event);
  }

  uploadTemplate(event: any) {
    this.template = event.addedFiles;
    this.templateUploaded = true;
    this.templateLoader = true; 
    let sizeInKb = parseInt(Math.floor(this.template[0].size / 1024).toFixed(2));
    this.templateSize = sizeInKb > 1024 ? (sizeInKb / 1024).toFixed(2) + ' MB' : sizeInKb + ' KB';

    this.fileCopy = this.template[0];
  }

  bulkSchedule(){
    this.erroredFile = [];
    this.templateUploaded = false;
    this.scheduleBtnLoading = false;
    this.template = [];
    this.scheduleRadio = 'all';
  }

  scheduleFileUpload(){
    this.scheduleBtnLoading = true;
    if(this.template.length){
      let file = this.fileCopy;
      let fileName = file.name.split('.');
      let fileExtension = fileName.pop();
      fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
      
      const pay = {
        Bucket_name: 'dev-neo-hire-asset',
        file_name : `Bulk-send-interview-link/Validated-file/${this.schoolId}/${this.eventId}/${nanoid(10)}/${fileName}`,
        type: file.type,

      };

      const blobData: any = file;
      this.fileUploadService.getSignedUrl(pay).subscribe(
        (url: any) => {
          if (url) {
            const json = url;
            this.fileUploadService.uploadUsingSignedUrl(
              json.data,
              blobData
            ).subscribe(
              (r: any) => {
                if (r && r.status === 200) {
                  let payload ={
                    Bucket_name : pay.Bucket_name,
                    file_name : pay.file_name,
                    schoolId : this.schoolId,
                    eventId : this.eventId,
                    stageId : this.stage.stageId
                  }
                  this.eventServices.postScheduleUpload(payload, this.isTemplateSelected?.templateId).subscribe((response: any) => {
                    if(response.status === 200){
                      if(response.data?.erroredFile){
                        if(response.data?.erroredFile.length > 0 &&response.data?.validatedData.length > 0 && response.data?.mailDetails.length > 0){
                          let time = new Date();
                          
                          let error = {
                            erroredFile : response.data.erroredFile,
                            time: time,
                          }
                          this.erroredFile.splice(0,0,error);
    
                          this.messageService.add({
                            severity: 'warn',
                            summary: 'Partially Successfull',
                            detail: 'Please Check the Error File',
                          });
                        }else if(response.data?.erroredFile.length > 0 && response.data?.validatedData.length == 0 && response.data?.mailDetails.length == 0){
                          let time = new Date();
                          
                          let error = {
                            erroredFile : response.data.erroredFile,
                            time: time,
                          }
                          this.erroredFile.splice(0,0,error);
                          
                          this.messageService.add({
                              severity: 'error',
                              summary: 'Error',
                              detail: 'Invalid File Please check the Errored File',
                            });
                        }
                      }
                      else{
                        if(response.data?.validatedData.length == 0 && response.data?.mailDetails.length == 0){
                            this.messageService.add({
                              severity: 'error',
                              summary: 'File Empty',
                              detail: 'No Data in the File',
                            });
                        }else{
                          this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Uploaded Successfully',
                          });
                        } 
                      }
                    }else{
                      this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Failed to Submit',
                      });
                    }
                    this.scheduleBtnLoading = false;
                  })
                }
              }
            );
          }
        }
      );
    }else{
    this.scheduleBtnLoading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to Submit',
      });
    }
}

errorFileDownload(file:any){
    window.open(file,'_blank');
}


onRemoveExcel(event: any) {
    this.excel.splice(this.excel.indexOf(event), 1);
  }

  uploadCandidates = (stageId: number) => {
      if (this.fileBase64.length) {
        this.sourceData[0].value = stageId;
        this.uploadBtnLoading = true;
        let payload = {
          Bucket_name: this.bucketData.Bucket_name,
          file_name: this.bucketData.file_name.replace(/\s/g, ''),
          jobId: this.jobId,
          actionId: 'fileMoveStage',
          sourceData: this.sourceData,
        };
        this.eventServices.candidteBulkMoveStage(payload).subscribe((res) => {
          if (Object.keys(res.data).length !== 0) {
            this.excel = [];
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Candidates Moved Successfully',
            });
          } else
            this.messageService.add({
              severity: 'error',
              summary: 'Failed',
              detail: 'Unable to Move Candidates',
            });
          this.uploadBtnLoading = false;
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Failed',
          detail: 'Select a file before uploading candidated.',
        });
      }
    // }
  };


  activitySliderSubmit = (options: any) => {
    if (options.action == 'moveStage') {
      this.moveCandidates(options.toOptions);
    }
    else if (options.action == 'statusUpdate') {
      this.changeStatus(options.toOptions);
    }
  }

  moveCandidates = async(destinationStage: any) => {
    let destinationStatus = '';
    switch(destinationStage.type){
      case 'Offer' : destinationStatus = 'offerPending'; break;
      case 'Screening' : destinationStatus = 'mailPending'; break;
      default: destinationStatus = 'pending';
    }
    this.uploadBtnLoading = true;
      this.loadingSpinner = true;
      this.sourceData[0].value = destinationStage.stageId;
      this.sourceData[1].value = destinationStatus;
      this.sourceData[2] = {
        user: this.loggedUserDetails.email,
        comments: this.activityRemarks
      }
  
      let payload: any = [];
  
      if (this.selectedUsersList.length > 1) {
        payload = [
          { sourceData: this.sourceData, source: [{ email: this.emails }] },
        ];
      } else {
        this.selectedUsersList.map((user: any) => {
          payload.push({ sourceData: this.sourceData, source: [{ data: user }] });
        });
      }
      this.eventServices.multiCandidateMoveStage(this.jobId, payload).subscribe(res => {
        if(this.selectedUsersList.length == 1){
          this.getCandidates();
        }else{
          this.messageService.add({severity:'info', summary:'Message', detail:'Bulk process has been initiated. Kindly refresh after some times.'});
          this.loadingSpinner = false;
        }
        this.selectedUsersList = [];
        this.uploadBtnLoading = false;
        this.activityRemarks = '';
        this.stageActivitySlider = false;
      })
  }

    
  getCohortStatuses = () => {
    this.eventServices.getAllCohortId().subscribe(res => {
      this.cohortStatuses = res.data;
      this._cohortStatuses = this.cohortStatuses;
      this._cohortStatuses.sort((a: any, b: any) => +new Date(b.createdAt) - +new Date(a.createdAt));
    })
  }

  onHideActivity = () => {
    this.getCandidates();
  }

  moveStageSlider = (stage: any, stageOverlay: any) => {
    this.cohortDetails = stage.type == 'Offer' ? true : false; 
    this.activitySliderOptions = {
      header: 'Move Stage',
      action: 'moveStage',
      from: this.stage.name,
      to: stage.name,
      toOptions: stage
    }
    this.stageActivitySlider = true;
    stageOverlay.hide();
  };

  isJobRoleLocationSubmitted(){
    let isValid = true;
    this.selectedUsersList.forEach((user:any) => {
      if(!user?.cohortJobRole || !user?.cohortLocation){
        isValid = false;
      }
    });
    return isValid;
  }

  changeStatusSlider = (status: any, user?:any) => {
    if(user) this.selectedUsersList = [user];
    let fromStatus = this.statusOptions.filter((each:any) => each.value == this.selectedUsersList[0].status)[0].label;
    this.activitySliderOptions = {
      header: 'Status Update',
      action: 'statusUpdate',
      from: fromStatus,
      to: status.label,
      toOptions: status,
    }
    this.stageActivitySlider = true;
  }

  generateTimeSlots = (): Array<any> => {
    let x = 15;
    let times = [];
    let tt = 0;
    let ap = ['AM', 'PM'];
    let tempDate = new Date().toISOString().split('T')[0];

    for (let i = 0; i < (24 * 60) / x; i++) {
      let hh = Math.floor(tt / 60);
      let mm = tt % 60;
      let hh12 = hh % 12;
      if (hh12 === 0) {
        hh12 = 12;
      }
      let slot =
        ('0' + hh12).slice(-2) +
        ':' +
        ('0' + mm).slice(-2) +
        ' ' +
        ap[Math.floor(hh / 12)];

      let slot_to_24 = slot;
      if (slot.split(' ')[1] == 'PM' && slot.split(':')[0] != '12') {
        let slotArr = slot.split(':');
        slotArr[0] = String(Number(slot.split(':')[0]) + 12);
        slot_to_24 = slotArr.join(':');
      }

      let utc = new Date(tempDate + ' ' + slot_to_24.split(' ')[0])
        .toISOString()
        .split('T')[1];
      times[i] = { timeSlot: slot, utcTime: utc };
      tt = tt + x;
    }
    return times.slice(28, 88);
  };

  dateChange() {
    this.sendLinkForm.controls['startTime'].setValue('');
    this.sendLinkForm.controls['endTime'].setValue('');
    let time = new Date().toISOString().split('T')[1];
    let copySlots = this.timeSlots;
    if (this.sendLinkForm.controls['interviewDate'].value == this.currentDate) {
      this.timeSlots = copySlots.filter(
        (element: any) => element.utcTime > time
      );
    } else {
      this.timeSlots = this.copyTimeSlots;
    }
  }

  sidenavClosed() {
    this.sendLinkForm.reset();
  }

  sendInterviewLink = (startTime: any, endTime: any) => {
    console.log(startTime,endTime,'time')
    this.clicked = true;
    this.sendLinkForm.controls['data'].setValue(this.selectedUsersList);
    this.sendLinkForm.controls['stageId'].setValue(this.stage.stageId);
    let payload = this.sendLinkForm.value;
    payload.interviewDate.setHours(payload.interviewDate.getHours() + 5);
    payload.interviewDate.setMinutes(payload.interviewDate.getMinutes() + 30);
    if (startTime.selectedOptionUpdated) {
      payload.startTime = this.changeFormat(startTime.value);
    }
    if (endTime.selectedOptionUpdated) {
      payload.endTime = this.changeFormat(endTime.value);
    }
    let startT = payload.startTime;
    let endT = payload.endTime;
    
    if(endT <= startT || startT > endT){
      this.sendLinkForm.controls['endTime'].setErrors({'incorrect': true});
    } else{
      this.sendLinkForm.controls['endTime'].setErrors(null);
    }

    if (this.sendLinkForm.valid) {
      this.uploadBtnLoading = true;
      this.eventServices
        .sendInterviewLink(this.jobId, this.stage.id, payload, this.isTemplateSelected?.templateId)
        .subscribe((res) => {
          if (res.status == 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res.data,
            });
            this.sendLinkForm.reset();
            this.clicked = false;
            this.sendLinkSlider = false;
            this.getCandidates();
            this.selectedUsersList = [];
          }
          this.uploadBtnLoading = false;
        });
      this.currentDate = new Date();
    } else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong!' });
      this.uploadBtnLoading = false;
    }
  };

  sendTestLink(users: any) {
    this.loadingSpinner = true;
    let payload = { data: users };
    this.eventServices
      .sendTestLink(payload, this.eventId, this.stage.id, this.eventCategory, this.isTemplateSelected?.templateId)
      .subscribe((res) => {
        if (res.status == 200)
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Test link sent',
          });
        this.loadingSpinner = false;
        this.getCandidates();
        this.selectedUsersList = [];
      });
  }

  changeFormat = (value: string): string => {
    let tempDate = new Date().toISOString().split('T')[0];
    let ap = value.split(' ')[1];
    if (ap == 'PM' || ap == 'pm') {
      let hh = Number(value.split(' ')[0].split(':')[0]);
      if (hh != 12) {
        hh += 12;
        value = hh + ':' + value.split(':')[1].split(' ')[0];
      }
    }
    return new Date(tempDate + ' ' + value.split(' ')[0])
      .toISOString()
      .split('T')[1];
  };

  getCandidates = () => {
    //Cohort Funcitons
    this.cohortBtnLoading = false;
    this.cohortErroredFile = [];
    
    //Move Stage Funcitons
    this.loadingSpinner = true;
    this.emailList.emit([]);
    this.candidateList = [];
    if (this.search || this.filterCriteria.length) {
      let payload: any = {};
      let searchTerm: string = '';
      if (this.search && this.search.length === 1) {
        searchTerm = this.search[0];
      } else {
        payload.search = this.search;
        payload.filterCriteria = this.filterCriteria;
      }
      this.eventServices
        .getSearchWiseCandidate(
          payload,
          this.stage.stageId,
          this.jobId,
          searchTerm,
          this.pageNumber,
          this.pageSize
        )
        .subscribe((result: any) => {
          this.candidateList = result?.data ? result.data.items : [];
          this.totalLength = result.data.totalItems || 0;
          if(this.stage.type == 'Offer'){
            this.candidateCount.emit(this.totalLength);
            this.candidateList.forEach((candidate: any) => {
              if(candidate?.userCohortData.cohort_spoc){
                candidate.userCohortData.cohort_spoc = this.eventUsers.filter((user: any) => user.id == candidate?.userCohortData.cohort_spoc)[0];
              }
            });
          }
          this.loadingSpinner = false;
        });
    } else {
      this.eventServices
        .getCandidatesByStage(
          this.jobId,
          this.stage.stageId,
          this.pageNumber,
          this.pageSize
        )
        .subscribe((result: any) => {
          this.candidateList = result?.data ? result.data.items : [];
          if (this.candidateList.length == 0) this.emptyStage.emit(true);
          else this.emptyStage.emit(false);
          this.totalLength = result.data.totalItems || 0;
          if(this.stage.type == 'Offer'){
            this.candidateCount.emit(this.totalLength);
            this.candidateList.forEach((candidate: any) => {
              if(candidate?.userCohortData.cohort_spoc){
                candidate.userCohortData.cohort_spoc = this.eventUsers.filter((user: any) => user.id == candidate?.userCohortData.cohort_spoc)[0];
              }
            });
          }
          if (this.selectedUsersList.length) {
            let _selectedUsersList: any = [];
            this.candidateList.forEach((user: any) => {
              this.selectedUsersList.forEach((each: any) => {
                if (user.id == each.id) _selectedUsersList.push(user)
              });
            });
            this.selectedUsersList = _selectedUsersList;
          }
          this.loadingSpinner = false;
        });
      }
  };

  getUsersByLimit = () => {
    this.pageNumber = 1;
    this.first = 1;
    this.last =
      this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getCandidates();
  };

  exportCandidate = (type: string) => {
    let checkedColumnFields: any = ['name', 'id'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    if(this.selectedUsersList.length){
      afterFilter = this.processExcel(this.selectedUsersList, checkedColumnFields, type);
    } else {
      afterFilter = this.processExcel(this.candidateList, checkedColumnFields, type);
    }
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: type === 'excel' ? 'xlsx' : 'csv',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  };

  processExcel = (usersList: Array<any>, checkedColumnFields: any, type: string) => {
    let afterFilter: any = [];
    usersList.forEach((eachData: any) => {
      eachData.feedbackStatus = (eachData.feedbackStatus) ? eachData.feedbackStatus : 'Feedback not Submitted';
      eachData.testScore = (eachData.testScore instanceof Array && eachData.testScore.length > 0) ? (eachData.testScore[0].marks + '/' + eachData.testScore[0].total_marks) : '-';
      let filterData: any = {};
      if (this.stage.type == 'Others') {
        if (!eachData.location) { eachData.location = '-' }
        if (!eachData.jobRole) { eachData.jobRole = '-' }
      }
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
        switch(item)
        {
          case "cohort_name" :
            filterData[item] = eachData.userCohortData[item] ?? '-';
            break;
          case "cohort_date" :
            filterData[item] = eachData.userCohortData['date'] ?? '-';
            break;
          case "cohort_spoc" :
            filterData[item] = eachData.userCohortData[item]?.email ?? '-';
            break;
        }
      });
      afterFilter.push(filterData);
    });
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: type === 'excel' ? 'xlsx' : 'csv',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  };

  exportAllCandidates = (type: string) => {
    this.loadingDownload = true;
    this.eventServices
      .getAllCandidatesByStage(this.jobId, this.stage.stageId)
      .subscribe((res: any) => {
        let candidateList = res.data;
        this.loadingDownload = false;
        import('xlsx').then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(candidateList);
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
          };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: type === 'excel' ? 'xlsx' : 'csv',
            type: 'array',
          });
          this.saveAsExcelFile(excelBuffer, this.exportName, type);
        });
      });
  };
  exportRequiredFields = (type: string) => {
    this.loadingDownload = true;
    if(this.selectedUsersList.length > 0){
      let payload = {
        eventId: this.eventId,
        stageId: this.stage.stageId,
        emails: this.emails
      }
      this.eventServices.getMultipleCohortDownload(payload).subscribe(res => {
        let cohortList = res.data;
        this.loadingDownload = false;
        import('xlsx').then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(cohortList);
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
          };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: type === 'excel' ? 'xlsx' : 'csv',
            type: 'array',
          });
          this.saveCohortExcelFile(excelBuffer, this.cohortName, type);
        });
      })
    } else {
      this.eventServices
        .getCohortDownload(this.eventId, this.stage.stageId)
        .subscribe((res: any) => {
          let cohortList = res.data;
          this.loadingDownload = false;
          import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(cohortList);
            const workbook = {
              Sheets: { data: worksheet },
              SheetNames: ['data'],
            };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: type === 'excel' ? 'xlsx' : 'csv',
              type: 'array',
            });
            this.saveCohortExcelFile(excelBuffer, this.cohortName, type);
          });
        });
    }
  };

  saveCohortExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  async getResultAnalysis(user: any, stageId: any) {
    this.globalService.getScheduleTest(user.email, this.jobId, stageId).subscribe((test: any) => {
      this.loadingSpinner = true;
      if (test.data.length > 0) {
        let scheduleTest = test.data[0];
        let contestId = scheduleTest.test_id.slice(0, 36);
        let testId = scheduleTest.test_id.slice(36, 72);
        let payload = {
          source: 'result-analysis',
        };
        this.globalService.getResultAnalysis(payload, contestId, testId, user.email).subscribe((resp: any) => {
          let url = resp.data.data;
          if (resp.data.data && resp.data.data.includes('https')) {
            let target_host = 'examly.io';
            if (this.ENV === 'dev') {
              target_host = 'exam.ly';
            } else if (this.ENV === 'acc') {
              target_host = 'examly.net';
            }
            url = url.replace('examly.io', target_host);
            window.open(url, '_blank');
            this.loadingSpinner = false;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'An Error occured',
              detail: 'Try again after some time !',
            });
            this.loadingSpinner = false;
          }
        })
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Analysis not found',
          detail: 'Candidate yet to start the test !',
        });
        this.loadingSpinner = false;
      }
    });
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.jobService
      .deleteColumnCandidates(this.jobId, columnId)
      .subscribe((res) => {
        this.savedColumnSettings = res.data?.selectedColumns;
        this.loadingSpinner = false;
      });
  };

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  };

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  };

  showSaveColumnSettings = () => {
    this.displaySaveColumnSettings = true;
  };

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._candidatesCol = this.candidatesCol.filter((val) =>
        val.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.candidatesCol.filter((val) =>
        val.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  };

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.candidatesCol.filter((e) =>
      savedColumnValue.includes(e.field)
    );
  };

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index ? -1 : 1));
  }

  setStatusDropdown = (status: any) => {
    this.statusOptions.forEach((each: any) => {
      if (status == each.value) this.currentStatus = each;
    });
  };

  changeStatus = (status: any) => {
    this.loadingSpinner = true;
    this.uploadBtnLoading = true;
    this.sourceData[1].value = status.value;
    this.sourceData[2] = {
        user: this.loggedUserDetails.email,
        comments: this.activityRemarks
      }
    
    let payload: any = [];

    if (this.selectedUsersList.length == 1) {
      payload.push({ sourceData: this.sourceData, source: [{ data: this.selectedUsersList[0] }] });
      this.eventServices.candidateChangeStatus(this.jobId, status.actionId, payload).subscribe(() => {
        this.getCandidates();
      })
    }
    else {
      payload = [{ sourceData: this.sourceData, source: [{ email: this.emails }] }];
      this.eventServices.multipleCandidateChangeStatus(this.jobId, status.actionId, payload).subscribe(() => {
        this.messageService.add({ severity: 'info', summary: 'Message', detail: 'Bulk process has been initiated. Kindly refresh after some times.' });
        this.loadingSpinner = false;
      })
    }
    this.selectedUsersList = [];
    this.uploadBtnLoading = false;
    this.activityRemarks = '';
    this.stageActivitySlider = false;
  }

  // Pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last =
      this.last + this.pageSize < this.totalLength
        ? this.pageSize + this.last
        : this.totalLength;
    this.pageNumber += 1;
    this.getCandidates();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last =
      this.last - this.pageSize >= this.totalLength
        ? this.totalLength - this.last
        : this.pageSize;
    this.pageNumber -= 1;
    this.getCandidates();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.candidatesCol.filter((col) =>
      val.includes(col)
    );
  }

  showCandidateDetails(userDetail: any) {
    this.displayUserDetails = true;
    this.selectedUser = userDetail;
  }

  sendEmail() {
    this.sendEmailSlider = true;
  }

  mailSent(event: boolean) {
    if(event){
      this.sendEmailSlider = false;
      this.sendOfferMail = false;
      this.sendDocumentation = false;
      this.getCandidates();
      this.selectedUsersList = [];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Mail Sent!' });
    }
  }

  clearFilter() {
    this.filterCriteria = [];
    this._selectedColumns.forEach(each => {
      if (each.options) each.selectedFilterElement = { label: 'All', value: '' }
    })
    this.getCandidates();
  }
}
