<div class="userList-header">
    <div class="header-tabs">
        <button class="tabs" [ngClass]="{'selected': displayCandidates}" (click)="showCandidates()">Candidates</button>
        <button class="tabs" [ngClass]="{'selected': displayJobDetails}" (click)="showJobDetails()">{{ isInEvent ?
            'Event' : 'Job'}} Details</button>
        <button class="tabs" [ngClass]="{'selected': displayWorkflow}" (click)="showWorkflow()">Stages</button>
        <button class="tabs" [ngClass]="{'selected': displayInterview}" (click)="showInterview()">Interview</button>
    </div>
    <div class="link-add-candidate" *ngIf="displayCandidates">
        <span (click)="copyMessage()" [life]="2000" tooltipEvent="focus" pTooltip="Link Copied" tooltipPosition="top">
            <div class="event-link">Copy Registration Link <p class="pi pi-link link-icon"></p>
            </div>
        </span>
        <div>
            <p-splitButton label="Add Candidates" class="addCandidates-button" styleClass="p-button-sm mr-2 mb-2"
                (onClick)="addCandidateForm()" [model]="this.bulkUpload"></p-splitButton>
            <p-sidebar class="addBulk-slider" [(visible)]="showBulkUpload" styleClass="p-sidebar-lg"
                [showCloseIcon]="true" position="right" [style]="{ width: '50vw' }" (onHide)="sidebarClose()">
                <div [style]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '25px', marginLeft: '50px'}">
                    <div class="bulk-header" [style]="{marginRight: '10px'}">Invite Candidate</div>
                    <div class="send-invite_btn">
                        <button pButton pRipple type="submit" (click)="sendInvite()" label="Send Invite"
                          class="button-align send-invite" [loading]="sendingInvite" [disabled]="bulkFile"></button>
                    </div>
                </div>
                <p-divider class="p-divider"></p-divider>
                <div class="single-container">
                    <span class="radioButtons">
                        <p-radioButton value="single-invite" [(ngModel)]="_selectedCategory" (onClick)="bulkSelected()">
                        </p-radioButton><label class="radioBtn" for="single-invite">Single-Invite</label>
                        <p-radioButton value="bulk-invite" [(ngModel)]="_selectedCategory" (onClick)="bulkSelected()">
                        </p-radioButton><label class="radioBtn" for="bulk-invite">Bulk-Invites</label>
                    </span>
                    <form *ngIf="_selectedCategory == 'single-invite'" class="departmentForm" [formGroup]="singleInvite">
                        <div>
                          <span class="p-float-label templateForm-elements input-field" style="margin-top: 20px;">
                            <input type="text" id="first-name" pInputText formControlName="firstName"
                              [ngClass]="{'p-filled': singleInvite.value.firstName, 'invalid': clicked && singleInvite.controls['firstName'].errors}" [style]="{width: '500px'}">
                            <label for="first-name">First Name <span [style]="{color: '#E24A47'}">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="singleInvite.get('firstName')" name="First name"></app-handle-error>
                          </span>
                          <span class="p-float-label templateForm-elements input-field" style="margin-top: 10px;">
                            <input type="text" id="last-name" pInputText formControlName="lastName"
                              [ngClass]="{'p-filled': singleInvite.value.lastName, 'invalid': clicked && singleInvite.controls['lastName'].errors}" [style]="{width: '500px'}">
                            <label for="last-name">Last Name <span [style]="{color: '#E24A47'}">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="singleInvite.get('lastName')" name="Last name"></app-handle-error>
                          </span>
                          <span class="p-float-label templateForm-elements input-field" style="margin-top: 10px;">
                            <input type="text" id="emailID" pInputText formControlName="emailID" autocomplete="off"
                              [ngClass]="{'invalid': clicked && singleInvite.controls['emailID'].errors}" [style]="{width: '500px'}">
                            <label for="emailID">Email ID <span [style]="{color: '#E24A47'}">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="singleInvite.get('emailID')" name="Email ID"></app-handle-error>
                            <div class="title-error" *ngIf="clicked && singleInvite.controls['emailID'].errors?.['pattern']">
                                <p>Invalid Email ID</p>
                            </div>
                          </span>
                          <span class="p-float-label templateForm-elements input-field" style="margin-top: 10px;">
                            <input type="text" id="first-name" pInputText formControlName="source"
                              [ngClass]="{'p-filled': singleInvite.value.source, 'invalid': clicked && singleInvite.controls['source'].errors}" [style]="{width: '500px'}">
                            <label for="source">Source Tag <span [style]="{color: '#E24A47'}">*</span></label>
                            <app-handle-error [clicked]="clicked" [control]="singleInvite.get('source')" name="Source Tag"></app-handle-error>
                          </span>
                          <!-- <span class="category-radioButtons templateForm-elements input-field">
                            <div [style]="{marginRight: '40px'}">
                                <label [style]="{fontSize: '14px', fontWeight: 'var(--fw-semi-bold)',color: 'var(--fc-dark)'}">Event Category <span [style]="{color: '#E24A47'}">*</span></label>
                            </div>
                            value="registration-form?form=Registration%20Form"
                            <p-radioButton [disabled]="true" value="technical" formControlName="eventCategory">
                            </p-radioButton><label [style]="{fontSize: '14px', marginRight: '20px'}" 
                            class="radioBtn" for="technical">Technical</label>
                            value="registration-form?form=Registration%20Form%20Non-Technical"
                            <p-radioButton [disabled]="true" value="nonTechnical" formControlName="eventCategory">
                            </p-radioButton><label [style]="{fontSize: '14px'}"
                            class="radioBtn" for="nonTechnical">Non-Technical</label>
                          </span> -->
                          <!-- <span class="field-checkbox templateForm-elements input-field" style="margin-top: 30px;">
                            <p-checkbox formControlName="nonTechnical" [binary]="true" inputId="binary"></p-checkbox>
                            <label for="binary">Non-Technical</label>
                          </span> -->
                        </div>
                      </form>
                </div>

                <form *ngIf="_selectedCategory == 'bulk-invite'" [formGroup]="bulkInvite" class="bulk-invite-form">
                    <div [style]="{ marginLeft: '60px'}">
                        <p class="template-download-label">Download Template to Invite Candidates</p>
                        <div>
                          <div class="template-download">
                            <div class="template-file_info">
                              <p class="pi pi-file-excel template-file-icon"></p>
                              <p class="file-name">Sample_Bulk_Invite.xlsx</p>
                            </div>
                            <p (click)="sampleData()" class="template-download-icon"><em class="pi pi-arrow-down"></em></p>
                          </div>
                        </div>
                        <p class="template-upload-label">Upload File to Invite Bulk Candidates</p>
                        <div class="profilePhoto form-details">
                          <div class="template-upload fileUpload-dropzone" ngx-dropzone
                            [accept]="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                            [multiple]="false" (change)="onSelectExcel($event)">
                            <!-- *ngIf="!uploadingFile" -->
                            <ngx-dropzone-label>
                              <div class="dropzone-label">
                                <em class="pi pi-cloud-upload"></em>
                                <p class="upload-text">
                                  Click to
                                  <span [style]="{ color: 'var(--fc-primary)' }">upload</span> or Drag
                                  & Drop
                                </p>
                                <p class="format-text">.XLS, .XLSX, .CSV Formats only</p>
                              </div>
                            </ngx-dropzone-label>
                            <!-- *ngIf="uploadingFile" -->
                            <!-- <div style="display: flex; justify-content:center; align-items:center">
                              <p-progressSpinner id="progress" [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                                animationDuration="0.5s">
                              </p-progressSpinner>
                            </div> -->
                            <!-- *ngIf="!uploadingFile" -->
                            <div>
                              <ngx-dropzone-preview class="file-preview" ngProjectAs="ngx-dropzone-preview"
                                *ngFor="let f of excel" [file]="f" [removable]="true" (removed)="onRemoveExcel(f)">
                                <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                                </ngx-dropzone-label>
                              </ngx-dropzone-preview>
                            </div>
                          </div>
                        </div>
                        <span class="p-float-label source-field">
                          <input formControlName="source" [ngClass]="{ 'p-filled': bulkInvite.value.source, 'invalid': clicked && bulkInvite.controls['source'].errors}"
                           style="width: 26vw; max-width: 100% !important;"
                            type="text" id="inputtext" pInputText />
                          <label for="inputtext">Source Tag <span [style]="{color: '#E24A47'}">*</span></label>
                          <app-handle-error [clicked]="clicked" [control]="bulkInvite.get('source')" name="Source Tag"></app-handle-error>
                        </span>
                        <!-- <div class="radioButton" [style]="{marginTop: '10px', marginLeft: '-9px', width: '100%'}">
                            <span class="radioButtons templateForm-elements input-field">
                              <label [style]="{fontSize: '14px', fontWeight: 'var(--fw-semi-bold)'}">Event Category <span [style]="{color: '#E24A47'}">*</span></label>
                              <p-radioButton [disabled]="true" value="technical" formControlName="eventCategory">
                              </p-radioButton><label class="radioBtn" for="technical">Technical</label>
                              <p-radioButton [disabled]="true" value="nonTechnical" formControlName="eventCategory">
                              </p-radioButton><label class="radioBtn" for="non-technical">Non-Technical</label>
                            </span>
                        </div> -->
                      </div>
                </form>
                
            </p-sidebar>
        </div>
    </div>
</div>

<div class="table" *ngIf="displayCandidates">

    <!-- Column Settings Sidebar -->
    <p-sidebar class="settingsSidebar" [transitionOptions]="'0ms'" [(visible)]="showSettingsSidebar"
        [style]="{ padding: '0'}" [modal]="false">
        <p class="sidebar-title">Column Settings</p>
        <div class="{{(savedColumnSettings && savedColumnSettings.length) ? 'savedColumns-continer' : 'hidden'}}">
            <p>Saved Column</p>
            <div class="savedColumns" *ngFor="let column of savedColumnSettings"
                (click)="applySavedSettings(column.columnValue)">
                <p>{{column.columnName}}</p>
                <em class="pi pi-trash" (click)="deleteSavedColumns(column.columnId)"></em>
            </div>
        </div>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search" [(ngModel)]="columnSearchTerm"
                (ngModelChange)="searchColumns($event)" />
        </span>
        <div *ngFor="let col of _usersCol" class="field-checkbox">
            <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
            <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
        </div>

        <div class="sidebar-bottom">
            <div class="sidebar-bottom-apply" [ngClass]="{'hidden' : displaySaveColumnSettings}">
                <p class="sidebar-bottom-text" (click)="showSaveColumnSettings()">Save Selected Columns</p>
                <div class="sidebar-bottom-buttons">
                    <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
                        (click)="clearSelectedColumns()"></button>
                    <button pButton pRipple type="button" label="Apply" (click)="applySelectedColumns()"></button>
                </div>
            </div>
            <div class="sidebar-saveSettings" [ngClass]="{'hidden' : !displaySaveColumnSettings}">
                <em class="pi pi-times" (click)="displaySaveColumnSettings = false"></em>
                <form class="p-inputgroup" [formGroup]="columnSettings" (ngSubmit)="saveColumnSettings()">
                    <input type="text" pInputText placeholder="Enter Filter Name" formControlName="columnName">
                    <button type="submit" pButton pRipple label="Save"></button>
                </form>
            </div>
        </div>
    </p-sidebar>

    <!-- Filter Sidebar -->
    <p-sidebar class="filterSidebar" [transitionOptions]="'0ms'" [(visible)]="showFilterSidebar"
        [style]="{ padding: '0'}" [modal]="false" [baseZIndex]="0" ariaCloseLabel="Filter">
        <p class="sidebar-title">Filter</p>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search">
        </span>
    </p-sidebar>

    <!-- Candidate Details Sidebar -->
    <div *ngIf="displayUserDetails">
        <p-sidebar class="candidateDetaile" [transitionOptions]="'0ms'" [(visible)]="displayUserDetails"
            styleClass="p-sidebar-lg" position="right" [style]="{width: '921px'}">
            <app-candidate-info [disableActivity]="false" [selectedUser]="selectedUser" [numberOfStages]="numberOfStages" [statusSettings]="statusSettings"></app-candidate-info>
        </p-sidebar>
    </div>

    <!-- Download Sidebar -->
    <p-sidebar [transitionOptions]="'0ms'" class="downloadSidebar candidateListDownload" [modal]="false"
        [style]="{ padding: '0', border: '2px solid white'  }" [(visible)]="showDownloadSidebar">
        <p class="sidebar-title">Download Data</p>

        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input pInputText (ngModelChange)="searchColumns($event)" [(ngModel)]="downloadSearchTerm" placeholder="Search"
                type="text" />
        </span>

        <div *ngFor="let col of colsToDownload" class="field-checkbox">
            <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
            <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
        </div>

        <div class="download-container">
            <p>Download as</p>
            <div class="radioButton-group">
                <div class="radioButton">
                    <p-radioButton [(ngModel)]="downloadAs" value="excel" name="exportButtons" inputId="excel">
                    </p-radioButton>
                    <label [style]="{color: downloadAs === 'excel' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                        for="excel"><em class="pi pi-file"></em>Excel</label>
                </div>
                <div class="radioButton">
                    <p-radioButton [(ngModel)]="downloadAs" name="exportButtons" inputId="csv" value="csv">
                    </p-radioButton>
                    <label [style]="{color: downloadAs === 'csv' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                        for="csv"><em class="pi pi-file-excel"></em>CSV</label>
                </div>
            </div>

            <button class="p-button-raised" pRipple pButton type="button"
                (click)="(downloadAs === 'excel') ? exportCandidate('excel') : exportCandidate('csv')" label="Download"></button>
        </div>

    </p-sidebar>

    <!-- Table -->
    <p-table #dt [columns]="_selectedColumns" [value]="candidateList" [loading]="loadingSpinner" [scrollable]="true"
        scrollHeight="76vh" scrollDirection="both" responsiveLayout="scroll" [resizableColumns]="true" [rows]="pageSize"
        dataKey="id" [exportFilename]="exportName" [globalFilterFields]="['userName', 'status', 'stage', 'email']">

        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="table-header-left">
                    <button pButton title="Settings"
                        (click)="showSettingsSidebar = true; showFilterSidebar = false; showDownloadSidebar = false"
                        class="p-button-text simple-btn{{showSettingsSidebar ? '-active' : '' }}"
                        icon="pi pi-sliders-h">
                    </button>
                    <!-- <button pButton title="Filter"
                        (click)="showFilterSidebar = true; showSettingsSidebar = false; showDownloadSidebar = false"
                        class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}" icon="pi pi-filter">
                    </button> -->
                    <button pButton title="Download"
                        (click)="showDownloadSidebar = true; showSettingsSidebar = false; showFilterSidebar = false"
                        class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}" icon="pi pi-download">
                    </button>
                    <button pButton pRipple type="button" icon="pi pi-refresh" (click)="getUsers()" title="Refresh"
                        class="p-button-text simple-btn{{loadingSpinner ? '-active' : '' }}"></button>
                    <p-divider layout="vertical"></p-divider>
                    <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength }}</span></label>
                </span>
                <span class="table-menu">
                    <p [style]="{paddingRight: '10px'}">View</p>
                    <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize"
                        [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }" (onChange)="getUsersByLimit()">
                    </p-dropdown>
                    <p-divider layout="vertical"></p-divider>
                    <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                        {{ first }} to {{ last }}
                    </button>
                    <button pButton class="p-button-secondary p-button-text simple-btn" (click)="prev()"
                        [disabled]="isFirstPage()" icon="pi pi-angle-left"></button>
                    <button pButton class="p-button-secondary p-button-text simple-btn" (click)="next()"
                        [disabled]="isLastPage()" icon="pi pi-angle-right"></button>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr class="table-heading"
                [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}">
                <th pFrozenColumn scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th [style]="{width: '15vw'}" scope="col" pSortableColumn="id" pResizableColumn pFrozenColumn>
                    Candidate ID
                    <p-sortIcon class="sort-icon" field="id"></p-sortIcon>
                </th>
                <th [style]="{width: '15vw'}" scope="col" pSortableColumn="name" pResizableColumn pFrozenColumn>
                    Name
                    <p-sortIcon class="sort-icon" field="name"></p-sortIcon>
                </th>
                <th *ngFor="let user of columns" pResizableColumn [style]="{width: '13vw'}" scope="col"
                    pSortableColumn="{{ user.field }}">
                    {{ user.header }}
                    <p-sortIcon class="sort-icon" field="{{ user.field }}"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-user let-columns="columns">
            <tr class="row-candidate-list"
                [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}"
                [style]="{cursor: 'pointer'}">
                <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td>
                <td [style]="{width: '15vw'}" pFrozenColumn (click)="showUserDetails(user)">{{ user.id }}</td>
                <td [style]="{width: '15vw'}" pFrozenColumn (click)="showUserDetails(user)">{{ user.name }}</td>
                <td *ngFor="let col of columns" [style]="{width: '13vw'}" (click)="showUserDetails(user)">

                    <span [ngSwitch]="true">
                        <div *ngIf="user.stageId == 1 || user.stageType;">
                            <span class="status-tag {{ user[col.field] }}-tag" *ngSwitchCase="col.field === 'status'">
                                {{ statusSettings[ user?.stageId == 1 ? 'Eligibility' : user?.stageType][user[col.field]] }}
                            </span>
                        </div>
                        <div *ngIf="!(user.stageId == 1 || user.stageType)">
                            <span class="status-tag {{ user[col.field] }}-tag" *ngSwitchCase="col.field === 'status'">
                                {{user.status}}
                            </span>
                        </div>

                        <span [style]="{fontSize: '12px'}" *ngSwitchCase="col.field === 'stageId'">

                            <div [ngSwitch]="user['status']">
                                <span *ngFor="let status of statusColor">
                                    <span *ngSwitchCase="status.status">
                                        <round-progress class="round-progress" [radius]="32" [stroke]="6"
                                            [background]="'#DFE3E7'" [current]="user._stageId" [max]="numberOfStages"
                                            [rounded]="true" [semicircle]="true" [color]="status.color">
                                        </round-progress>
                                    </span>
                                </span>
                            </div>

                            <div [style]="{color: 'black', margin: '-15px 0 0 22px'}">
                                {{user._stageId}}/{{numberOfStages}}
                            </div>
                        </span>

                        <span *ngSwitchCase="col.field === 'source'">
                            <em *ngIf="user[col.field]" class="pi pi-{{user[col.field].toLowerCase()}}"
                                [style]="{padding: '5px 5px 0'}"></em>{{user[col.field]}}
                        </span>

                        <span *ngSwitchCase="col.field === 'createdAt' || col.field == 'dob'">
                            {{ user[col.field] | date: dateFormat || '-' }}
                        </span>

                        <span *ngSwitchDefault>
                            {{user[col.field] ? user[col.field] : '-'}}
                        </span>

                    </span>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="{{displayJobDetails ? 'jobDetails-page' : 'hidden'}}">
    <app-event-details *ngIf="isInEvent"></app-event-details>
    <app-job-details *ngIf="!isInEvent"></app-job-details>
</div>

<div class="{{displayWorkflow ? 'workflow-page' : 'hidden'}}">
    <app-workflow (stagesEmitter)="stageDetails = $event; numberOfStages = $event.length"></app-workflow>
</div>
<div class="{{displayInterview ? 'interview-page' : 'hidden'}}">
    <app-interview-panel></app-interview-panel>
</div>