<div class="field wiley-form" [ngSwitch]="model.fieldType">
    <p class="fieldName">
        {{ model.fieldName }} <span *ngIf="model.isMandatory" class="imp">*</span>
    </p>

    <span *ngSwitchCase="'none'" [class]="model.fieldType">
        <p [innerHTML]="model.fieldDescription"></p>
    </span>

    <span *ngSwitchCase="'checkbox'" [ngClass]="{'grid-checkbox': model.fieldOptions?.length > 6 }"
        [class]="model.fieldType">
        <div *ngFor="let option of model.fieldOptions; index as i" [class]="'field-' + model.fieldType">
            <p-checkbox [id]="model.fieldId" [name]="option.label" [value]="option.value" [inputId]="option.value"
                [(ngModel)]="tempAnswer" (ngModelChange)="emitValue($event, checkboxField)"
                [required]="model.isMandatory" #checkboxField="ngModel">
            </p-checkbox>
            <label [for]="option.value">{{ option.label }}</label>
        </div>
        <span *ngIf="clicked && !checkboxField?.valid && model.isMandatory" class="errorMessage">This field is
            required!</span>
    </span>

    <span *ngSwitchCase="'dropdown'" [class]="model.fieldType">
        <p-dropdown [id]="model.fieldId" [options]="model.fieldOptions" [placeholder]="model.placeHolder"
            [autoDisplayFirst]="false" [class]="'field-' + model.fieldType" optionLabel="label" [(ngModel)]="tempAnswer"
            (ngModelChange)="emitValue($event, dropdownField)" [required]="model.isMandatory" #dropdownField="ngModel"
            [filter]="model.fieldOptions.length > 10 ? true : false" filterBy="label">
        </p-dropdown>
        <span *ngIf="clicked && !dropdownField?.valid && model.isMandatory" class="errorMessage">This field is
            required!</span>
        <span *ngIf="inValidLocation && model.fieldName == 'Preferred Location 2'" class="errorMessage">Choose Different
            Locations.</span>
    </span>

    <span *ngSwitchCase="'radio'" [class]="model.fieldType">
        <div *ngFor="let option of model.fieldOptions; index as i" [class]="'field-' + model.fieldType">
            <p-radioButton [id]="model.fieldId" [inputId]="option.value" [name]="option.label" [value]="option.value"
                [(ngModel)]="tempAnswer" (ngModelChange)="emitValue($event, radioField)" [required]="model.isMandatory"
                #radioField="ngModel">
            </p-radioButton>
            <label [for]="option.value">{{ option.label }}</label>
        </div>
        <span *ngIf="clicked && !radioField?.valid && model.isMandatory" class="errorMessage">This field is
            required!</span>
    </span>

    <span *ngSwitchCase="'booleanRadio'" [class]="model.fieldType">
        <div *ngFor="let option of model.fieldOptions; index as i" [class]="'field-' + model.fieldType">
            <p-radioButton [id]="model.fieldId" [inputId]="option.value" [name]="option.label" [value]="option.value"
                [(ngModel)]="tempAnswer" (ngModelChange)="emitValue($event, booleanRadioField)" [required]="model.isMandatory"
                #booleanRadioField="ngModel">
            </p-radioButton>
            <label [for]="option.value">{{ option.label }}</label>
        </div>
        <span *ngIf="clicked && !booleanRadioField?.valid && model.isMandatory" class="errorMessage">This field is
            required!</span>
    </span>

    <span *ngSwitchCase="'mobile'" [class]="model.fieldType">
        <div class="p-inputgroup">

            <button type="button" pButton pRipple [label]="selectedCode.isoCode3" [icon]="model.readOnly ? '' : 'pi pi-angle-down'"
                (click)="model.readOnly ? null : op.toggle($event)" [style]="{height: '40px'}">
                <p-overlayPanel #op>
                    <ng-template pTemplate>
                        <span class="p-input-icon-left search-code">
                            <em class="pi pi-search"></em>
                            <input (ngModelChange)="searchCode($event)"
                                style="width: 100%; padding-left: 30px !important;" pInputText placeholder="Search"
                                type="text" [(ngModel)]="codeSearchTerm" />
                        </span>
                        <div *ngFor="let code of _countryCodeList" class="countryCode-overlay">
                            <p (click)="selectedCode = code; emitValue(tempAnswer, model); op.hide()"
                                class="countryCode">
                                {{code.country}} <span [style]="{fontWeight: '600'}">{{' (+'
                                    +code.countryCodes}})</span> </p>
                        </div>
                    </ng-template>
                </p-overlayPanel>
            </button>

            <p-inputNumber [readonly]="model.readOnly ?? false" (click)="duplicateData.mobileDuplicate = false"  [(ngModel)]="tempAnswer"
                [maxlength]="selectedCode.phLength" [useGrouping]="false" [style]="{width: '80%'}"
                (ngModelChange)="emitValue($event, inputField, selectedCode.phLength)" [required]="model.isMandatory"
                #inputField="ngModel">
            </p-inputNumber>
        </div>
        <span *ngIf="clicked && !inputField?.valid && model.isMandatory && !invalidLength" class="errorMessage">This
            field is required!</span>
        <span *ngIf="inputField.touched && invalidLength" class="errorMessage">Please enter valid mobile number.</span>
        <span *ngIf="model.fieldName == 'Alternate Mobile Number' && inputField.valid && duplicateData.mobileDuplicate" class="errorMessage">Phone number should not be same as the primary phone number</span>
    </span>

    <span *ngSwitchCase="'input'" [class]="model.fieldType">
        <input [type]="model.fieldName == 'Aadhar Card Number' || model.fieldName == 'Pin Code' ? 'number' : 'text'" (click)="duplicateData.userNotFound = false" 
            [ngClass]="model.readOnly ? 'disable-input_field' : ''" [readOnly]="model.readOnly ?? false"
            [placeholder]="model.placeHolder" [(ngModel)]="tempAnswer" [id]="model.fieldId" pInputText
            (keypress)="(model.fieldName == 'Aadhar Card Number' || model.fieldName == 'Pin Code') ? restrictNumber($event, model.fieldName == 'Aadhar Card Number' ? 12 : model.fieldName == 'Pin Code' ? 6 : 0) : null"
            
            (ngModelChange)="emitValue($event, inputField)" [required]="model.isMandatory" #inputField="ngModel" />
        <span *ngIf="clicked && !inputField.valid && model.isMandatory && !invalidLength" class="errorMessage">This
            field is required!.</span>
        <span *ngIf="model.fieldName == 'Aadhar Card Number' && inputField.touched && invalidLength"
            class="errorMessage">Aadhaar Number must be 12 digits.</span>
        <span *ngIf="model.fieldName == 'Pin Code' && inputField.touched && invalidLength" class="errorMessage">Pin Code
            must be 6 digits.</span>
        <span *ngIf="model.fieldName == 'Candidate ID' && inputField?.valid && duplicateData.userNotFound" class="errorMessage">Please enter valid candidate id</span>
    </span>

    <span *ngSwitchCase="'email'" [class]="model.fieldType">
        <input (click)="duplicateData.emailDuplicate = false" [id]="model.fieldId" type="text" pInputText [placeholder]="model.placeHolder" [(ngModel)]="tempAnswer"
            (ngModelChange)="emitValue($event, emailField)" [required]="model.isMandatory" #emailField="ngModel"
            pattern="\b[a-z0-9]+([\.-]?[a-z0-9]+)*@[a-z0-9]+([\.-]?[a-z0-9]+)*(\.[a-z]{2,3})+\b" />
        <span *ngIf="clicked && !emailField?.valid && model.isMandatory" class="errorMessage">Please enter valid email!</span>
        <span *ngIf="model.fieldName == 'Alternate Email Address' && emailField?.valid && duplicateData.emailDuplicate" class="errorMessage">Email should not be same as the primary email</span>
    </span>

    <span *ngSwitchCase="'multiinput'" class="multi-fields">
        <div *ngFor="let option of model.fieldOptions" [class]="model.fieldType">
            <input [id]="model.fieldId" type="text" pInputText [placeholder]="option.label" [(ngModel)]="option.value"
                (ngModelChange)="emitValue($event, multiInputField)" [required]="model.isMandatory"
                #multiInputField="ngModel" />
            <span *ngIf="clicked && !multiInputField?.valid && model.isMandatory" class="errorMessage">This field is
                required!</span>
        </div>
    </span>

    <span *ngSwitchCase="'datePicker'" [class]="model.fieldType">
        <p-calendar [id]="model.fieldId" dateFormat="mm-dd-yy" dataType="string" inputId="dateformat" [minDate]="currentDate"
            [(ngModel)]="tempAnswer" (ngModelChange)="emitValue($event, datePickerField)" [required]="model.isMandatory"
            #datePickerField="ngModel">
        </p-calendar>
        <span *ngIf="clicked && !datePickerField?.valid && model.isMandatory" class="errorMessage">This field is
            required!</span>
        <span *ngIf="belowAge" class="errorMessage">Candidate must be 18 years old or
            above!</span>
    </span>

    <span *ngSwitchCase="'multiInputEmail'" class="multi-fields">
        <div [class]="model.fieldType">
            <input [readOnly]="model.readOnly ?? false" [id]="model.fieldId" type="text" pInputText
                [placeholder]="model.fieldOptions[0].label" #multiEmailField_1="ngModel"
                [(ngModel)]="model.fieldOptions[0].value" [required]="model.isMandatory"
                (ngModelChange)="emitValue($event, multiEmailField_2)" [autocomplete]="false"
                pattern="\b[a-z0-9]+([\.-]?[a-z0-9]+)*@[a-z0-9]+([\.-]?[a-z0-9]+)*(\.[a-z]{2,3})+\b" />
            <span *ngIf="clicked && !multiEmailField_1?.value && model.isMandatory" class="errorMessage">This field is
                required!</span>
            <span *ngIf="multiEmailField_1.touched && multiEmailField_1?.value && !multiEmailField_1?.valid"
                class="errorMessage">Invalid format</span>
        </div>
        <div [class]="model.fieldType">
            <input type="text" pInputText [placeholder]="model.fieldOptions[1].label" #multiEmailField_2="ngModel"
                [(ngModel)]="model.fieldOptions[1].value" (ngModelChange)="emitValue($event, multiEmailField_2)"
                [autocomplete]="false" [required]="model.isMandatory" (paste)="false"
                pattern="\b[a-z0-9]+([\.-]?[a-z0-9]+)*@[a-z0-9]+([\.-]?[a-z0-9]+)*(\.[a-z]{2,3})+\b"
                [ngClass]="{invalid : multiEmailField_2.touched && model.fieldOptions[1].value !== model.fieldOptions[0].value}" />
            <span *ngIf="clicked && !multiEmailField_2?.value" class="errorMessage">This field is required!</span>
            <span *ngIf="multiEmailField_2.touched && !multiEmailField_2?.valid && multiEmailField_2?.value"
                class="errorMessage">Invalid Format!</span>
            <span *ngIf="multiEmailField_2.touched && model.fieldOptions[1].value !== model.fieldOptions[0].value"
                class="errorMessage">Field values do not match!</span>
        </div>
    </span>

    <span *ngSwitchCase="'percentage'" [class]="model.fieldType">
        <span class="p-input-icon-right">
            <p-inputNumber [(ngModel)]="tempAnswer" [id]="model.fieldId" mode="decimal" [min]="0" [max]="100"
                (ngModelChange)="emitValue($event, percentageField)" [required]="model.isMandatory"
                #percentageField="ngModel">
            </p-inputNumber>
            <i class="pi pi-percentage"></i>
            <span *ngIf="clicked && !percentageField?.valid && model.isMandatory" class="errorMessage">This field is
                required!</span>
        </span>
    </span>

    <span *ngSwitchCase="'textArea'" [class]="model.fieldType">
        <textarea pInputTextarea [(ngModel)]="tempAnswer" [id]="model.fieldId" [autoResize]="true" [rows]="5"
            (ngModelChange)="emitValue($event, textAreaField)" [required]="model.isMandatory"
            #textAreaField="ngModel"></textarea>
        <span *ngIf="clicked && !textAreaField?.valid && model.isMandatory" class="errorMessage">This field is
            required!</span>
    </span>


    <span *ngSwitchCase="'file'" [class]="model.fieldType">
        <div [id]="model.fieldId" class="fileUpload-dropzone" ngx-dropzone [accept]="fileType" [multiple]="false"
            (change)="model.fieldName === 'Please Upload Your Passport Size Photograph' ? onSelectfile($event, true) : onSelectfile($event)"
            [maxFileSize]="convertByte(model.fileProperities.fileSize.sizeFormat, model.fileProperities.fileSize.size)"
            [ngClass]="{ hidden: uploadingFile }">
            <ngx-dropzone-label *ngIf="!uploadingFile">
                <div class="dropzone-label">
                    <em class="pi pi-cloud-upload"></em>
                    <p class="upload-text">
                        Click to
                        <span [style]="{ color: 'var(--fc-primary)' }">upload</span> or Drag
                        & Drop
                    </p>
                    <p class="format-text">
                        {{ model.fileProperities.fileFormat.join() }} Format only
                    </p>
                    <p class="format-text">
                        (Less than
                        {{
                        model.fileProperities.fileSize.size +
                        model.fileProperities.fileSize.sizeFormat
                        }})
                    </p>
                </div>
            </ngx-dropzone-label>
            <div *ngIf="uploadingFile">
                <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s">
                </p-progressSpinner>
            </div>
            <div *ngIf="fileBase64?.length ?? false" [style]="{width: '50%'}">
                <ngx-dropzone-image-preview
                    [ngClass]="{'file-preview' : fileType.includes('.pdf') || fileType.includes('.docx') || fileType.includes('.doc') || inEditMode }"
                    ngProjectAs="ngx-dropzone-preview" *ngFor="let f of file" [file]="f" [removable]="true"
                    (removed)="onRemovefile()">
                    <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                    </ngx-dropzone-label>
                </ngx-dropzone-image-preview>
            </div>
        </div>
        <span *ngIf="clicked && !file.length && model.isMandatory" class="errorMessage">This field is required!</span>
    </span>

    <small class="fieldDescription" [innerHTML]="model.fieldDescription" 
        [ngClass]="{'form-declaration': model.fieldName == 'Declaration & Consent' 
            || (model.displaySection == 'Diversity Questionnaire (Optional)' && model.fieldName == '' ) 
            || (model.displaySection == 'PWD Details' && model.fieldName == '' )}"
        *ngIf="model.fieldDescription?.length && model.fieldType != 'none'"></small>
</div>