import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { JobServicesService } from 'src/app/services/job-services.service';
import { EmailServicesService } from 'src/app/services/email-services.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';

declare let tinymce: any;
export interface TemplateList {
  category: string,
  categoryId: string,
  id: string,
  isEditable: boolean,
  templateTitle: string,
  subjectTitle: string,
  templateContent: any,
  collapse: boolean
  showIcons: boolean
}

export enum Placeholders {
  User = ' ${User} ',
  EmailId = ' ${EmailId} ',
  UserType = ' ${UserType} ',
  Address = ' ${Address} ',
  SchoolName = ' ${SchoolName} ',
  ContactNumber = ' ${ContactNumber} ',
  Email_Signature = ' ${Email_Signature} ',
  Emails = ' ${Emails} ',
}
@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css'],
  providers:[MessageService, ConfirmationService ]
})
export class GeneralSettingsComponent implements OnInit {



  categories: Array<any> = [];
  templateList: Array<TemplateList> = [];
  addTemplateSidebar: boolean = false;
  newCategoryName: string = '';
  isLoading: boolean = true;
  selectedCategoryId: string = '';
  showPlaceholder: boolean = false;
  placeholders: Array<any> = [];
  focusedElement: any;
  cursorPositionId: string = '';
  cursor: Array<number> = [];

  _subjectTitle: string = '';
  _templateContent: string = '';
  _selectedCategory: Object = {};
  _templateTitle: string = '';
  templateToEdit: string = '';
  saveBtnLoading: boolean = false;
  isAllActive: boolean = true;
  userInfo: any = [];

  addTemplateForm: FormGroup = new FormGroup({
    category: new FormControl(),
    templateTitle: new FormControl(),
    subjectTitle: new FormControl(),
    templateContent: new FormControl()
  })

  activeComponent : string = 'Primary Account'

  constructor(private jobServices: JobServicesService, private emailServices: EmailServicesService, private messageService: MessageService, private confirmationService: ConfirmationService, private globalService: GlobalServicesService) {
    this.placeholders = Object.keys(Placeholders).map((key: string) => ({ value: Placeholders[key as keyof typeof Placeholders], tag: key }));
  }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories = () => {
    this.isLoading = false;
    this.categories = [{
      name : 'Primary Account',
      isActive : true
    },
    {
      name : 'Company Details',
      isActive : false
    },
    {
      name : 'Branches',
      isActive : false
    },
    {
      name : 'Joining Cohort Fields',
      isActive : false
    },
    {
      name : 'Date and Time',
      isActive : false
    },
    {
      name : 'Status',
      isActive : false
    },
    // {
    //   name : 'Departments',
    //   isActive : false
    // }
  ] 

  this.userInfo = [
    { field: "Email/Username", value: 'a.j.manoow@gmail.com' ?? '-' },
    { field: "Password", value: '**********' ?? '-' },
    { field: "Phone/Mobile", value: '+91 9988998899' ?? '-' },
    { field: "Primary Location", value: 'Erode' ?? '-' },
    { field: "Primary Branch", value: 'Chennai' ?? '-' }
  ]
    // this.getEmailTemplates();
  }

  getEmailTemplates = () => {
    this.isLoading = true;
    this.emailServices.getTemplates(this.selectedCategoryId).subscribe(response => {
      let templateList = response.data ? response.data.items : [];
      this.templateList = templateList.map((item: any) => ({
        category: item.category,
        categoryId: item.categoryId,
        id: item.id,
        isEditable: item.isEditable,
        templateTitle: item.templateTitle,
        subjectTitle: item.subjectTitle,
        templateContent: item.templateContent,
        collapse: true,
        showIcons: false
      }
      ))
      this.isLoading = false;
    })
  }

  openCategory = (name: string) => {
    this.activeComponent = name;
    this.categories.forEach((each)=>{
      this.activeComponent === each.name ? each.isActive = true : each.isActive = false;
    })
  }

  addCategory = (ipElement: any) => {
    if (this.newCategoryName) {
      this.isLoading = true;
      let payload = { fieldName: this.newCategoryName }
      this.emailServices.AddTemplteCategory(payload).subscribe(response => {
        let categories = response.data ? response.data.category : [];
        this.categories = categories.map((category: any) => ({ name: category.fieldName, id: category.fieldId, isActive: false }))
        this.categories[this.categories.length - 1].isActive = true;
        this.isLoading = false;
      })
    }
    ipElement.deactivate();
  }

  saveTemplate = () => {
    this.saveBtnLoading = true;
    let newTemplate = this.addTemplateForm.value;
    let editorText = tinymce.get(0).getContent()
    ? tinymce.get(0).getContent()
    : null;
    this.addTemplateForm.controls['templateContent'].setValue(editorText);

    let payload = {
      category: newTemplate.category?.name,
      categoryId: newTemplate.category?.id,
      templateTitle: newTemplate.templateTitle,
      subjectTitle: newTemplate.subjectTitle,
      templateContent: tinymce.get(0).getContent()
      ? tinymce.get(0).getContent()
      : null,
      isEditable: true
    }

    if (this.templateToEdit) {
      this.emailServices.editTemplate(payload, this.templateToEdit).subscribe(response => {
        if (response.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Template Edited Successfully' });
          this.addTemplateForm.reset();
          this.getEmailTemplates();
          this.templateToEdit = '';
          this.addTemplateForm.reset();
          this.addTemplateSidebar = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to Edit the template' });
          this.templateToEdit = '';
        }
        this.saveBtnLoading = false;
      })
    } else {
      this.emailServices.addTemplate(payload).subscribe(response => {
        if (response.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Template Created Successfully' });
          this.addTemplateForm.reset();
          this.getEmailTemplates();
          this.addTemplateForm.reset();
          this.addTemplateSidebar = false;
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to create the template' });
        }
        this.saveBtnLoading = false;
      })
    }
  }

  editTemplate = (template: TemplateList) => {
    // this.addTemplateSidebar = true;
    this._selectedCategory = this.categories.filter((item: any) => item.name === template.category)[0];
    this._templateTitle = template.templateTitle;
    this._subjectTitle = template.subjectTitle;
    this._templateContent = template.templateContent;
    this.templateToEdit = template.id;
    this.openAddTemplate(template);
    tinymce.activeEditor.setContent(template.templateContent);
  }

  deleteTemplate(template: TemplateList, event: any) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isLoading = true;
        this.emailServices.deleteTemplate(template.id).subscribe(res => {
          res.status == 200 && this.getEmailTemplates();
          this.messageService.add({ severity: 'info', summary: 'Deleted', detail: 'Template deleted' });
        })
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

  getElement = (id: string) => {
    this.cursorPositionId = id;
    this.focusedElement = document.getElementById(id);

    if (this.cursorPositionId === 'title') {
      let [start, end] = [this.focusedElement.selectionStart, this.focusedElement.selectionEnd];
      this.cursor = [start, end];
    }

    if (this.cursorPositionId === 'content') {
      let range = this.focusedElement.firstChild.lastChild.__quill.selection.lastRange;
      let [start, end] = [range.index, range.index + range.length]
      this.cursor = [start, end];
    }
  }

  insertPlaceholder = (text: string) => {
    if (this.cursorPositionId === 'title') {
      let currentValue = this.focusedElement.value;
      let patchedValue = currentValue.substring(0, this.cursor[0]) + text + currentValue.substring(this.cursor[1]);
      this._subjectTitle = patchedValue;
    }
    if (this.cursorPositionId === 'content') {
      let currentInnerText = this.focusedElement.innerText;
      let currentValue = currentInnerText.replace('\n\n', '<br>');
      // let currentValue = this.parseHtmlToText(currentInnerText)
      let patchedValue = currentValue.substring(0, this.cursor[0]) + text + currentValue.substring(this.cursor[1]);
      this._templateContent = patchedValue;

    }
  }

  cancel = () => {
    this.addTemplateForm.reset();
  }

  async loadTinyMce(template?:any) {
    await this.globalService.loadScript(
      'script',
      'https://cdn.tiny.cloud/1/0eh36k1drqmz94mh92qtt7utdjc0pmvctldubb7sho99iiva/tinymce/5/tinymce.min.js',
      'text/javascript',
      'examlyck'
    );
    tinymce.remove();
    tinymce
      .init({
        selector: 'textarea',
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
        height: 500,
        menu: '',
        plugins:
          'print preview paste searchreplace autolink ' +
          'directionality code visualblocks visualchars fullscreen link media ' +
          'codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists ' +
          'wordcount textpattern noneditable help charmap quickbars emoticons',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
          'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
          'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ' +
          'insertfile quickimage media link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        images_upload_url: '',
        image_caption: true,
        quickbars_selection_toolbar:
          'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'wrap',
        contextmenu: 'link image imagetools table',
        relative_urls: false
      })
      .then((editor: any) => {
        tinymce.activeEditor.setContent(template.templateContent);
      });
  }

  openAddTemplate(template?: any)
  {
    this.addTemplateSidebar = true;
    this.globalService.removeScript();
    this.loadTinyMce(template);
  }

}
