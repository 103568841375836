<div class="manage-candidate">
  <div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
  </div>

  <div class="invite-candidate_container">
    <button *ngIf="privilege.editManageCandidate" pButton pRipple class="p-button-raised" type="button"
      (click)="addTalentpoolSlider = true" label="Invite Candidate" style="width: 190px !important;"><em
        class="invite-candidate_icon pi pi-user-plus"></em></button>
  </div>
</div>
<div class="invite-slider" *ngIf="addTalentpoolSlider">
  <p-sidebar class="addTalentpool-slider" [(visible)]="addTalentpoolSlider" styleClass="p-sidebar-lg"
    [showCloseIcon]="true" position="right" (onHide)="getAllCandidates()">
    <app-talentpool-add></app-talentpool-add>
  </p-sidebar> 
</div>

<p-sidebar *ngIf="sendEmailSlider" class="sendEmail-slider"  [(visible)]="sendEmailSlider" styleClass="p-sidebar-lg"
  [showCloseIcon]="true" position="right" [style]="{width: '68rem'}">
  <app-send-email [selectedUsersList]="selectedUsersList" (mailSent)="mailSent($event)"></app-send-email>
</p-sidebar>

<div class="table">

  <!-- Filter SideBar -->
  <p-sidebar class="filterSidebar" [transitionOptions]="'0ms'" [(visible)]="showFilterSidebar"
    [style]="{padding: '0', border: '2px solid white'}" [modal]="false" [baseZIndex]="0" ariaCloseLabel="Filter">
    <p class="sidebar-title">Filter</p>
    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input type="text" pInputText placeholder="Search" [(ngModel)]="filterSearchTerm">
    </span>

    <div class="filterSidebarContent">
      <p-accordion [multiple]="true" [collapseIcon]="'pi pi-minus'">
        <p-accordionTab header='Date'>
          <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [inline]="true"
            [showWeek]="true" [style]="{width: 'auto'}" inputId="range"></p-calendar>
        </p-accordionTab>
      </p-accordion>
    </div>

    <div class="filterSidebarContent">
      <div class="sidebar-bottom">
        <div class="sidebar-bottom-apply">
          <!-- <p class="sidebar-bottom-text" (click)="displaySaveFilterSettings = true;">Save Selected Filters</p> -->
          <div class="sidebar-bottom-buttons">
            <!-- <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
              (click)="clearSelectedFilters()"></button> -->
            <button pButton pRipple type="button" label="Apply" (click)="applySelectedFilter()"></button>
          </div>
        </div>

        <div [ngClass]="{'hidden' : !displaySaveFilterSettings}" class="sidebar-saveSettings">
          <em (click)="displaySaveFilterSettings = false" class="pi pi-times"></em>
          <div class="p-inputgroup">
            <input pInputText placeholder="Enter Filter Name" type="text">
            <button pButton pRipple label="Save" type="button"></button>
          </div>
        </div>
      </div>
      <p-accordion [multiple]="true" *ngFor="let col of _selectedColumns" [collapseIcon]="'pi pi-minus'">
        <p-accordionTab [ngSwitch]="col.field" header={{col.header}} *ngIf="col.options">
          <span *ngSwitchCase="'status'">
            <div *ngFor="let option of col.options" class="field-checkbox">
              <p-checkbox [(ngModel)]="selectedStatusElements" name="status" [inputId]="option" [value]="option">
              </p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>

          <span *ngSwitchCase="'department'">
            <div *ngFor="let option of col.options" class="field-checkbox">
              <p-checkbox [(ngModel)]="selectedDepartmentElements" [value]="option" name="department"
                [inputId]="option"></p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>

          <span *ngSwitchCase="'talentpoolType'">
            <div *ngFor="let option of col.options;" class="field-checkbox">
              <p-checkbox [(ngModel)]="selectedTalentpoolTypeElements" [value]="option" name="talentpoolType"
                [inputId]="option">
              </p-checkbox>
              <label [for]="option">{{ option }}</label>
            </div>
          </span>
        </p-accordionTab>
      </p-accordion>

    </div>

  </p-sidebar>

  <!-- Column Settings Sidebar -->
  <p-sidebar class="settingsSidebar" [transitionOptions]="'0ms'" [(visible)]="showSettingsSidebar"
    [style]="{ padding: '0', border: '2px solid white'}" [modal]="false">
    <p class="sidebar-title">Column Settings</p>

    <div class="{{savedColumnSettings.length ? 'savedColumns-continer' : 'hidden'}}">
      <p>Saved Column</p>
      <div *ngFor="let column of savedColumnSettings" class="savedColumns"
        (click)="applySavedSettings(column.columnValue)">
        <p>{{column.columnName}}</p>
        <em (click)="deleteSavedColumns(column.columnId)" class="pi pi-trash"></em>
      </div>
    </div>
    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input (ngModelChange)="searchColumns($event)" pInputText placeholder="Search" type="text"
        [(ngModel)]="columnSearchTerm" />
    </span>

    <div *ngFor="let col of _talentpoolCol" class="field-checkbox">
      <p-checkbox [disabled]="col.index == 1 || col.index == 2 || col.index == 3" [value]="col" name="category" [inputId]="col.field" [(ngModel)]="checkedColumns"></p-checkbox>
      <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
    </div>

    <div class="sidebar-bottom">

      <div class="{{ displaySaveColumnSettings ? 'hidden' : 'sidebar-bottom-apply'}}">
        <p (click)="displaySaveColumnSettings = true" class="sidebar-bottom-text">Save Selected Columns</p>
        <div class="sidebar-bottom-buttons">
          <button type="button" pRipple pButton class="p-button-outlined" label="Clear"
            (click)="clearSelectedColumns()"></button>
          <button pRipple pButton label="Apply" (click)="applySelectedColumns()" type="button"></button>
        </div>
      </div>

      <div [ngClass]="{'hidden': !displaySaveColumnSettings}" class="sidebar-saveSettings">
        <em (click)="displaySaveColumnSettings = false" class="pi pi-times"></em>
        <form [formGroup]="columnSettings" (ngSubmit)="saveColumnSettings()" class="p-inputgroup">
          <input pInputText placeholder="Enter Filter Name" type="text" formControlName="columnName">
          <button pButton pRipple label="Save" type="submit"></button>
        </form>
      </div>
    </div>
  </p-sidebar>

  <!-- Download Sidebar -->
  <p-sidebar [transitionOptions]="'0ms'" [(visible)]="showDownloadSidebar" class="downloadSidebar talentListDownload"
    [modal]="false" [style]="{ padding: '0', border: '2px solid white'}">
    <p class="sidebar-title">Download Data</p>

    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input pInputText placeholder="Search" [(ngModel)]="downloadSearchTerm" type="text"
        (ngModelChange)="searchColumns($event)" />
    </span>

    <div class="field-checkbox" *ngFor="let col of colsToDownload">
      <p-checkbox [value]="col" [inputId]="col.field" [(ngModel)]="checkedColumns" name="category"></p-checkbox>
      <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
    </div>

    <div class="download-container">
      <p>Download as</p>
      <div class="radioButton-group">
        <div class="radioButton">
          <p-radioButton [(ngModel)]="downloadAs" value="excel" name="exportButtons" inputId="excel"></p-radioButton>
          <label for="excel"
            [style]="{color: downloadAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
              class="pi pi-file"></em>Excel</label>
        </div>
        <div class="radioButton">
          <p-radioButton name="exportButtons" value="csv" inputId="csv" [(ngModel)]="downloadAs"></p-radioButton>
          <label for="csv" [style]="{color: downloadAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
              class="pi pi-file-excel"></em>CSV</label>
        </div>
      </div>

      <button pButton pRipple type="button" label="Download" class="p-button-raised"
        (click)="downloadAs === 'excel' ? exportRole('Excel') : exportRole('csv')"></button>
    </div>

  </p-sidebar>

  <!-- Table -->
  <p-table (onHeaderCheckboxToggle)="allSelected()" #dt [columns]="_selectedColumns" [value]="talentList"
    [resizableColumns]="true" [rows]="pageSize" [loading]="loadingSpinner" [scrollable]="true" scrollHeight="76vh"
    scrollDirection="both" responsiveLayout="scroll" dataKey="id" [(selection)]="selectedUsersList"
    (selectionChange)="onSelectedTalentChange($event)" [exportFilename]="exportName">

    <ng-template pTemplate="caption">
      <div class="table-header">
        <span class="table-header-left">
          <button pButton class="p-button-text simple-btn{{showSettingsSidebar ? '-active' : '' }}"
            icon="pi pi-sliders-h"
            (click)="showSettingsSidebar = true; showFilterSidebar = false; showDownloadSidebar = false">
          </button>
          <button pButton class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}" icon="pi pi-filter"
            (click)="showFilterSidebar = true; showSettingsSidebar = false; showDownloadSidebar = false">
          </button>
          <button pButton class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}"
            icon="pi pi-download"
            (click)="showDownloadSidebar = true; showSettingsSidebar = false; showFilterSidebar = false">
          </button>
          <p-divider layout="vertical"></p-divider>
          <label [style]="{display: 'flex'}">Total List :
            <span [style]="{color: '#333333', marginLeft: '5px'}" *ngIf="totalLength">{{ totalLength }}</span>
            <p-skeleton width="3rem" height="1.2rem" styleClass="mb-2" *ngIf="!totalLength"></p-skeleton>
          </label>
        </span>
        <span class="table-menu">
          <p [style]="{paddingRight: '10px'}">View</p>
          <p-dropdown [(ngModel)]="pageSize" [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }"
            (onChange)="getTalentByLimit()" [options]="pageLimits"></p-dropdown>
          <p-divider layout="vertical"></p-divider>
          <button class="disabled p-button-secondary p-button-text simple-btn" pButton>
            {{ first }} to {{ last }}
          </button>
          <button class="p-button-secondary p-button-text simple-btn" [disabled]="isFirstPage()" pButton
            icon="pi pi-angle-left" (click)="prev()"></button>
          <button (click)="next()" pButton class="p-button-secondary p-button-text simple-btn" icon="pi pi-angle-right"
            [disabled]="isLastPage()"></button>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr class="table-heading"
        [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}">
        <th scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th scope="col" *ngFor="let talent of columns" pSortableColumn="{{ talent.field }}" [style]="{width: '13vw'}"
          pResizableColumn>
          {{ talent.header }}
          <p-sortIcon field="{{ talent.field }}" class="sort-icon"></p-sortIcon>
        </th>
      </tr>
      <tr class="selection-operations-row" *ngIf="privilege.editManageCandidate && selectedUsersList.length"
        [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}">
        <td pFrozenColumn class="selection-operations-options">
          <span>{{selectedUsersList.length}} Selected</span>
          <!-- <p-checkbox [(ngModel)]="allCandidateSelected" [binary]="true"></p-checkbox>
          <label for=""></label> -->
          <!-- <div class="selectAll-checkbox" *ngIf="allCandidateSelected">
            [inputId]="filter.field"
            [for]="filter.field"
            <label [style]="{paddingLeft: '10px'}">Select All - {{ totalLength }}</label>
            <p-checkbox [style]="{marginLeft: '10px'}" [(ngModel)]="selectAll" [binary]="true"
              name="selectAll"></p-checkbox>
          </div> -->
          <button pButton pRipple label="Move to" icon="pi pi-external-link" (click)="stagesOP.toggle($event)">
          </button>
          <button pButton pRipple label="Send Emails" icon="pi pi-send" (click)="sendEmail()"></button>
        </td>
        <p-overlayPanel #stagesOP class="moveToOverlay">
          <ng-template pTemplate>
            <div class="stageList" *ngIf="moveToSelected">
              <span class="p-input-icon-left" style="top: 0 !important; padding-left: 5px; padding-right: 5px;">
                <em class="pi pi-search"></em>
                <input (ngModelChange)="eventSearch($event)" type="text" pInputText placeholder="Search"
                  [(ngModel)]="eventSearchTerm">
              </span>
              <div class="options-container">
                <div *ngFor="let event of _eventDetails" class="stageList-options">
                  <p (click)="moveEvent(event, stagesOP)">{{event.name}}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>
      </tr>

      <!-- <tr id="reset-this-root" class="row-talent-list"
        *ngIf="!selectedUsersList.length || !privilege.editManageCandidate"
        [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}">
        <th class="theader" scope="col" pResizableColumn [style]="{width: '5vw'}" pFrozenColumn></th>
        <th class="theader" scope="col" [style]="{width: '15vw'}">
          <div class="candidate-search">
            <em class="pi pi-search"></em>
            <input (keyup.enter)="searchData('id', _selectedColumns.length)"
              [(ngModel)]="searchForm[_selectedColumns.length].value" (ngModelChange)="event( _selectedColumns.length)"
              pInputText style="width: 100%; padding: 10px !important; padding-left: 30px !important;" type="text"
              placeholder="Candidate ID">
          </div>
        </th>
        <th class="theader" *ngFor="let selected of _selectedColumns; index as i" scope="col" pResizableColumn
          [style]="{width: '10vw', marginRight: '50px'}">
          <div *ngIf="selected.field !== 'status'" class="event-search">
            <em class="pi pi-search"></em>
            <input (keyup.enter)="searchData(selected.field, i)" [(ngModel)]="searchForm[i].value"
              (ngModelChange)="event(i)" pInputText
              style="width: 100%; padding: 10px !important; padding-left: 30px !important;" type="text"
              placeholder={{selected.header}}>
          </div>
          <div *ngIf="selected.field == 'status'" class="theader" scope="col" pResizableColumn
            [style]="{width: '10vw'}">
            <p-dropdown [(ngModel)]="searchForm[i].value" (ngModelChange)="event(i); searchData(selected.field, i)"
              class="status-dropdown" [options]="['All', 'Active', 'Inactive', 'Verified', 'Unverified', 'Hold']"
              appendTo="body" [style]="{'width':'100%', 'color':'var(--clr-secondary-light2) !important'}"></p-dropdown>
          </div>
        </th>
      </tr> -->
    </ng-template>
    <ng-template pTemplate="body" let-talent let-columns="columns">
      <tr class="row-talent-list"
        [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}"
        [style]="{cursor: 'pointer'}">
        <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
          <p-tableCheckbox [value]="talent"></p-tableCheckbox>
        </td>
        <td (click)="openCandidates(talent)" *ngFor="let col of columns" [style]="{width: '13vw'}">

          <span [ngSwitch]="true">

            <span *ngSwitchCase="col.field === 'status'">
              <div *ngIf="talent[col.field]" class="status-tag {{ talent[col.field] }}-tag">
                {{ talent[col.field]?.charAt(0).toUpperCase() + talent[col.field]?.slice(1) }}
              </div>
            </span>

            <span *ngSwitchCase="col.field === 'createdAt'">
              {{talent[col.field] | date: dateFormat || '-'}}
            </span>

            <span *ngSwitchCase="col.field === 'eventDetails'" class="eventName-op">
              <span *ngIf="talent[col.field] != null; else elseContent">
                {{ talent[col.field][0]?.eventTitle ?
                talent[col.field][0].eventTitle : talent[col.field][0]
                }}
                <div *ngIf="talent[col.field].length > 1" class="remainingItems-badge">
                  <p-badge value="+{{talent[col.field].length - 1}}" styleClass="mr-2"
                    (mouseenter)="events_op.show($event)"></p-badge>
                </div>
              </span>
              <ng-template #elseContent>-</ng-template>
              <p-overlayPanel #events_op [dismissable]="true">
                <ng-template pTemplate>
                  <div class="remainingItems" (mouseenter)="events_op.show($event)" (mouseleave)="events_op.hide()">
                    <p *ngFor="let event of talent[col.field].slice(1)">{{event.eventTitle ?
                      event.eventTitle : event }}</p>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>

            <span *ngSwitchDefault>
              {{ talent[col.field] ? talent[col.field] : '-' }}
            </span>
          </span>

        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="displayCandidateDetails">
    <p-sidebar class="candidateDetaile" [transitionOptions]="'0ms'" [(visible)]="displayCandidateDetails"
        styleClass="p-sidebar-lg" position="right" [style]="{width: '921px'}">
        <app-candidate-info [selectedUser]="selectedUser" [disableFeedback]="true" [disableActivity]="true" [disableStage]="true" [numberOfStages]="0" [statusSettings]="statusSettings"></app-candidate-info>
    </p-sidebar>
</div>
</div>


<p-menu #menu [popup]="true" [model]="talentOptions"></p-menu>
<p-toast></p-toast>