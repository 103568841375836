import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from './../../services/settings.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { SharedServicesService } from 'src/app/services/shared-services.service';
import { Message, MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { nanoid } from 'nanoid'
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css'], 
  providers: [SharedServicesService, MessageService]
})

export class CompanyDetailsComponent implements OnInit {

  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  companyData: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  saveDisable: boolean = false;
  profilePic: File[] = [];
  profileBase64: any = '';
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  defaultLogo: string = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-logo.png`
  logoSrc: any = '';
  logoSrcEdit: any = '';
  companyDetails: FormGroup = new FormGroup({
    company: new FormControl('', Validators.required),
    industry: new FormControl('', Validators.required),
    primaryLanguage: new FormControl('', Validators.required),
    companyCategory: new FormControl(''),
    panNumber: new FormControl('',Validators.required),
    gstNumber: new FormControl('',Validators.required),
    tinNumber: new FormControl('',Validators.required),
    companyLogo: new FormControl('')
  });

  companyName: string = '';
  companyEmail: string = '';
  industry: string = '';
  panNumber: string = '';
  gstNumber: string = '';
  tinNumber: string = '';
  primaryLanguage: string = '';
  companyCategory: string = '';
  panNumberShow: boolean = false;
  gstNumberShow: boolean = false;
  tinNumberShow: boolean = false;

  schoolId: string ='';

  industryList: any = [
    {label : "Research"},
    {label : "Development"},
    {label : "Core"}
  ];
  primaryLanguageList: any = [
    {label : "English"},
  ]
  
  companyCategoryList: any = [ "Public Limited", "Corporate" ];

  constructor(private settings: SettingsService, private fileUploadService: FileUploadService, private sharedService: SharedServicesService, private messageService: MessageService, private globalService:GlobalServicesService) { }

  ngOnInit(): void {
    this.logoSrc = this.logoSrcEdit = this.defaultLogo;
    this.schoolId = localStorage.getItem('schoolData')
    ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
    : '';
    this.companyEmail = localStorage.getItem('schoolDetails')
    ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].senderEmailId ?? null
    : null;
    this.getCompanyDetails();
  }

  getCompanyDetails()
  {
    this.settings.getCompanyData().subscribe((res: any) => {
      this.companyData = res.data ? res.data : [];
      this.logoSrc = this.companyData.companyLogo ?? this.defaultLogo;
      this.logoSrcEdit = this.companyData.companyLogo ?? this.defaultLogo;
      this.logoSrc = this.logoSrc.length ? this.logoSrc : this.defaultLogo
      this.logoSrcEdit = this.logoSrcEdit.length ? this.logoSrcEdit : this.defaultLogo
      this.userInfo = [
        { field: "Company", value: res.data.company ?? '-' },
        { field: "Email", value: this.companyEmail ?? '-'},
        { field: "Industry", value: res.data.industry ?? '-' },
        { field: "Primary Language", value: res.data.primaryLanguage?? '-' },
        { field: "Company Category", value: 'Public Limited' ?? '-' },
        { field: "No. of Branches", value: res.data.branchCount ?? '-' },
        { field: "PAN Number", value: res.data.panNumber ?? '-' },
        { field: "GST Number", value: res.data.gstNumber ?? '-' },
        { field: "TIN Number", value: res.data.tinNumber ?? '-' }
      ]
    })
  }

  openAddTemplate()
  {
    this.addTemplateSidebar = true;
    console.log(this.companyData)
    this.companyName = this.companyData.company;
    this.industry = this.companyData.industry;
    this.primaryLanguage = this.companyData.primaryLanguage;
    this.companyCategory = 'Public Limited';
    this.panNumber = this.companyData.panNumber;
    this.gstNumber = this.companyData.gstNumber;
    this.tinNumber = this.companyData.tinNumber;
    // this.companyDetails.patchValue(this.companyData)

  }

  cancel = () => {
    this.companyDetails.reset();
    this.addTemplateSidebar = false;
  }

  saveTemplate = () => {
    this.saveBtnLoading = true;
    const schoolDetails :any = JSON.parse(localStorage.getItem('schoolDetails') as string);
    const id = schoolDetails[0].id;
    const payload = this.companyDetails.value;
    if(this.companyDetails.valid){
      this.settings.updateCompanyData(id, payload).subscribe(res => {
        if(res.data){
          console.log(res)
          this.sharedService.emitChange(res.data.company.companyLogo);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Saved Successfully', })
        }
        else{this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong!', })}
        this.saveBtnLoading = false;
      })
    } else{
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please Fill the Required Fields', })
      this.saveBtnLoading = false;
    }
  }

  onSelectLogo(event: any, action?: any) {
    this.profilePic.push(...event.addedFiles);
    this.handleUpload(event, action)
  }

  handleUpload(event: any, action?:any) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    let width, height;
    reader.readAsDataURL(file);
    reader.onload = (e:any) => {
      let img:any = new Image();      
      img.src = e.target.result;
      const valid = (state:any) =>
      {
        if(state)
        {
          if(action === 'edit')
          {
            this.logoSrcEdit = reader.result ? reader.result : null;
            this.fileUpload(file);
          }
        }
      }
      img.onload = function () {
          width = this.width;
          height = this.height;
          if(width<=800 && height<=400)
          {
            valid(true)
          }
          else
          {
            valid(false)
          }
      }     
    };
  }

  onRemoveProfilePic(event: any) {
    this.profilePic.splice(this.profilePic.indexOf(event), 1);
  }

  deleteFile(action?:any)
{
  if(action === 'edit')
  {
    this.logoSrcEdit = null;
    this.companyDetails.controls['companyLogo'].patchValue("");
  }
}  

showPassword(selector: any) {
  let x:any = document.getElementsByClassName(selector)[0];
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
} 

fileUpload(file: any) {
  let fileName = file.name.split(".");
  let fileExtension = fileName.pop();
  fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
  const pay = {
    Bucket_name: this.bucketName,
    file_name: `general-settings/company-details/company-logo/${this.schoolId}/${fileName}`,
    type: file.type,
  };

  const blobData: any = file;
  this.saveDisable = true;
  this.fileUploadService.getSignedUrl(pay).subscribe(
    (url: any) => {
      if (url) {
        const json = url;
        this.fileUploadService.uploadUsingSignedUrl(
          json.data,
          blobData
        ).subscribe(
          (r: any) => {
            if (r && r.status === 200) {
              this.logoSrcEdit = `https://s3.amazonaws.com/${pay.Bucket_name}/${pay.file_name}`;
              this.companyDetails.controls['companyLogo'].patchValue(this.logoSrcEdit);
              this.saveDisable = false;
            }
          }
        );
      }
    }
  );
}

}

